import axios from "api/axios";
import { handleError } from "utils/functions";

export const getAllFields = async () => {
  try {
    const response = await axios.get("/fields");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addField = async (name, color, callback = () => {}) => {
  try {
    const response = await axios.post("/fields", {
      name,
      type: color,
    });
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const editField = async (fieldId, name, color, callback = () => {}) => {
  try {
    const response = await axios.patch(`/fields/${fieldId}`, {
      name,
      type: color,
    });
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const deleteField = async (fieldId, callback = () => {}) => {
  try {
    const response = await axios.delete(`/fields/${fieldId}`);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};
