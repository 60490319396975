import axios from 'api/axios'
import { handleError } from 'utils/functions'

export const getAllUsers = async () => {
    try {
        const response = await axios.get('/users')
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const getUserById = async (id) => {
    try {
        const response = await axios.get(`users/${id}`)
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const updateUser = async (id, editUserData) => {
    try {
        await axios.patch(`/users/${id}`, editUserData)
    } catch (error) {
        handleError(error)
    }
}

export const addUser = async (userData, callback) => {
    try {
        await axios.post('/users', userData)
        callback()
    } catch (error) {
        handleError(error)
    }
}

export const deleteUser = async (userId, callback) => {
    try {
        await axios.delete(`/users/${userId}`)
        callback()
    } catch (error) {
        console.error(error)
    }
}

export const support = async (userId, description) => {
    try {
        const res = await axios.post(
            `/users/support/user?userId=${userId}&message=${description}`,
        )
        return {
            ok: true,
            data: res.data,
        }
    } catch (error) {
        return {
            ok: false,
            data: error,
        }
    }
}
