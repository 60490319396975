import React from "react";
import PropTypes from "prop-types";

// mui
import {
  Typography,
  Stack,
  Button,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "../../theme";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// chartjs
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip as TT, Legend } from "chart.js";
ChartJS.register(ArcElement, TT, Legend);

function Piechart({ title, tooltip, chartLabel, chartData, button }) {
  // chart styles
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          usePointStyle: true,
          padding: 30,
        },
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };

  // chart data
  const data = {
    labels: chartLabel,
    datasets: [
      {
        data: chartData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
        ],
      },
    ],
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          width: "30rem",
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",

          justifyContent: "align-start",
          alignItems: "start",
          overflow: "hidden",
        }}
      >
        <Stack
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="cardHeader" sx={{ m: "1rem 0 0 1rem" }}>
            {title}
            <Tooltip title={tooltip}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Doughnut
            options={options}
            data={data}
            style={{
              padding: "2rem",
            }}
          />
          <Button endIcon={<ArrowForwardIcon />} variant="cardButton">
            {button}
          </Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}

Piechart.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  chartLabel: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.arrayOf(PropTypes.number),
  button: PropTypes.string,
};

Piechart.defaultProps = {
  title: "Your title comes here",
  tooltip: "Tooltip content comes here",
  chartLabel: ["Lorem", "Ipsum", "Dolor", "Sit", "Amet"],
  chartData: [10, 20, 5, 8, 3],
  button: "Button",
};

export default Piechart;
