import axios from "api/axios";
import { handleError } from "utils/functions";
export * from "./clientApi";
export * from "./tagApi";
export * from "./fleetApi";
export * from "./vehicleApi";
export * from "./driverApi";
export * from "./tripApi";
export * from "./taxAPI";
export * from "./destinationsApi";
export * from "./fieldApi";
export * from "./usersAPI";
export * from "./permissionsAPI";
export * from "./periodsAPI";
export * from "./dashboardAPI";
export * from "./filterApi";
export * from "./inOutReport";
export * from "./documents";

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post("/users/login", {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/**
 * ----------------------------------------------------------------------------------
 * - [Tasks]
 * ----------------------------------------------------------------------------------
 */
export const getAllTasks = async (perPage, currentPage, pagesToLoad) => {
  try {
    const response = await axios.get("/tasks", {
      params: {
        perPage,
        pagesToLoad,
        currentPage,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addTask = async (taskData, callback = () => {}) => {
  try {
    const response = await axios.post("/tasks", taskData);
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const editTask = async (taskId, taskData, callback = () => {}) => {
  try {
    await axios.put(`tasks/${taskId}`, taskData);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const deleteTask = async (taskId, userId, callback = () => {}) => {
  try {
    await axios.delete(`/tasks/${taskId}?userId=${userId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const updateProgressTask = async (taskId, callback = () => {}) => {
  try {
    await axios.patch(`/tasks/progress/${taskId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const finishTask = async (taskId, callback = () => {}) => {
  try {
    await axios.patch(`/tasks/finish/${taskId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const toggleTaskDone = async (taskData, callback = () => {}) => {
  try {
    const { title, deadline, description, status } = taskData;
    await axios.put(`/tasks/${taskData.id}`, {
      title,
      deadline,
      description,
      status: !status,
    });
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const findTaskByText = async (text) => {
  try {
    const response = await axios.get("/tasks/findByString/text", {
      params: {
        text,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const filterByStatus = async (filterData) => {
  const newData = {
    taskStatus: filterData.taskStatus,
    userId: filterData.userId,
  };
  try {
    const response = await axios.get("/tasks/filter/Tasks", {
      params: {
        ...newData,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 *
 * @returns
 */

export const getAllUsers = async () => {
  try {
    const response = await axios.get("/users");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllTrips = async (
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/route", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllDrivers = async (
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/drivers", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllDestinations = async (
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/destinations", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllComments = async (
  type,
  id,
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/comments", {
      params: {
        type,
        perPage,
        pagesToLoad,
        currentPage,
        id,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getRovineta = async (
  id,
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/rovineta", {
      params: {
        carId: id,
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// // GET BY ID
// export const getUser = async (id) => {
//   try {
//     const response = await axios.get(baseUrl + "users/" + id);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getClient = async (id) => {
  try {
    const response = await axios.get("clients/" + id);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTask = async (id) => {
  try {
    const response = await axios.get("/tasks/" + id);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTrip = async (id) => {
  try {
    const response = await axios.get("/route/" + id);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getFleet = async (id) => {
  try {
    const response = await axios.get("/cars/" + id);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDriver = async (id) => {
  try {
    const response = await axios.get("/drivers/" + id);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getNotes = async (
  clientId,
  perPage = 10,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get(`/notes?clientId=${clientId}`, {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getProblems = async (
  clientId,
  perPage = 10,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get(`/problems/client/${clientId}`, {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getRouteByTypeDeparture = async (
  perPage,
  currentPage,
  pagesToLoad
) => {
  try {
    const response = await axios.get(`route/typeRoute?typeRoute=TUR`, {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getRouteByTypeArrival = async (
  perPage,
  currentPage,
  pagesToLoad
) => {
  try {
    const response = await axios.get(`route/typeRoute?typeRoute=RETUR`, {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

// // ADD
// export const addUser = async (user) => {
//   try {
//     const response = await axios.post(baseUrl + "users/" + user);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const addTax = async (tax) => {
//   try {
//     const response = await axios.post(baseUrl + "taxes/" + tax);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const addTrip = async (addTripData, callback = () => {}) => {
  try {
    const response = await axios.post("/route", addTripData);
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const addProblem = async (
  clientId,
  userId,
  routeId,
  title,
  description,
  callback = () => {}
) => {
  try {
    const response = await axios.post(`/problems?clientId=${clientId}`, {
      userId,
      routeId,
      title,
      description,
    });
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const addNote = async (id, userId, title, note, callback = () => {}) => {
  try {
    const response = await axios.post(`/notes?clientId=${id}`, {
      userId,
      title,
      note,
    });
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const addFile = async (file) => {
  try {
    const response = await axios.post("/cars/file", {
      file,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addRovineta = async (id, addRovinetaData, callback = () => {}) => {
  try {
    const response = await axios.post(`/rovineta?carId=${id}`, addRovinetaData);
    callback(response.data);
  } catch (error) {
    console.log(error);
  }
};

export const addClientOnRoute = async (
  clientId,
  RouteId,
  clientOnRoute,
  callback = () => {}
) => {
  try {
    const response = await axios.post("/clientsOnRoute/addRoute", {
      params: {
        clientId,
        RouteId,
        type: clientOnRoute.type,
      },
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const addComment = async (
  id,
  type,
  userId,
  comment,
  callback = () => {}
) => {
  try {
    const response = await axios.post(`/comments?id=${id}`, {
      type,
      userId,
      comment,
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

// // UPDATE

export const getCars = async (
  perPage = 10,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/cars", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editVehicle = async (id, vehicleData, callback = () => {}) => {
  try {
    const response = await axios.put(`/cars/${id}`, vehicleData);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const editDriver = async (
  id,
  driverData,
  carId,
  callback = () => {}
) => {
  try {
    const response = await axios.put(`/drivers/${id}`, driverData, {
      params: {
        carId: carId,
      },
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};
export const editTrip = async (id, tripData, callback = () => {}) => {
  try {
    const response = await axios.put(`/route/${id}`, tripData);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

// export const updateUser = async (user) => {
//   try {
//     const response = await axios.put(baseUrl + "users/" + user.id, user);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const updateClient = async (client) => {
//   try {
//     const response = await axios.put(baseUrl + "clients/" + client.id, client);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const updateTask = async (tasks) => {
//   try {
//     const response = await axios.put(baseUrl + "tasks/" + tasks.id, tasks);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const updateTax = async (tax) => {
//   try {
//     const response = await axios.put(baseUrl + "taxes/" + tax.id, tax);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const updateTrip = async (trip) => {
//   try {
//     const response = await axios.put(baseUrl + "trips/" + trip.id, trip);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const updateFleet = async (fleet) => {
//   try {
//     const response = await axios.put(baseUrl + "fleets/" + fleet.id, fleet);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export const updateDriver = async (driver) => {
//   try {
//     const response = await axios.put(baseUrl + "drivers/" + driver.id, driver);
//     return response.data;
//   } catch (error) {
//     delete console.error(error);
//   }
// };

// //  DELETE

export const deleteClientOnRoute = async (
  clientsOnRouteId,
  reason,
  callback = () => {}
) => {
  try {
    await axios.delete(`clientsOnRoute/${clientsOnRouteId}?reason=${reason}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const deleteClientDepartureWithReason = async (
  clientsOnRouteId,
  departureReason,
  callback = () => {}
) => {
  try {
    await axios.delete(`clientsOnRoute/client/${clientsOnRouteId}`, {
      params: {
        departureReason,
      },
    });
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const deleteDriver = async (driverId, callback = () => {}) => {
  try {
    await axios.delete(`/drivers/${driverId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

/**
 * Get Client's default document types
 */
export const getClientDefaultDocumentTypes = async () => {
  try {
    const response = await axios.get("/clients/docs/mainDocsType");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

//get the reports
export const getAllReports = async (
  startDate,
  endDate,
  callback = () => {}
) => {
  try {
    const response = await axios.get("/reports", {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

//get daily reports
export const getDailyReport = async () => {
  try {
    const response = await axios.get("/reports/daily/DailyReport");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

//return daily reports
export const returnDailyReport = async (id) => {
  try {
    const response = await axios.get("/reports/" + id, {
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPaymentsReport = async (
  startDate,
  endDate,
  callback = () => {}
) => {
  try {
    const response = await axios.get("/reports/payments/PaymentsReport", {
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

//get the trips report

export const getTripReport = async (id) => {
  try {
    const response = await axios.get(`/route/reports/getReport/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const addFinalPay = async (
  clientsOnRouteId,
  finalPay,
  callback = () => {}
) => {
  try {
    const response = await axios.patch(
      `/clientsOnRoute/updatePayment/${clientsOnRouteId}?finalPay=${finalPay}`
    );
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const updateDepartureData = async (
  clientsOnRouteId,
  updateData,
  callback
) => {
  try {
    const response = await axios.put(
      `/clientsOnRoute/departure/update/${clientsOnRouteId}`,
      {
        ...updateData,
      }
    );
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const addPaymentOnDeparture = async (
  paymentData,
  callback = () => {}
) => {
  try {
    await axios.post("/departurePayment", paymentData);
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const getDeparturePayments = async (departureId) => {
  try {
    const response = await axios.get(
      `/departurePayment/departure/${departureId}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const updatePaymentData = async (
  id,
  updateData,
  callback = () => {}
) => {
  try {
    await axios.patch(`/departurePayment/${id}`, {
      ...updateData,
    });
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const deletePayment = async (id, callback = () => {}) => {
  try {
    await axios.delete(`/departurePayment/${id}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const recoverPasswordUser = async (email) => {
  try {
    const response = await axios.post(`/users/user/recover_password/${email}`);
    return {
      ok: true,
      data: response.data,
    };
  } catch (error) {
    return {
      error,
      ok: false,
      data: {},
    };
  }
};

export const setNewUserPassword = async (token, newPassword) => {
  try {
    const response = await axios.put(`/users/user/reset_password/${token}`, {
      newPassword,
    });
    return {
      ok: true,
      data: response.data,
    };
  } catch (error) {
    return {
      error,
      ok: false,
      data: {},
    };
  }
};
