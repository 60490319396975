import axios from "api/axios";
import { handleError } from "utils/functions";

export const getTags = async (
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/tags", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addTag = async (clientId, tagData, callback = () => {}) => {
  let { name, type } = tagData;

  try {
    await axios.post("/tags", {
      clientId,
      name,
      type,
    });
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const updateTag = async (tagData, callback = () => {}) => {
  const { tagId, name, type } = tagData;

  try {
    await axios.put(`/tags/${tagId}`, {
      name,
      type,
    });
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const deleteTag = async (tagId, callback = () => {}) => {
  try {
    await axios.delete(`/tags/${tagId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const getTagsOnClient = async (clientId) => {
  try {
    const response = await axios.get("/tagsOnClients", {
      params: {
        id: clientId,
      },
    });

    return response.data.map((t) => t.tag);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addTagOnClient = async (clientId, tagId, callback = () => {}) => {
  try {
    await axios.post("/tagsOnClients", {
      clientId,
      tagId,
    });
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const deleteTagOnClient = async (
  clientId,
  tagId,
  callback = () => {}
) => {
  try {
    await axios.delete("/tagsOnClients", {
      params: {
        clientId,
        tagId,
      },
    });
    callback();
  } catch (error) {
    console.error(error);
  }
};
