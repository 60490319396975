// react imports
import React, { useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams } from "react-router-dom";

import logoRaisis from "resources/LogoRaisisCRM.png";
// import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { setNewUserPassword } from "api/API";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const mobilePhone = useMediaQuery(theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { token } = useParams();

  const handleSubmitRecoverPassword = () => {
    if (password.length < 8)
      toast.error("Introduceti o parola mai lunga de 8 caractere");
    else {
      setNewUserPassword(token, password).then((res) => {
        if (res.ok) {
          toast.success("Parola resetata cu succes");
          navigate("/login");
        } else toast.error("A intervenit o eroare");
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          flex: 1,
          gap: "7.5vw",
          display: "flex",
          flexDirection: mobilePhone ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2.5rem",
        }}
      >
        <Box
          sx={{
            width: mobilePhone ? "90vw" : "40vw",
            maxWidth: "500px",
            flexShrink: 0,
            borderRadius: "15px",
            padding: "25px 32px",
            boxShadow: "#eaeef4 0px 7px 29px 0px;",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
              }}
            >
              {/* <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Password"
                sx={{
                  // boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                  fieldset: { borderRadius: "10px" },
                }}
              /> */}
              <FormControl sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <Button
              onClick={handleSubmitRecoverPassword}
              variant="contained"
              style={{
                padding: "12px 14px",
              }}
            >
              {t("Reseteaza parola")}
            </Button>
          </Box>
        </Box>
      </Box>

      <img
        src={logoRaisis}
        alt="Raisis Software"
        style={{
          flexShrink: 0,
          width: "35%",
          maxWidth: "256px",
          paddingBlock: "2.5rem",
        }}
      />
    </Box>
  );
};

export default ResetPassword;
