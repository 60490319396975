import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import styles from "./Drivers.module.scss";

import {
  Breadcrumbs,
  Link,
  Typography,
  Box,
  Grid,
  TextField,
  Divider,
  MenuItem,
} from "@mui/material";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";


import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Comments, CustomDialog } from "../../lib";
import { getDOB } from "utils/functions";

import { toast } from "react-toastify";

import {
  getDriver,
  editDriver,
  deleteDocDriver,
  uploadDocDriver,
  getDocsDriver,
  getAllFleets,
} from "../../api/API";
import { DetailsComponet, DetailsWrapper, DocumentModal } from "../../lib";

function Driver() {
  let { id } = useParams();
  const [driver, setDriver] = useState(null);
  const [driverModal, setDriverModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [fleet, setFleet] = useState([]);
  const { t } = useTranslation();
  const [driverEditData, setDriverEditData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    CNP: "",
    birthday: new Date(),
    adress: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getDriver(id);
        setDriver(response);
      } catch (error) {
        console.error("Error: ", error);
      }
    })();
  }, [id]);

  useEffect(() => {
    getDocsDriver(id).then((docs) => setDocs(docs));
  }, [id]);

  useEffect(() => {
    getAllFleets(9999, 0, 1).then((fleet) => {
      setFleet(fleet);
    });
  }, []);

  const handleChangeEdit = (e) => {
    if (e.target.name === "CNP") {
      setDriverEditData({
        ...driverEditData,
        [e.target.name]: e.target.value,
        ["birthday"]: getDOB(e.target.value),
      });
    } else {
      setDriverEditData({
        ...driverEditData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (!driverModal) return;

    const { firstName, lastName, phone, email, CNP, birthday, adress, carId } =
      driverModal;

    setDriverEditData({
      firstName,
      lastName,
      phone,
      email,
      CNP,
      birthday,
      adress,
      carId,
    });
  }, [driverModal]);

  const handleSubmitEditDriver = () => {
    if (driverEditData) {
      editDriver(id, driverEditData, driver?.car.id, () => {
        getDriver(id).then((res) => setDriver(res));
        toast.success(
          `Soferul cu numele ${driverEditData.firstName} ${driverEditData.lastName} a fost editat`
        );
      });
      setDriverModal(false);
      setDriverEditData("");
    } else {
      console.error("eroare");
      toast.error("A intervenit o eroare");
    }
  };

  const render = [
    {
      key: "firstName",
      label: t("First name"),
    },
    {
      key: "birthday",
      label: t("Birthday"),
      cellModifier: (displayData) => (
        <Typography>{dayjs(displayData).format("DD-MM-YYYY")}</Typography>
      ),
    },
    {
      key: "phone",
      label: t("Phone Number"),
    },
    {
      key: "email",
      label: t("E-mail"),
    },
    {
      key: "adress",
      label: t("Address"),
    },
    {
      key: "lastName",
      label: t("Last name"),
    },
    {
      key: "car",
      label: t("Car"),
      cellModifier: (displayData, data) => {
        return <Typography>{data.car.registrationPlate}</Typography>;
      },
    },

    {
      key: "CNP",
      label: "CNP",
      cellModifier: (displayData, data) => (
        <Typography
          sx={{
            color: "green",
            borderRadius: "1rem",
            border: "1px solid",
            padding: "10px",
          }}
        >
          {displayData}
        </Typography>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Driver details")}
        </Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Nomenclators")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Drivers")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <div className={styles.parent}>
        <div className={styles.upperSide}>
          <div className={styles.leftSide}>
            {/* component with driver info */}
            <DetailsComponet
              title="Details"
              showEdit
              tableData={driver}
              render={render}
              onEdit={() => setDriverModal(driver)}
            />
            {/* modal for edit */}
            <CustomDialog
              open={Boolean(driverModal)}
              handleClose={() => setDriverModal(false)}
              button1={t("Edit")}
              button2={t("Quit")}
              onClickButton1={() => handleSubmitEditDriver()}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "white",
                  borderRadius: "1.5rem",
                  height: "100%",
                  width: "22rem",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="firstName"
                      value={driverEditData.firstName}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label={t("First name")}
                    />

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="lastName"
                      value={driverEditData.lastName}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label={t("Last name")}
                    />

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="phone"
                      value={driverEditData.phone}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label="Telefon"
                    />

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="email"
                      value={driverEditData.email}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label="Email"
                    />

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="CNP"
                      value={driverEditData.CNP}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label="CNP"
                      inputProps={{ maxLength: 13 }}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="birthday"
                        name="birthday"
                        value={getDOB(driverEditData.CNP)}
                        onChange={(value) => {
                          handleChangeEdit({
                            target: { name: "birthday", value },
                          });
                        }}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              fieldset: {
                                borderRadius: "1.5rem",
                              },
                              width: "16rem",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="adress"
                      value={driverEditData.adress}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label="Adresă"
                    />

                    <TextField
                      fullWidth
                      select
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      name="carId"
                      value={driverEditData.carId}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label="Vehicul"
                    >
                      {fleet.map((car) => (
                        <MenuItem key={car.id} value={car.id}>
                          {car.registrationPlate}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            </CustomDialog>
          </div>
          <div className={styles.rightSide}>
            {/* driver doc modal */}
            <DetailsWrapper
              title={t("Documents")}
              showAdd
              onAdd={() => setDocumentModal(true)}
              padding={docs.length > 0 ? false : true}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                {docs.map((doc, docIndex) => (
                  <Box key={doc.id} sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "1rem",
                      }}
                    >
                      <Typography>
                        {doc.docs.name || `Document nr. ${docIndex + 1}`}
                      </Typography>
                      <div>
                        <a
                          rel="noreferrer"
                          target={"_blank"}
                          href={`${process.env.REACT_APP_API_BASE}/drivers/file/${doc.docs.fileId}`}
                        >
                          <AttachFileIcon />
                        </a>
                        <DeleteIcon
                          color="errorCustom"
                          onClick={() =>
                            deleteDocDriver(doc.docs.id, () => {
                              let newDocs = [...docs];
                              newDocs = newDocs.filter((d) => d.id !== doc.id);
                              setDocs(newDocs);
                              toast.success(
                                `Documentul ${doc.docs.name} a fost șters!`
                              );
                            })
                          }
                        />
                      </div>
                    </Box>
                    {docIndex !== docs.length - 1 && <Divider />}
                  </Box>
                ))}

                {!docs.length && (
                  <Typography>{t("Driver has no documents.")}</Typography>
                )}
              </Box>
            </DetailsWrapper>
            <DocumentModal
              open={Boolean(documentModal)}
              setOpen={setDocumentModal}
              handleSubmit={(formData) =>
                uploadDocDriver(driver.id, formData, (doc) => {
                  getDocsDriver(driver.id).then((docs) => {
                    setDocs(docs);
                    setDocumentModal(false);
                    toast.success(t("File has been uploaded!"));
                  });
                })
              }
            />
          </div>
        </div>
        <div className={styles.comments}>
          <Comments type="DRIVER" id={id} />
        </div>
      </div>
    </div>
  );
}

export default Driver;
