import React from "react";
import { useTranslation } from "react-i18next";
import versionsInfo from "./versionsInfo";
import styles from "./Versions.module.scss";

import { Divider, Typography } from "@mui/material";
import LoupeIcon from "@mui/icons-material/Loupe";
import BugReportIcon from "@mui/icons-material/BugReport";

const Versions = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.pageContainer}>
      <div className={styles.componentWrapper}>
        {versionsInfo.map((version) => (
          <section key={version.version}>
            {versionsInfo.length > 1 && <Divider light />}
            <div className={styles.headerContainer}>
              <div className={styles.titleContainer}>
                <Typography
                  variant="clientContactedTimesBox"
                  backgroundColor="#5664D2"
                >
                  {version.versionType}
                </Typography>
                <Typography variant="pageTitle">
                  {t("Version")}:
                  <span className={styles.versionFont}>{version.version}</span>
                </Typography>
              </div>
            </div>
            <div className={styles.detailsWrapper}>
              {version.description !== "" && (
                <Typography variant="tableContent" style={{ padding: "10px" }}>
                  {t(`${version.description}`)}
                </Typography>
              )}
              {version.features.length > 0 && (
                <div className={styles.featuresArea}>
                  <Typography variant="h6">{t("New features")}:</Typography>
                  <Divider style={{ width: "40%" }} />
                  <div className={styles.listOfFeatures}>
                    {version.features.map((feature) => (
                      <Typography
                        key={feature}
                        variant={"tableContent"}
                        direction="row"
                        alignItems="center"
                      >
                        <LoupeIcon
                          fontSize="inherit"
                          sx={{ marginRight: 1, color: "green" }}
                        />
                        {feature}
                      </Typography>
                    ))}
                  </div>
                </div>
              )}
              {version.bugsFixed.length > 0 && (
                <div className={styles.bugsArea}>
                  <Typography variant="h6">{t("Bugs fixed")}:</Typography>
                  <Divider style={{ width: "36%" }} />
                  <div className={styles.listOfBugs}>
                    {version.bugsFixed.map((item) => (
                      <Typography
                        key={item}
                        variant={"tableContent"}
                        direction="row"
                        alignItems="center"
                      >
                        <BugReportIcon
                          fontSize="inherit"
                          sx={{ marginRight: 1, color: "red" }}
                        />
                        {item}
                      </Typography>
                    ))}
                  </div>
                </div>
              )}{" "}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default Versions;
