import { useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// components
import useChart from "./useChart";
import LoadingSpinner from "lib/components/Spinner/LoadingSpinner";

import FilterDashboardCharts from "lib/modals/FilterDashboardCharts";
import { DashboardButtons } from "lib/components/Buttons/buttons";

// ----------------------------------------------------------------------

AppMoneyFlow.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppMoneyFlow({
  title,
  subheader,
  chartLabels,
  chartData,
  selectedValue,
  setSelectedValue,
  tableType,
  loading,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  open,
  setOpen,
  setData,
  setLabels,
  total,
  setTotal,
  ...other
}) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} ${
              tableType === "registration" ? "înscrieri" : "€ încasați"
            }  `;
          }
          return y;
        },
      },
    },
  });

  const { t } = useTranslation();

  return (
    <Card {...other}>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <CardHeader
          title={title}
          subheader={
            tableType === total + "registration" ? "Înscrieri" : "€ încasați"
          }
        /> */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Typography variant="tableTitle">{title}</Typography>
          <Typography>
            {" "}
            ({total} {tableType === "registration" ? "înscrieri" : "€ încasați"}
            )
          </Typography>
        </div>
        <DashboardButtons
          styles={{ margin: "10px" }}
          onClick={() => setOpen(true)}
        >
          {t("Choose dates")}
        </DashboardButtons>
      </div>

      <FilterDashboardCharts
        open={open}
        setOpen={setOpen}
        tableType={tableType}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        setData={setData}
        setLabels={setLabels}
        total={total}
        setTotal={setTotal}
      />

      {loading ? (
        <LoadingSpinner loading={loading} margin="auto" />
      ) : (
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          {selectedValue === "day" && (
            <ReactApexChart
              type="line"
              series={chartData}
              options={chartOptions}
              height={364}
            />
          )}
          {selectedValue === "month" && (
            <ReactApexChart
              type="line"
              series={chartData}
              options={chartOptions}
              height={364}
            />
          )}
        </Box>
      )}
    </Card>
  );
}
