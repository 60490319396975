import React, { useState } from "react";
import { CustomDialog } from "lib";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box, TextField, Grid } from "@mui/material";

import { addInOutRegister, editInOutRegister } from "api/API";

const AddReportModal = ({
  reportModalOpen,
  setReportModalOpen = () => {},
  setTriggerRefetch = () => {},
  editReportData,
  setEditReportData = () => {},
}) => {
  const { t } = useTranslation();

  const [registerData, setRegisterData] = useState({
    source:
      editReportData !== undefined ? editReportData.source : "LUCICOSM SRL",
    details:
      editReportData !== undefined
        ? editReportData.details
        : "CONTRACT MEDIERE",
    responsible: editReportData !== undefined ? editReportData.responsible : "",
    contractId: "",
    createdAt:
      editReportData !== undefined ? editReportData.createdAt : new Date(),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };

  //   source - de unde provine documentul
  // details - continut pe scurt al documentului
  // responsible - cui s-a repartizat doc si semn de primire

  return (
    <CustomDialog
      open={reportModalOpen}
      title={t("Add register")}
      handleClose={() => {
        setReportModalOpen(false);
        setEditReportData(undefined);
      }}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() => {
        if (editReportData !== undefined) {
          editInOutRegister(editReportData.id, registerData).then((res) => {
            if (res.ok) {
              toast.success(t("The register was modified succesfully"));
              setEditReportData(undefined);
              setReportModalOpen(false);
              setTriggerRefetch((prev) => !prev);
            } else {
              toast.error(t("An error occured. Please try again!"));
            }
          });
        } else {
          addInOutRegister(registerData).then((res) => {
            if (res.ok) {
              toast.success(t("The register was added succesfully"));
              setEditReportData(undefined);
              setReportModalOpen(false);
              setTriggerRefetch((prev) => !prev);
            } else {
              toast.error(t("An error occured. Please try again!"));
            }
          });
        }
      }}
    >
      <Box
        fullWidth
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "550px",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={3}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Registration Date*")}
                name="createdAt"
                value={registerData.createdAt}
                onChange={(value) => {
                  handleChange({ target: { name: "createdAt", value } });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>{" "}
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={registerData.source}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              required
              label={t("Where the document comes from")}
              name="source"
              onChange={handleChange}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={registerData.details}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              required
              label={t("Brief content of the document")}
              name="details"
              onChange={handleChange}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            <TextField
              fullWidth
              value={registerData.responsible}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              required
              label={t(
                "The compartment to which the document and the receiving signature were assigned"
              )}
              name="responsible"
              onChange={handleChange}
            />
          </Grid>{" "}
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AddReportModal;
