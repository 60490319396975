import React from "react";
import { Typography, Button } from "@mui/material";
import { toast } from "react-toastify";
import { useConfirm } from "hooks/useConfirm";
import { useTranslation } from "react-i18next";

const Step2Signature = (props) => {
  const {
    handlePrev = () => {},
    handleNext = () => {},
    setImageSrc = () => {},
  } = props;

  const { t } = useTranslation();
  const confirm = useConfirm();

  const saveSignatureImage = () => {
    fetch(`${process.env.REACT_APP_SCANNER_SIGNATURE_URL}/api/sign/confirm`, {
      method: "GET",
      headers: {
        Accept: "image/jpeg",
      },
    })
      .then((response) => {
        if (response.status === 500) {
          toast.error(t("Signature cant be empty"));
          throw new Error("Empty signature");
        }

        return response.blob();
      })
      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(blob);
        toast.success(
          t("Signature was saved succesfully. Please proceed to the next step!")
        );
      })
      .catch((error) => {
        console.error("Error fetching image:", error.status);
        toast.error(
          t("Signature pad is not open or there was no signature available!")
        );
      });
  };
  return (
    <>
      <Typography variant="h6">{t("Step 2: Save Signature")}</Typography>
      <Button
        style={{
          borderRadius: "99rem",
        }}
        variant="contained"
        onClick={() => saveSignatureImage()}
      >
        {t("Save signature")}
      </Button>
      <div style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
        <Button
          style={{
            borderRadius: "99rem",
          }}
          variant="outlined"
          onClick={handlePrev}
        >
          {t("Previous")}
        </Button>

        <Button
          style={{
            borderRadius: "99rem",
          }}
          variant="outlined"
          onClick={() =>
            confirm(
              t(
                "Are you sure you saved the signature before going to the next step?"
              ),
              () => {
                handleNext();
              }
            )
          }
        >
          {t("Next")}
        </Button>
      </div>
    </>
  );
};

export default Step2Signature;
