import React, { useState, useEffect } from "react";


import { CustomDialog, CustomTable } from "lib";

import DeleteIcon from "@mui/icons-material/Delete";

import { useConfirm } from "hooks/useConfirm";

import { getPeriods, addPeriod, deletePeriod } from "api/API";

import { Typography, Breadcrumbs, Link, Grid, TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";


const Periods = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [periods, setPeriods] = useState([]);
  const [addPeriodModal, setAddPeriodModal] = useState(false);
  const [time, setTime] = useState({});
  const [loadingPeriods, setLoadingPeriods] = useState(true);
  const navigate = useNavigate()
  const handleChange = async (e) => {
    setTime({
      ...time,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  useEffect(() => {
    getPeriods().then((res) => {
      setPeriods(res);
      setLoadingPeriods(false);
    });
  }, []);

  const labels = [
    {
      id: "time",
      label: t("Days"),
      minWidth: 10,
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
           {/* <span
            style={{ cursor: "pointer" }}
             onClick={() => navigate(`/periods/period/` + row.id)}
          >
            <ArrowForwardIcon color="primary" />
          </span>  */}
          {/* &nbsp;&nbsp;&nbsp; */}
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(t("Are you sure you want to delete this period?"), () =>
                deletePeriod(row.id, () =>
                  getPeriods().then((period) => {
                    setPeriods(period);
                    toast.success(t("Period has been deleted!"));
                  })
                )
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("All periods")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Periods")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTable
        title={t("All periods")}
        onAdd={() => setAddPeriodModal(true)}
        showAdd
        labels={labels}
        cellModifier={cellModifier}
        tableData={periods}
        loading={loadingPeriods}
      />

      <CustomDialog
        open={addPeriodModal}
        handleClose={() => setAddPeriodModal(false)}
        title={t("Add period")}
        button1={t("Finalize")}
        button2={t("Quit")}
        onClickButton1={() =>
          addPeriod(time, () => {
            getPeriods().then((res) => setPeriods(res));
            setAddPeriodModal(false);
            setTime({});
            toast.success(t("Period has been added"));
          })
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("Period")}
              name="time"
              value={time.time || ""}
              onChange={handleChange}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            ></TextField>
          </Grid>
        </Grid>
      </CustomDialog>
    </div>
  );
};

export default Periods;
