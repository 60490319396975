import React, { useEffect } from "react";
import { CustomDialog } from "lib";
import { getAllUsers } from "api/usersAPI";
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Checkbox,
  Autocomplete,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterUsersModal = ({
  open = false,
  setOpen = () => {},

  fields = [],

  setIsFilter = () => {},
  triggerFilter = false,
  setTriggerFilter = () => {},
  setData,
  setLoading,
  filterData = null,
  setFilterData = () => {},
  resetFilter = () => {},
  triggerRefetch = null,
  setTriggerRefetch = () => {},
  resetSearch = null,
}) => {
  const { t } = useTranslation();

  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);

  const handleSelectAll = () => {
    setIsCheckedAll(!isCheckedAll);
    setSelectedUsers(users.map((user) => user.id));
    if (isCheckedAll) {
      setSelectedUsers([]);
    }
  };

  const handleChange = async (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };

  const statuses = [
    {
      id: 1,
      name: t("To Do"),
      value: "ToDo",
    },
    {
      id: 2,
      name: t("In Progress"),
      value: "InProgress",
    },
    { id: 3, name: t("Done"), value: "Done" },
  ];

  const handleFilter = () => {
    resetSearch();
    setLoading(true);
    setData([]);
    setIsFilter(true);
    setTriggerFilter(!triggerFilter);
    setOpen(false);
  };

  const handleRemoveFilters = () => {
    resetFilter();
    setFilterData({});
    setLoading(true);
    setData([]);
    setTriggerRefetch(!triggerRefetch);
    setOpen(false);
  };

  const getAllUsersF = async () => {
    const users = await getAllUsers();
    setUsers(users);
  };

  useEffect(() => {
    getAllUsersF();
  }, []);

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Filter")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={handleFilter}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "20rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label={t("Users")}
              name="userId"
              onChange={handleChange}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {users.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label={t("Status")}
              name="taskStatus"
              onChange={handleChange}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {statuses.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{
                borderRadius: "1rem",
              }}
              onClick={handleRemoveFilters}
            >
              Sterge filtre
            </Button>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default FilterUsersModal;
