import React from "react";
import { useEffect, useState } from "react";
import { Grid, TextField, MenuItem } from "@mui/material";

import { useTranslation } from "react-i18next";

import CustomDialog from "lib/components/Modals/CustomModal";
import { getPeriods, updatePeriod } from "api/API";

const PrelungireModal = ({
  open = false,
  setOpen = () => {},
  clientsOnRoutesId,
  addCallback = () => {},
}) => {
  const { t } = useTranslation();
  const [periods, setPeriods] = useState([]);
  const [standardDays, setStandardDays] = useState("");
  const [customeDays, setCustomeDays] = useState("");
  const [finalDate, setFinalDate] = useState(new Date());

  const finalData = (startData, numberOfdays) => {
    const start = new Date(startData);
    const numberOfDaysToAdd = numberOfdays;
    const result = new Date(start.setDate(start.getDate() + numberOfDaysToAdd));

    return result;
  };

  useEffect(() => {
    getPeriods().then((res) => {
      setPeriods(res.filter((p) => p.time > clientsOnRoutesId.timeWorked));
    });
  }, [clientsOnRoutesId.clientsOnRoutesId]);

  return (
    <CustomDialog
      open={open}
      handleClose={() => setOpen(false)}
      title={t("Additional Contract")}
      button1={t("Add")}
      button2={t("Quit")}
      onClickButton1={() => {
        if (standardDays !== "") {
          updatePeriod(
            clientsOnRoutesId.clientsOnRoutesId,
            standardDays,
            finalData(
              clientsOnRoutesId.startDate,
              periods[periods.findIndex((p) => p.id === standardDays)].time
            ),
            addCallback
          );
        } else if (customeDays !== "") {
          updatePeriod(
            clientsOnRoutesId.clientsOnRoutesId,
            standardDays,
            finalData(clientsOnRoutesId.finalDate, Number(customeDays)),
            addCallback
          );
        }
        setStandardDays("");
        setCustomeDays("");
      }}
    >
      <Grid container justifyContent={"space-between"} spacing={2}>
        {periods.length > 0 && (
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              name="timeId"
              value={standardDays}
              onChange={(e) => {
                setStandardDays(e.target.value);
                if (customeDays !== "") {
                  setCustomeDays("");
                }
              }}
              label={t("Number of days")}
              sx={{ fieldset: { borderRadius: "1rem" } }}
            >
              {periods.map((period) => (
                <MenuItem value={period.id} key={period.id}>
                  {period.time}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Data prelungire contract"
              value={finalDate}
              inputFormat="DD/MM/YYYY"
              onChange={(value) => {
                setFinalDate(new Date(value));
                setFinalDate(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                    width: "16rem",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("Custom period")}
            name="time"
            value={customeDays}
            onChange={(e) => {
              setCustomeDays(e.target.value);
              if (standardDays !== "") {
                setStandardDays("");
              }
            }}
            sx={{
              fieldset: {
                borderRadius: "1.5rem",
              },
            }}
          ></TextField>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default PrelungireModal;
