import React from "react";
import { CustomDialog } from "lib";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getDashboardPayments, getDashboardRegistration } from "api/API";

import { Box, TextField, MenuItem, Button } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DashboardButtons } from "lib/components/Buttons/buttons";

const FilterDashboardCharts = ({
  open,
  setOpen,
  tableType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedValue,
  setSelectedValue,
  setData,
  setLabels,
  total,
  setTotal,
}) => {
  const { t } = useTranslation();

  const periodArray = [
    { id: 1, period: "day", name: t("Days ") },
    { id: 2, period: "month", name: t("Months") },
  ];

  const todaysDate = dayjs().format("YYYY-MM-DD");

  const handleSubmit = () => {
    const dataDeStart = dayjs(startDate).format("YYYY-MM-DD");
    const dataDeFinal = dayjs(endDate).format("YYYY-MM-DD");
    tableType === "money"
      ? getDashboardPayments({
          startDate: dataDeStart,
          endDate: dataDeFinal,
          tag: selectedValue,
          orice: false,
        }).then((res) => {
          setData(res.sumArray);
          setLabels(res.dates);
          setTotal(res.Total);
          setOpen(false);
        })
      : getDashboardRegistration({
          startDate: dataDeStart,
          endDate: dataDeFinal,
          tag: selectedValue,
          orice: false,
        }).then((res) => {
          setData(res.sumArray);
          setLabels(res.dates);
          setTotal(res.Total);
          setOpen(false);
        });
  };

  const handleDeleteFilters = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    tableType === "money"
      ? getDashboardPayments({
          startDate: todaysDate,
          endDate: todaysDate,
          tag: selectedValue,
          orice: true,
        }).then((res) => {
          setData(res.sumArray);
          setLabels(res.dates);
          setTotal(res.Total);
          setOpen(false);
        })
      : getDashboardRegistration({
          startDate: todaysDate,
          endDate: todaysDate,
          tag: selectedValue,
          orice: true,
        }).then((res) => {
          setData(res.sumArray);
          setLabels(res.dates);
          setTotal(res.Total);
          setOpen(false);
        });
  };

  return (
    <CustomDialog
      open={open}
      handleClose={() => setOpen(false)}
      title={
        tableType === "money"
          ? t("Filter by money")
          : t("Filter by registrations")
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "20rem",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={t("Start Date")}
            name="insurance"
            value={startDate}
            onChange={setStartDate}
            inputFormat="DD/MM/YYYY"
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label={t("End Date")}
            name="insurance"
            value={endDate}
            onChange={setEndDate}
            inputFormat="DD/MM/YYYY"
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
        <TextField
          select
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          sx={{
            fieldset: {
              borderRadius: "1.5rem",
            },
          }}
        >
          {periodArray.map((bla) => (
            <MenuItem key={bla.id} value={bla.period}>
              {bla.name}
            </MenuItem>
          ))}
        </TextField>
        <DashboardButtons onClick={handleSubmit}>{t("Apply")}</DashboardButtons>
        <DashboardButtons onClick={handleDeleteFilters}>
          {t("Delete filters")}
        </DashboardButtons>
      </Box>
    </CustomDialog>
  );
};

export default FilterDashboardCharts;
