import axios from "api/axios";
import { handleError } from "utils/functions";
import { handleErrorCustom, handleErrorCustomClients } from "utils/functions";

export const getAllClients = async (perPage, currentPage, pagesToLoad) => {
  try {
    const response = await axios.get("/clients", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getClient = async (id) => {
  try {
    const response = await axios.get("clients/" + id);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addClient = async (clientData, callback = () => {}) => {
  try {
    const res = await axios.post("/clients", clientData);
    return { ok: true, data: res.data };
    // callback();
  } catch (error) {
    handleError(error);
  }
};

export const editClient = async (clientId, clientData, callback = () => {}) => {
  try {
    const response = await axios.put(`/clients/${clientId}`, clientData);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteClient = async (clientId, callback = () => {}) => {
  try {
    await axios.delete(`/clients/${clientId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const blockClient = async (clientId, callback) => {
  try {
    await axios.patch(`/clients/block/${clientId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const unblockClient = async (clientId, callback = () => {}) => {
  try {
    await axios.patch(`/clients/unblock/${clientId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const getClientDocs = async (clientId) => {
  try {
    const response = await axios.get("/clients/file", {
      params: {
        clientId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const uploadClientDoc = async (
  clientId,
  formData,
  callback = () => {}
) => {
  try {
    const response = await axios.post(
      `/clients/file?clientId=${clientId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const uploadContractToAws = async (formData) => {
  try {
    const response = await axios.post(`/clients/uploadFileToAWS`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { ok: true, data: response.data };
  } catch (error) {
    return { ok: false, data: error };
  }
};

export const deleteClientDoc = async (docId, callback = () => {}) => {
  try {
    await axios.delete(`/clients/file/${docId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const getAdditionalInfo = async (clientId) => {
  try {
    const response = await axios.get("/aditionalInfo", {
      params: {
        clientId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addAdditionalInfo = async (
  clientId,
  data,
  callback = () => {}
) => {
  try {
    const response = await axios.post("/aditionalInfo", {
      clientId,
      ...data,
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const editAdditionalInfo = async (
  clientId,
  data,
  callback = () => {}
) => {
  try {
    try {
      for (const info of data) {
        await axios.put(`/aditionalInfo/${info.id}`, {
          title: info.title,
          content: info.content,
        });
      }

      callback();
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteAdditionalInfo = async (
  additionalInfoId,
  callback = () => {}
) => {
  try {
    await axios.delete(`/aditionalInfo/${additionalInfoId}`);
    callback(additionalInfoId);
  } catch (error) {
    console.error(error);
  }
};

export const getClientDepartures = async (clientId) => {
  try {
    const response = await axios.get(`/clientsOnRoute`, {
      params: {
        id: clientId,
        perPage: 10,
        pagesToLoad: 1,
        currentPage: 0,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addClientDeparture = async (
  departureData,
  callback = () => {}
) => {
  try {
    await axios.post("/clientsOnRoute", {
      ...departureData,
      paymentStatus: Number(departureData.paymentStatus) ?? 0,
    });
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const deleteClientDeparture = async (clientId, callback = () => {}) => {
  try {
    await axios.delete(`/clientsOnRoute/${clientId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const deleteDeparture = async (clientOnRouteId, callback = () => {}) => {
  try {
    await axios.delete(`/clientsOnRoute/client/${clientOnRouteId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const addFieldOnClient = async (
  clientId,
  fieldId,
  callback = () => {}
) => {
  try {
    const response = await axios.post("/clientsFields", {
      clientId,
      fieldId,
    });
    callback(response.data);
  } catch (error) {
    handleErrorCustom(error);
  }
};

export const getFieldsOnClient = async (clientId) => {
  try {
    const response = await axios.get(`/clientsFields/${clientId}`);
    return response.data;
  } catch (error) {
    handleError(error);
    return [];
  }
};

export const editFieldOnClient = async (
  clientId,
  data,
  callback = () => {}
) => {
  try {
    try {
      for (const info of data) {
        await axios.patch(`/clientsFields/${info.id}`, {
          clientId,
          fieldId: info.field.id,
        });
      }

      callback();
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteFieldOnClient = async (
  clientId,
  fieldId,
  callback = () => {}
) => {
  try {
    await axios.delete(`/clientsFields`, {
      params: {
        clientId,
        fieldId,
      },
    });
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const getAllDepartures = async (
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("clientsOnRoute/all/departures", {
      params: {
        perPage,
        pagesToLoad,
        currentPage,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findClientByText = async (text) => {
  try {
    const response = await axios.get("/clients/findByString/text", {
      params: {
        text,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findClientOnRouteByString = async (text) => {
  try {
    const response = await axios.get("/clientsOnRoute/searchAll/departure", {
      params: {
        text,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPaymentsForClient = async (clientId) => {
  try {
    const response = await axios.get(`/clients/payments/${clientId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
