import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TaskModal from "lib/modals/TaskModal";
import { useConfirm } from "hooks/useConfirm";

import { Comments, DetailsComponet } from "lib";

import { Typography, Breadcrumbs, Link, Box, Button } from "@mui/material";
import dayjs from "dayjs";

import { getTask, getAllUsers, updateProgressTask, finishTask } from "api/API";
import { toast } from "react-toastify";

function Task() {
  const { t } = useTranslation();
  const confirm = useConfirm();
  let { id } = useParams();
  const [task, setTask] = useState({});
  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusTask, setStatusTask] = useState("ToDo");

  useEffect(() => {
    getTask(id).then((task) => {
      const newTaskShape = {
        ...task,
      };

      if (task.userTask.length === 0) {
        newTaskShape.userTask = [
          {
            isDeleted: task.isDeleted,
            taskId: task.id,
            user: task.user,
            userId: task.userId,
          },
        ];
      }

      setTask(newTaskShape);
    });
    getAllUsers().then((users) => setUsers(users));
  }, [id]);

  const render = [
    {
      key: "userTask",
      label: t("Responsible"),
      cellModifier: (displayData) => {
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {displayData.map((user) => (
              <div
                key={user.userId}
                style={{
                  border: "1px solid rgba(233, 30, 99, 0.5)",
                  borderRadius: "1rem",
                  padding: "4px 14px",
                  color: "#E91E63",
                  marginLeft: "5px",
                  marginBottom: "5px",
                }}
              >
                <Typography>{user.user?.name}</Typography>
              </div>
            ))}
          </div>
        );
      },
    },

    {
      key: "taskStatus",
      label: "Status",
      cellModifier: (displayData) => {
        let colorFromStatus = "#e3cf1b";
        if (displayData === "ToDo") colorFromStatus = "#E91E63";
        if (displayData === "Done") colorFromStatus = "#3B873E";

        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{
                color: "#ffffff",
                backgroundColor: colorFromStatus,
                borderRadius: "1rem",
                "&:hover": {
                  backgroundColor: colorFromStatus,
                  transform: "scale(1.1)",
                },
              }}
              variant="contained"
              onClick={() =>
                displayData !== "Done" &&
                confirm(
                  t(
                    "Are you sure you want to update the status? This cant be undone!"
                  ),
                  () =>
                    displayData === "ToDo"
                      ? updateProgressTask(id, () =>
                          getTask(id).then((task) => setTask(task))
                        )
                      : finishTask(id, () =>
                          getTask(id).then((task) => setTask(task))
                        )
                )
              }
            >
              {t(displayData)}
            </Button>
          </Box>
        );
      },
    },
    {
      key: "createdAt",
      label: t("Created at"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">
            {dayjs(displayData).format("DD MMM YYYY, HH:mm")}
          </Typography>
        );
      },
    },
    {
      key: "deadline",
      label: "Deadline",
      cellModifier: (displayData) => (
        <Typography variant="detailsText">
          {dayjs(displayData).format("DD MMM YYYY")}
        </Typography>
      ),
    },
    {
      key: "description",
      label: t("Description"),
      cellModifier: (displayData) => (
        <Typography sx={{ marginLeft: "10px" }} variant="detailsText">
          {displayData}
        </Typography>
      ),
    },
    {
      key: "title",
      label: t("Title"),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Task details")}
        </Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>Task: {task?.title}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <div style={{ width: "100%", maxWidth: "35rem" }}>
        <DetailsComponet
          title={t("Task details")}
          showEdit
          tableData={task}
          render={render}
          onEdit={() => setModalOpen(task)}
        />
      </div>

      <Comments type={"TASK"} id={id} />

      <TaskModal
        open={modalOpen}
        setOpen={setModalOpen}
        users={users}
        task={task}
        editCallback={() =>
          getTask(id).then((task) => {
            setTask(task);
            setModalOpen(false);
            toast.success(t("Task has been modified!"));
          })
        }
      />
    </div>
  );
}

export default Task;
