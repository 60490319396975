import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//styles
import styles from "./Vehicle.module.scss";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useTranslation } from "react-i18next";

//mui
import {
  Typography,
  Link,
  Breadcrumbs,
  Box,
  TextField,
  Grid,
  Divider,
} from "@mui/material";

import dayjs from "dayjs";

import { toast } from "react-toastify";

import EditRovinietaModal from "lib/modals/EditRovinietaModal";

//icons
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";

//components
import {
  CustomDialog,
  DetailsComponet,
  DocumentModal,
  DetailsWrapper,
} from "../../lib";
import {
  getFleet,
  addRovineta,
  getRovineta,
  editVehicle,
  getDocsVehicle,
  uploadDocVehicle,
  uploadGasCreditCards,
  getGasCreditCards,
  deleteGasCreditCard,
  deleteDocVehicle,
} from "api/API";
import { Comments } from "../../lib";

function Vehicle() {
  let { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [documentModal, setDocumentModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [rovinietaModal, setRovinietaModal] = useState(false);
  const [addRovinietaData, setAddRovinietaData] = useState(null);
  const [roviniete, setRoviniete] = useState([]);

  const [gasCards, setGasCards] = useState([]);
  const [gasCardsModal, setGasCardsModal] = useState(false);

  const [rovinetaEditModal, setRovinetaEditModal] = useState(false);
  const [vehicleModal, setVehicleModal] = useState(false);
  const { t } = useTranslation();

  const [vehicleEditData, setVehicleEditData] = useState({
    brand: "",
    model: "",
    registrationPlate: "",
    fabricationYear: "",
    kilometres: "",
    casco: new Date(),
    insurance: new Date(),
    itp: new Date(),
  });

  const handleChange = (e) => {
    setAddRovinietaData({
      ...addRovinietaData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeEdit = (e) => {
    setVehicleEditData({
      ...vehicleEditData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getRovineta(id);
        setRoviniete(response);
      } catch (error) {
        console.error("Error: ", error);
      }
    })();
  }, []);

  useEffect(() => {
    getDocsVehicle(id).then((docs) => setDocs(docs));
    getFleet(id).then((fleet) => setVehicle(fleet));
    getGasCreditCards(id).then((gas) => setGasCards(gas));
  }, [id]);

  const render = [
    {
      key: "brand",
      label: t("Brand"),
    },
    {
      key: "model",
      label: "Model",
    },
    {
      key: "fabricationYear",
      label: t("Year of manufacture"),
    },
    {
      key: "registrationPlate",
      label: t("Registration Plate"),
    },
    {
      key: "kilometres",
      label: t("Number of kilometres"),
    },

    {
      key: "insurance",
      label: t("Insurance"),
      cellModifier: (displayData) => {
        return (
          <Typography> {dayjs(displayData).format("DD-MM-YYYY")}</Typography>
        );
      },
    },
    {
      key: "casco",
      label: "Casco",
      cellModifier: (displayData) => {
        return (
          <Typography> {dayjs(displayData).format("DD-MM-YYYY")}</Typography>
        );
      },
    },
    {
      key: "itp",
      label: "ITP",
      cellModifier: (displayData) => {
        return (
          <Typography> {dayjs(displayData).format("DD-MM-YYYY")}</Typography>
        );
      },
    },
  ];

  const handleSubmitRovineta = () => {
    if (addRovinietaData) {
      addRovineta(id, addRovinietaData, () => {
        getRovineta(id).then((res) => setRoviniete(res));
        toast.success("Rovinieta a fost adaugata cu succes");
      });
      setRovinietaModal(false);
      setAddRovinietaData("");
    } else {
      console.error("Incarca data");
      toast.error("A intervenit o eroare");
    }
  };

  const rovinietaData = roviniete?.reduce(
    (tableData, info) => ({
      ...tableData,
      [info.title]: dayjs(info.expire).format("DD-MM-YYYY"),
    }),
    {}
  );

  const rovinietaRender = roviniete?.reduce(
    (renderInfo, info) => [
      ...renderInfo,
      {
        key: info.title,
        label: info.title,
      },
      {
        key: info.expire,
        label: dayjs(info.expire).format("DD-MM-YYYY"),
      },
    ],
    []
  );

  useEffect(() => {
    if (!vehicleModal) return;

    const {
      brand,
      model,
      registrationPlate,
      fabricationYear,
      kilometres,
      casco,
      insurance,
      itp,
    } = vehicleModal;

    setVehicleEditData({
      brand,
      model,
      registrationPlate,
      fabricationYear,
      kilometres,
      casco,
      insurance,
      itp,
    });
  }, [vehicleModal]);

  const handleSubmitEditVehicle = () => {
    if (vehicleEditData) {
      const newEditVehicleData = { ...vehicleEditData };
      newEditVehicleData.fabricationYear = parseInt(
        newEditVehicleData.fabricationYear
      );
      editVehicle(id, newEditVehicleData, () => {
        getFleet(id).then((res) => setVehicle(res));
        toast.success("Vehiculul a fost updatat cu succes");
      });
      setVehicleModal(false);
      setVehicleEditData("");
    } else {
      console.error("Incarca data");
      toast.error("A intervenit o eroare");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Vehicle Details")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Nomenclators")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Fleet")} </div>
          </Link>
        </Breadcrumbs>
      </div>

      <div className={styles.parent}>
        <div className={styles.upperSide}>
          <div className={styles.leftUpper}>
            <DetailsComponet
              title={t("Details")}
              showEdit
              tableData={vehicle}
              render={render}
              onEdit={() => setVehicleModal(vehicle)}
            />
            <CustomDialog
              open={Boolean(vehicleModal)}
              handleClose={() => setVehicleModal(false)}
              title={t("Edit vehicle")}
              button1={t("Edit")}
              button2={t("Quit")}
              onClickButton1={() => handleSubmitEditVehicle()}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "white",
                  borderRadius: "1.5rem",
                  height: "100%",
                  width: "22rem",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          sx={{
                            fieldset: {
                              borderRadius: "1.5rem",
                            },
                          }}
                          autoComplete={"off"}
                          required
                          name="brand"
                          value={vehicleEditData.brand}
                          onChange={handleChangeEdit}
                          id="outlined-required"
                          label="Marca"
                        />
                      </Grid>

                      <TextField
                        fullWidth
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                        }}
                        autoComplete={"off"}
                        required
                        name="model"
                        value={vehicleEditData.model}
                        onChange={handleChangeEdit}
                        id="outlined-required"
                        label="Model"
                      />

                      <TextField
                        fullWidth
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                        }}
                        autoComplete={"off"}
                        required
                        name="registrationPlate"
                        value={vehicleEditData.registrationPlate}
                        onChange={handleChangeEdit}
                        id="outlined-required"
                        label="Numar inmatriculare"
                      />

                      <TextField
                        fullWidth
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                        }}
                        autoComplete={"off"}
                        required
                        name="fabricationYear"
                        value={vehicleEditData.fabricationYear}
                        onChange={handleChangeEdit}
                        id="outlined-required"
                        label="An fabricatie"
                      />

                      <TextField
                        fullWidth
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                        }}
                        autoComplete={"off"}
                        required
                        name="kilometres"
                        value={vehicleEditData.kilometres}
                        onChange={handleChangeEdit}
                        id="outlined-required"
                        label="Kilometri"
                      />

                      <Grid item xs={6}>
                        <DesktopDatePicker
                          label="Casco"
                          name="casco"
                          value={vehicleEditData?.casco}
                          onChange={(value) => {
                            handleChangeEdit({
                              target: { name: "casco", value },
                            });
                          }}
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                fieldset: {
                                  borderRadius: "1.5rem",
                                },
                                width: "16rem",
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <DesktopDatePicker
                        label="Asigurare"
                        name="insurance"
                        value={vehicleEditData?.insurance}
                        onChange={(value) => {
                          handleChangeEdit({
                            target: { name: "insurance", value },
                          });
                        }}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              fieldset: {
                                borderRadius: "1.5rem",
                              },
                              width: "16rem",
                            }}
                          />
                        )}
                      />

                      <DesktopDatePicker
                        label="ITP"
                        name="itp"
                        value={vehicleEditData?.itp}
                        onChange={(value) => {
                          handleChangeEdit({ target: { name: "itp", value } });
                        }}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              fieldset: {
                                borderRadius: "1.5rem",
                              },
                              width: "16rem",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
            </CustomDialog>
            <div className={styles.upperDown}>
              <DetailsWrapper
                title={t("Gasoline credit cards")}
                showAdd
                onAdd={() => setGasCardsModal(true)}
                padding={gasCards.length > 0 ? false : true}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {gasCards.map((doc, docIndex) => (
                    <Box key={doc.id} sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "1rem",
                        }}
                      >
                        <Typography>
                          {doc.docs.name || `Document nr. ${docIndex + 1}`}
                        </Typography>
                        <div>
                          <a
                            rel="noreferrer"
                            target={"_blank"}
                            href={`${process.env.REACT_APP_API_BASE}/cars/cards/${doc.docs.fileId}`}
                          >
                            <AttachFileIcon />
                          </a>
                          <DeleteIcon
                            color="errorCustom"
                            onClick={() =>
                              deleteGasCreditCard(doc.docs.id, () => {
                                let newDocs = [...gasCards];
                                newDocs = newDocs.filter(
                                  (d) => d.id !== doc.id
                                );
                                setGasCards(newDocs);
                                toast.success(
                                  `Documentul ${doc.docs.name} a fost șters!`
                                );
                              })
                            }
                          />
                        </div>
                      </Box>
                      {docIndex !== gasCards.length - 1 && <Divider />}
                    </Box>
                  ))}

                  {!gasCards.length && (
                    <Typography>
                      {t("Vehicle has no gas credit card.")}
                    </Typography>
                  )}
                </Box>
              </DetailsWrapper>

              {/* modal for gas credit cards */}
              <DocumentModal
                open={Boolean(gasCardsModal)}
                setOpen={setGasCardsModal}
                handleSubmit={(formData) =>
                  uploadGasCreditCards(vehicle.id, formData, (doc) => {
                    getGasCreditCards(vehicle.id).then((docs) => {
                      setGasCards(docs);
                      setGasCardsModal(false);
                      toast.success(t("Gas credit card has been uploaded!"));
                    });
                  })
                }
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <DetailsComponet
              title={t("Vignettes")}
              tableData={rovinietaData}
              showAdd
              showEdit
              render={rovinietaRender}
              onAdd={() => setRovinietaModal(true)}
              onEdit={() => setRovinetaEditModal(true)}
            />
            {/* rovineta add modal */}
            <CustomDialog
              title="Adauga o rovinieta"
              button1="Adauga"
              button2="Renunta"
              open={rovinietaModal}
              handleClose={() => setRovinietaModal(false)}
              onClickButton1={() => handleSubmitRovineta()}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "white",
                  borderRadius: "1.5rem",
                  height: "100%",
                }}
              >
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      required
                      id="outlined-required"
                      label="Title"
                      name="title"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Data Expirare"
                        name="expire"
                        value={addRovinietaData?.expire}
                        onChange={(value) => {
                          handleChange({ target: { name: "expire", value } });
                        }}
                        inputFormat="DD/MM/YYYY"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              fieldset: {
                                borderRadius: "1.5rem",
                              },
                              width: "16rem",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
            </CustomDialog>

            {/* rovinieta edit modal */}

            <EditRovinietaModal
              open={Boolean(rovinetaEditModal)}
              setOpen={setRovinetaEditModal}
              info={roviniete}
              vehicleId={vehicle?.id}
              editCallback={async () => {
                setRovinetaEditModal(false);
                try {
                  const newRovineta = await getRovineta(vehicle.id);
                  setRoviniete(newRovineta);
                  toast.success("Rovineta a fost modificata!");
                } catch (error) {
                  console.error(error);
                }
              }}
              deleteCallback={(rovinietaId) => {
                setRovinetaEditModal(false);
                setRoviniete(roviniete.filter((i) => i.id !== rovinietaId));
                toast.success("Rovinieta a fost ștearsă!");
              }}
            />
            <div className={styles.rightSideDown}>
              {/* add doc modal */}
              <DetailsWrapper
                title={t("Documents")}
                showAdd
                onAdd={() => setDocumentModal(true)}
                padding={docs.length > 0 ? false : true}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {docs.map((doc, docIndex) => (
                    <Box key={doc.id} sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "1rem",
                        }}
                      >
                        <Typography>
                          {doc.docs.name || `Document nr. ${docIndex + 1}`}
                        </Typography>
                        <div>
                          <a
                            rel="noreferrer"
                            target={"_blank"}
                            href={`${process.env.REACT_APP_API_BASE}/cars/file/${doc.docs.fileId}`}
                          >
                            <AttachFileIcon />
                          </a>
                          <DeleteIcon
                            color="errorCustom"
                            onClick={() =>
                              deleteDocVehicle(doc.docs.id, () => {
                                let newDocs = [...docs];
                                newDocs = newDocs.filter(
                                  (d) => d.id !== doc.id
                                );
                                setDocs(newDocs);
                                toast.success(
                                  `Documentul ${doc.docs.name} a fost șters!`
                                );
                              })
                            }
                          />
                        </div>
                      </Box>
                      {docIndex !== docs.length - 1 && <Divider />}
                    </Box>
                  ))}

                  {!docs.length && (
                    <Typography>{t("Vehicle has no documents.")}</Typography>
                  )}
                </Box>
              </DetailsWrapper>

              <DocumentModal
                open={documentModal}
                setOpen={setDocumentModal}
                handleSubmit={(formData) =>
                  uploadDocVehicle(vehicle.id, formData, (doc) => {
                    getDocsVehicle(vehicle.id).then((docs) => {
                      setDocs(docs);
                      setDocumentModal(false);
                      toast.success("File has been uploaded!");
                    });
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.downSide}>
        <Comments type="CAR" id={id} />
      </div>
    </div>
  );
}

export default Vehicle;
