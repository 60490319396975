import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CustomDialog } from "lib";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { addPaymentOnDeparture, updatePaymentData } from "api/API";

import { Box, TextField, Grid } from "@mui/material";

const AddPaymentOnDeparture = ({ open, setOpen, id, rowData, addCallback }) => {
  const { t } = useTranslation();

  const [addPaymentData, setAddPaymentData] = useState({});

  const handleChange = (e) => {
    setAddPaymentData({
      ...addPaymentData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (Object.keys(rowData).length > 0) setAddPaymentData(rowData);
    else setAddPaymentData({});
  }, [rowData]);

  const handleSubmit = () => {
    const newData = {};
    newData.departureId = id;
    newData.payment = Number(addPaymentData.payment);
    newData.paymentName = addPaymentData.paymentName;
    newData.paymentType = addPaymentData.paymentType;
    newData.paymentDate = addPaymentData.paymentDate;
    if (Object.keys(rowData).length > 0) {
      updatePaymentData(rowData.id, newData, addCallback);
    } else {
      addPaymentOnDeparture(newData, addCallback);
    }

    setOpen(false);
  };

  return (
    <CustomDialog
      open={open}
      handleClose={() => setOpen(false)}
      button1={t("Add new payment")}
      button2={t("Cancel")}
      title={t("Add payment")}
      onClickButton1={handleSubmit}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "30rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="paymentName"
              value={addPaymentData.paymentName}
              onChange={handleChange}
              id="outlined-required"
              label={t("Payment Name")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="paymentType"
              value={addPaymentData.paymentType}
              onChange={handleChange}
              id="outlined-required"
              label={t("Payment Type")}
            />
          </Grid>

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Payment Date")}
                name="paymentDate"
                value={addPaymentData.paymentDate}
                onChange={(value) => {
                  handleChange({ target: { name: "paymentDate", value } });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="payment"
              value={addPaymentData.payment}
              onChange={handleChange}
              id="outlined-required"
              label={t("Amount Paid")}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AddPaymentOnDeparture;
