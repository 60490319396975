import React, { useEffect, useState } from "react";
import CustomDialog from "lib/components/Modals/CustomModal";
import { Box, Grid, TextField } from "@mui/material";
import { addAdditionalInfo } from "api/clientApi";
import { useTranslation } from "react-i18next";

const AdditionalInfoModal = ({ open, setOpen, clientId, addCallback }) => {
  const [data, setData] = useState({});
  const { t } = useTranslation();

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!open) return;

    setData({
      title: "",
      content: "",
    });
  }, [open]);

  return (
    <CustomDialog
      open={open}
      handleClose={() => setOpen(false)}
      button1={t("Finalize")}
      button2={t("Quit")}
      title={t("Add new info")}
      onClickButton1={() => addAdditionalInfo(clientId, data, addCallback)}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "22rem",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              name="title"
              value={data.title}
              onChange={handleChange}
              id="outlined-required"
              label={t("Title")}
            />

            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              name="content"
              value={data.content}
              onChange={handleChange}
              id="outlined-required"
              label={t("Content")}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AdditionalInfoModal;
