import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Breadcrumbs, Link, Box, Typography, Divider } from "@mui/material";

import {
  Details,
  Departures,
  Taxes,
  Problems,
  Notes,
  Payments,
  Signature,
} from "./";
import { getClient } from "api/clientApi";

function Client() {
  let { id } = useParams();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("details");
  const [client, setClient] = useState({});

  useEffect(() => {
    getClient(id).then((client) => setClient(client?.client));
  }, [id]);

  return client?.id ? (
    <>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography variant="pageTitle">{t("Client details")}</Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>

          <Link underline="hover" color="inherit">
            <div>{t("Clients")}</div>
          </Link>

          <Link aria-current="page" underline="hover" color="text.primary">
            <div style={{ textTransform: "capitalize" }}>
              Client - {client.firstName} {client.lastName}
            </div>
          </Link>
        </Breadcrumbs>

        <Box
          sx={{
            color: "rgba(66, 82, 110, 0.86)",
            marginTop: "4rem",
            display: "flex",
          }}
        >
          <Typography
            variant="tabs"
            sx={{
              paddingInline: "2rem",
              cursor: "pointer",
              color: activeTab === "details" && "#5664D2",
              borderBottom: activeTab === "details" && "2px solid #5664D2",
            }}
            onClick={() => {
              setActiveTab("details");
            }}
          >
            {t("Client details")}
          </Typography>

          <Typography
            variant="tabs"
            sx={{
              paddingInline: "2rem",
              cursor: "pointer",
              color: activeTab === "payments" && "#5664D2",
              borderBottom: activeTab === "payments" && "2px solid #5664D2",
            }}
            onClick={() => {
              setActiveTab("payments");
            }}
          >
            {t("Client payments")}
          </Typography>
          <Typography
            variant="tabs"
            sx={{
              paddingInline: "2rem",
              cursor: "pointer",
              color: activeTab === "departures-tur" && "#5664D2",
              borderBottom:
                activeTab === "departures-tur" && "2px solid #5664D2",
            }}
            onClick={() => {
              setActiveTab("departures-tur");
            }}
          >
            {t("Departures")}
          </Typography>
          <Typography
            variant="tabs"
            sx={{
              paddingInline: "2rem",
              cursor: "pointer",
              color: activeTab === "taxes" && "#5664D2",
              borderBottom: activeTab === "taxes" && "2px solid #5664D2",
            }}
            onClick={() => {
              setActiveTab("taxes");
            }}
          >
            {t("Taxes")}
          </Typography>
          <Typography
            variant="tabs"
            sx={{
              paddingInline: "2rem",
              cursor: "pointer",
              color: activeTab === "problems" && "#5664D2",
              borderBottom: activeTab === "problems" && "2px solid #5664D2",
            }}
            onClick={() => {
              setActiveTab("problems");
            }}
          >
            {t("Problems")}
          </Typography>
          <Typography
            variant="tabs"
            onClick={() => {
              setActiveTab("notes");
            }}
            sx={{
              paddingInline: "2rem",
              cursor: "pointer",
              color: activeTab === "notes" && "#5664D2",
              borderBottom: activeTab === "notes" && "2px solid #5664D2",
            }}
          >
            {t("Notes")}
          </Typography>
        </Box>
        <Divider />
      </div>

      {activeTab === "details" ? (
        <Details />
      ) : activeTab === "departures-tur" ? (
        <Departures />
      ) : activeTab === "taxes" ? (
        <Taxes />
      ) : activeTab === "problems" ? (
        <Problems />
      ) : activeTab === "notes" ? (
        <Notes />
      ) : activeTab === "payments" ? (
        <Payments />
      ) : null}
    </>
  ) : (
    <></>
  );
}

export default Client;
