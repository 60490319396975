import React, { useMemo, useState, useEffect } from "react";
import { CustomDialog } from "lib";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Box, Grid, MenuItem, TextField, Autocomplete } from "@mui/material";
import { addTask, editTask } from "api/API";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const TaskModal = ({
  open = false,
  setOpen = () => {},
  users = [],
  addCallback = () => {},
  editCallback = () => {},
  task,
}) => {
  let { id } = useParams();
  const { t } = useTranslation();

  const defaultTaskData = useMemo(
    () => ({
      title: "",
      deadline: Date.now(),
      description: "",
      userTask: [],
      status: false,
    }),
    []
  );

  const [userNames, setUserNames] = useState([]);

  const handleChange = (e) => {
    setTaskData({
      ...taskData,
      [e.target.name]: e.target.value,
    });
  };

  const [taskData, setTaskData] = useState(defaultTaskData);

  useEffect(() => {
    if (!open) return;

    if (open === true) {
      setTaskData(defaultTaskData);
      // setUserNames(open.userTask.map((usr) => usr.user.name));
      return;
    }

    const { title, deadline, description, status, userTask } = open;

    setTaskData({
      title,
      deadline,
      description,
      userTask: userTask.map((u) => u.user),
      status,
    });
  }, [open, defaultTaskData]);

  //we are using this function to get the data from the mui autocomplete and then we create an array ({userId:3, userId:4} ...)

  const handleChangeResponsibleClients = (event, value) => {
    setTaskData({
      ...taskData,
      userTask: value,
    });
  };

  const handleAddTaskData = () => {
    let newTaskData = {
      ...taskData,
      userTask: taskData.userTask.map((user) => ({ userId: user.id })),
    };

    addTask(newTaskData, addCallback);
  };

  const handleEditTaskData = () => {
    let newTaskData = {
      ...taskData,
      userTask: taskData.userTask.map((user) => ({ userId: user.id })),
    };
    editTask(id, newTaskData, editCallback);
  };

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={typeof open === "boolean" ? t("Add task") : t("Edit task")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() =>
        typeof open === "boolean" ? handleAddTaskData() : handleEditTaskData()
      }
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          maxWidth: "30rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={6}>
              <div>
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  name="title"
                  value={taskData.title}
                  label={t("Title")}
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Deadline")}
                  name="deadline"
                  value={taskData.deadline}
                  onChange={(value) => {
                    handleChange({ target: { name: "deadline", value } });
                  }}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={t("Description")}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                name="description"
                value={taskData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={users}
                value={taskData.userTask}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeResponsibleClients}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                    label={t("Users")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default TaskModal;
