import {
    Typography,
    TextField,
    Button,
    Breadcrumbs,
    Link,
    Box,
} from '@mui/material'

import styles from './Reports.module.scss'
import React, { useState } from 'react'
import { CustomTable, CustomDialog } from 'lib'
import { useTranslation } from 'react-i18next'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import AddIcon from '@mui/icons-material/Add'

import {
  getAllReports,
  getDailyReport,
  returnDailyReport,
  getPaymentsReport,
} from "api/API";
import dayjs from "dayjs";

const Reports = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectDatesModal, setSelectDatesModal] = useState(false);
  const [raportType, setRaportType] = useState("");

  const getReports = async () => {
    let sd = startDate;
    let ed = endDate;

    if (!(startDate instanceof Date)) sd = new Date(startDate);
    if (!(endDate instanceof Date)) ed = new Date(endDate);

    // Take first month moment
    sd.setHours(0);
    sd.setMinutes(0);
    sd.setSeconds(0);
    sd.setMilliseconds(0);
    sd.setDate(0);

    // Take last month moment
    ed.setHours(23);
    ed.setMinutes(59);
    ed.setSeconds(59);
    ed.setMilliseconds(999);
    ed.setDate(new Date(ed.getFullYear(), ed.getMonth() + 1, 0).getDate());

    if (raportType === "monthly") {
      getAllReports(sd, ed).then((res) => {
        window.open(`${process.env.REACT_APP_API_BASE}/reports/${res}`);
        // window.open(`/reports/${res}`);
      });
    } else {
      getPaymentsReport(sd, ed).then((res) => {
        window.open(`${process.env.REACT_APP_API_BASE}/reports/${res}`);
        // window.open(`/reports/${res}`);
      });
    }

    setSelectDatesModal(false);
  };

  const getReport = () => {
    getDailyReport().then((res) => {
      const id = res;
      returnDailyReport(id).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", id);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    });
  };

  const labels = [
    {
      id: "nameReport",
      label: t("Name of the report"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  const reportData = [
    {
      id: 1,
      name: (
        <Typography variant="tableContent">{t("Monthly Report")}</Typography>
      ),
      tag: "withDate",
    },
    {
      id: 2,
      name: <Typography variant="tableContent">{t("Daily Report")}</Typography>,
      tag: "noDate",
    },
    {
      id: 3,
      name: (
        <Typography variant="tableContent">{t("Payments Report")}</Typography>
      ),
      tag: "payments",
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <Button
            variant={"outlined"}
            sx={{ borderRadius: "1rem" }}
            onClick={
              row.tag === "withDate"
                ? () => {
                    setRaportType("monthly");
                    setSelectDatesModal(true);
                  }
                : row.tag === "noDate"
                ? () => getReport()
                : row.tag === "payments"
                ? () => {
                    setRaportType("payments");
                    setSelectDatesModal(true);
                  }
                : undefined
            }
          >
            {" "}
            <Typography variant="tableContent">
              {t("Download Report")}
            </Typography>{" "}
          </Button>
          &nbsp; &nbsp; &nbsp;
        </>
      );
    }

    if (column.id === "nameReport") {
      return <Typography variant="tableContent">{row.name}</Typography>;
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {" "}
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Reports")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Nomenclator")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Reports")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <div className={styles.content}>
        <CustomTable
          title={t("Reports")}
          cellModifier={cellModifier}
          tableData={reportData}
          labels={labels}
        ></CustomTable>

        <CustomDialog
          handleClose={() => setSelectDatesModal(false)}
          open={selectDatesModal}
          title={t("Select a period")}
        >
          <Box
            fullWidth
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "1.5rem",
              height: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ marginBottom: "16px" }}>
                <DesktopDatePicker
                  label={t("Start Date")}
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
                  inputFormat="MM/YYYY"
                  views={["year", "month"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginBottom: "16px" }}>
                <DesktopDatePicker
                  views={["year", "month"]}
                  label={t("End Date")}
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                  inputFormat="MM/YYYY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                    />
                  )}
                />
              </Box>

              <Box>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{
                    borderRadius: "1rem",
                  }}
                  onClick={getReports}
                >
                  {t("Download Report")}
                </Button>
              </Box>
            </LocalizationProvider>
          </Box>
        </CustomDialog>
      </div>
    </div>
  );
};

export default Reports
