import React from "react";
import { CustomDialog } from "lib";
import { Box, Grid, Typography, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterTaxReturnModal = ({
  open = false,
  setOpen = () => {},
  taxes = [],
  selectedTax = [],
  setSelectedTax = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Filter by tax type")}
      button1={t("Finalize")}
      button2={t("Quit")}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "20rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          {taxes.map((tax) => (
            <Grid item xs={12} key={tax.id}>
              <Box
                sx={{
                  //   width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                  borderRadius: "1rem",
                  padding: "1rem",
                  "&:hover": {
                    background: "#f4f4f4",
                  },
                }}
                onClick={() => {
                  if (selectedTax.indexOf(tax.name) > -1) {
                    setSelectedTax(
                      selectedTax.filter(
                        (selectedTaxId) => selectedTaxId !== tax.name
                      )
                    );
                  } else {
                    setSelectedTax([...selectedTax, tax.name]);
                  }
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Typography>{tax.name}</Typography>
                </Box>
                <Checkbox checked={selectedTax.indexOf(tax.name) > -1} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default FilterTaxReturnModal;
