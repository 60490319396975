import axios from "api/axios";
import { handleError } from "utils/functions";
import { handleErrorCustom } from "utils/functions";

export const filterByDate = async (filterData) => {
  const newDate = {
    countryId: filterData.countryId,
    cityId: filterData.cityId,
    stateId: filterData.stateId,
    gender: filterData.Gender,
    contactPerson: filterData.contactPerson,
    birthday:
      filterData.birthday === null ? null : new Date(filterData.birthday),
    // birthday: new Date(filterData.birthday),
    CIP: filterData.CIP,
    tagId: filterData.tagId,
    age: filterData.age,
  };

  try {
    const response = await axios.get("/clients/filter/Clients", {
      params: {
        ...newDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const searchClientWhenAddingToTrip = async (searchData) => {
  try {
    const response = await axios.get("/clientsOnRoute/search/departure", {
      params: {
        ...searchData,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
