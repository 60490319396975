import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "hooks/useConfirm";

import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { CustomTablePagination } from "lib";

import { Typography, Breadcrumbs, Link, Chip } from "@mui/material";

import {
  getAllClients,
  getAllFields,
  deleteClient,
  findClientByText,
  getCountries,
  getStates,
  getCities,
} from "api/API";

import { filterByDate } from "api/filterApi";

import ClientsFilterModal from "lib/modals/ClientsFilterModal";
import DatePickerFilter from "lib/modals/DatePickerFilter";

import AddClientModal from "./AddClientModal";

function VisualizeClients() {
  const navigate = useNavigate();
  const [addModal, setAddModal] = useState(false);
  const [loadingClients, setLoadingClients] = useState(true);
  const [computingClients, setComputingClients] = useState(false);

  const [clients, setClients] = useState([]);
  const [displayClients, setDisplayClients] = useState([]);
  const [fields, setFields] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const [filterModal, setFilterModal] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [datePickerData, setDatePickerData] = useState({
    birthday: null,
    CIP: null,
    age: null,
    Gender: null,
    cityId: null,
    countryId: null,
    stateId: null,
    tagId: null,
    contactPerson: null,
  });

  const { t } = useTranslation();
  const confirm = useConfirm();

  const labels = [
    {
      id: "firstName",
      label: t("First name"),
      minWidth: 10,
      onClick: () => console.log("name clicked"),
    },
    {
      id: "lastName",
      label: t("Last name"),
      minWidth: 10,
      onClick: () => console.log("name clicked"),
    },
    {
      id: "age",
      label: t("Age"),
      minWidth: 10,
      onClick: () => console.log("age clicked"),
    },
    {
      id: "phone",
      label: t("Phone Number"),
      minWidth: 10,
      onClick: () => console.log("phone clicked"),
    },
    {
      id: "states",
      label: t("State"),
      minWidth: 10,
      onClick: () => console.log("states clicked"),
    },
    {
      id: "cities",
      label: t("City"),
      minWidth: 10,
      onClick: () => console.log("states clicked"),
    },
    {
      id: "Adress",
      label: t("Address"),
      minWidth: 10,
      onClick: () => console.log("address clicked"),
    },
    {
      id: "CIP",
      label: t("CIP"),
      minWidth: 10,
      onClick: () => console.log("lastLeave clicked"),
    },

    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("actions clicked"),
    },
  ];

  const span = (number) => {
    return (
      <p>
        Probleme
        <span style={{ fontWeight: 800, color: "#E91E63", marginLeft: "5px" }}>
          {number}
        </span>
      </p>
    );
  };

  useEffect(() => {
    setComputingClients(true);
    const displayClients = [...clients];

    for (let i = 0; i < displayClients.length; i++) {
      const client = displayClients[i];
      const tags = client.TagOnClient.map((tag) => tag.tag);

      client.info =
        tags.length || client.Problems.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              padding: "10px",
              gap: "10px",
            }}
          >
            {client.Problems.length > 0 ? (
              <Chip
                icon={
                  <PriorityHighIcon
                    color="#FFFFFF"
                    sx={{
                      backgroundColor: "#E91E63",
                      borderRadius: "1rem",
                    }}
                    style={{
                      color: "white",
                    }}
                  />
                }
                label={span(client.Problems.length)}
                sx={{
                  border: "1px solid #E91E63",
                  background: "#FFFFFF",
                  color: "#E91E63",
                }}
              />
            ) : null}

            {tags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                sx={{
                  background: tag.type,
                }}
              />
            ))}
          </div>
        ) : null;
    }

    setDisplayClients(displayClients);
    setComputingClients(false);
    // };
  }, [clients]);

  useEffect(() => {
    (async () => {
      setLoadingClients(true);
      // await computeClientsAndTags();

      const fields = await getAllFields();
      setFields(fields);

      setLoadingClients(false);
    })();
  }, []);

  const getAndSetStates = async (countryId) => {
    getStates(countryId).then((states) => setStates(states));
  };

  useEffect(() => {
    getCountries().then((countries) => setCountries(countries));
    getAndSetStates(181);
  }, []);

  const getAndSetCities = async (countryId, stateId) => {
    getCities(countryId, stateId).then((cities) => setCities(cities));
  };

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`./` + row.id)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm("Esti sigur ca vrei sa stergi clientul?", () =>
                deleteClient(row.id, () => {
                  setTriggerRefetch(!triggerRefetch);
                  //computeClientsAndTags();
                })
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }

    if (column.id === "age") {
      return (
        <Typography variant={"tableContent"}>
          {dayjs().diff(row.birthday, "year") + " ani"}
        </Typography>
      );
    }

    if (column.id === "fieldName") {
      return (
        <Typography variant="tableContent">
          {row.ClientFields?.map((field, index) => {
            if (index + 1 === row.ClientFields?.length) {
              return field.field?.name;
            } else {
              return field.field?.name + ", ";
            }
          })}
        </Typography>
      );
    }

    if (column.id === "states") {
      return row.states ? (
        <Typography variant="tableContent">{row.states?.name}</Typography>
      ) : (
        <Typography variant="tableContent">{t("No data available")}</Typography>
      );
    }
    if (column.id === "cities") {
      return row.city ? (
        <Typography variant="tableContent">{row.city?.name}</Typography>
      ) : (
        <Typography variant="tableContent">{t("No data available")}</Typography>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Visualize clients")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Clients")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("All clients")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTablePagination
        title={t("Clients")}
        showSearchbar
        showAdd
        onAdd={() => setAddModal(true)}
        onFilters={() => setFilterModal(true)}
        showDatePickerFilter
        labels={labels}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        tableData={displayClients}
        cellModifier={cellModifier}
        loading={loadingClients}
        setLoading={setLoadingClients}
        getterFunction={getAllClients}
        setterFunction={setClients}
        searchFunction={findClientByText}
        ComponentUsedForFiltering={DatePickerFilter}
        filterData={datePickerData}
        setFilterData={setDatePickerData}
        filterFunction={filterByDate}
      />

      <ClientsFilterModal open={filterModal} setOpen={setFilterModal} />

      {addModal && (
        <AddClientModal
          addModal={addModal}
          setAddModal={setAddModal}
          computingClients={computingClients}
          triggerRefetch={triggerRefetch}
          setTriggerRefetch={setTriggerRefetch}
          getAndSetCities={getAndSetCities}
          getAndSetStates={getAndSetStates}
          countries={countries}
          states={states}
          cities={cities}
        />
      )}
    </div>
  );
}

export default VisualizeClients;
