import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  Chip,
} from "@mui/material";
import { useConfirm } from "hooks/useConfirm";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";

import { CustomTable, CustomDialog } from "lib";
import { getAllFields, addField, editField, deleteField } from "api/API";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

function Fields() {
  const [fields, setFields] = useState([]);
  const [fieldModal, setFieldModal] = useState(false);
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [query, setQuery] = useState("");
  const [loadingFields, setLoadingFields] = useState(true);
  const confirm = useConfirm();
  const { t } = useTranslation();
  const { can } = useUser();

  const canAlterFields = can(17);

  useEffect(() => {
    getAllFields().then((fields) => {
      setFields(fields);
      setLoadingFields(false);
    });
  }, []);

  useEffect(() => {
    if (!fieldModal) return;

    if (fieldModal === true) {
      setName("");
      setColor("#f4f4f4");
      return;
    }

    setName(fieldModal.name);
  }, [fieldModal]);

  const labels = [
    {
      id: "name",
      label: t("Name"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "type",
      label: t("Type"),
      minWidth: 10,
    },
    ...(canAlterFields
      ? [
          {
            id: "actions",
            label: t("Actions"),
            minWidth: 10,
            onClick: () => {},
          },
        ]
      : []),
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "type")
      return (
        <div
          style={{
            width: 25,
            height: 25,
            backgroundColor: value,
            borderRadius: "50%",
          }}
        ></div>
      );
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setFieldModal(row)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp; &nbsp; &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(t("Are you sure you want to delete this field?"), () =>
                deleteField(row.id, () => {
                  getAllFields().then((fields) => {
                    setFields(fields);
                    toast.success(t("Field has been deleted!"));
                  });
                })
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }

    return <Typography variant="tableContent"> {value}</Typography>;
  };

  return (
    <div>
      <div>
        <div style={{ marginBottom: "2rem" }}>
          <Typography variant="pageTitle">{t("Fields")}</Typography>

          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" color="inherit">
              <div>{t("Administration")}</div>
            </Link>
            <Link aria-current="page" underline="hover" color="text.primary">
              <div>{t("Fields")}</div>
            </Link>
          </Breadcrumbs>
        </div>

        <CustomTable
          showAdd={canAlterFields}
          showSearchbar
          title={t("Fields")}
          labels={labels}
          loading={loadingFields}
          tableData={fields.filter((field) => {
            if (
              query === "" ||
              field.name.toLowerCase().includes(query.toLowerCase())
            )
              return true;
          })}
          cellModifier={cellModifier}
          onAdd={() => setFieldModal(true)}
          onSearch={(e) => setQuery(e.target.value)}
        />

        {/* ---[Add/Edit Field Modal]--- */}
        <CustomDialog
          title={
            typeof fieldModal === "boolean" ? t("Add field") : t("Edit field")
          }
          open={Boolean(fieldModal)}
          handleClose={() => setFieldModal(false)}
          button1={typeof fieldModal === "boolean" ? t("Add") : t("Edit")}
          button2={t("Quit")}
          onClickButton1={() =>
            typeof fieldModal === "boolean"
              ? addField(name, color, (field) => {
                  getAllFields().then((fields) => {
                    setFields(fields);
                    setFieldModal(false);
                    toast.success(
                      `Ai adăugat cultura ${field.name} cu succes!`
                    );
                  });
                })
              : editField(fieldModal.id, name, color, (field) => {
                  getAllFields().then((fields) => {
                    setFields(fields);
                    setFieldModal(false);
                    toast.success(
                      `Ai modificat cultura ${field.name} cu succes!`
                    );
                  });
                })
          }
        >
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoComplete={"off"}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("Name")}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "2rem" }}
              >
                <input
                  type="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                  name="type"
                  style={{
                    width: 50,
                    height: 50,
                  }}
                />

                <div>
                  <Typography sx={{ fontSize: 12 }}>Preview tag:</Typography>
                  <Chip
                    label={name?.length > 0 ? name : t("Text field")}
                    sx={{
                      backgroundColor: color,
                      "& span": {
                        mixBlendMode: "difference",
                        color: "white",
                      },
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    </div>
  );
}

export default Fields;
