import axios from "api/axios";
import { handleError } from "utils/functions";

export const addTaxReturn = async (taxData, callback = () => {}) => {
  try {
    const response = await axios.post("/tax", taxData);
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const getTaxesByClient = async (clientId) => {
  try {
    const response = await axios.get(`/tax/clientId/${clientId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllTaxesReturn = async (
  perPage = 100,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/tax", {
      params: {
        perPage,
        pagesToLoad,
        currentPage,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getTaxesById = async (taxId) => {
  try {
    const response = await axios.get(`/tax/${taxId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDocsTaxes = async (clientId) => {
  try {
    const response = await axios.get("/tax/file", {
      params: {
        clientId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const uploadDocTaxes = async (
  clientId,
  taxId,
  formData,
  callback = () => {}
) => {
  try {
    const response = await axios.post("/tax/file", formData, {
      params: {
        clientId,
        taxId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteDocTaxes = async (docId, callback = () => {}) => {
  try {
    await axios.delete(`/tax/file/${docId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const uploadAddtionalDocTaxes = async (
  clientId,
  taxId,
  formData,
  callback = () => {}
) => {
  try {
    const response = await axios.post("/tax/additionalFile", formData, {
      params: {
        clientId,
        taxId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const getAdditionalDocsTaxes = async (clientId) => {
  try {
    const response = await axios.get("/tax/additionalFile", {
      params: {
        clientId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteAdditionalDocTaxes = async (docId, callback = () => {}) => {
  try {
    await axios.delete(`/tax/additionalFile/${docId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const editTaxesDetails = async (taxId, taxData, callback = () => {}) => {
  try {
    const response = await axios.put(`/tax/${taxId}`, taxData);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteTax = async (taxId, callback = () => {}) => {
  try {
    await axios.delete(`/tax/${taxId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const getAllEligibleTax = async () => {
  try {
    const response = await axios.get("/clientsOnRoute/taxes/allEligibleTax");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteElligibleTax = async (
  clientsOnRouteId,
  callback = () => {}
) => {
  try {
    await axios.delete(`/clientsOnRoute/tax/${clientsOnRouteId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};
