import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./RegisteringTaxReturn.module.scss";
import { getTaxDetailsColor } from "utils/functions";

import { DetailsComponet, CustomDialog, DocumentModal, Comments } from "lib";

import DocumentDetails from "lib/components/DocumentDetails/DocumentDetails";

import {
  Breadcrumbs,
  Link,
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";

import {
  getTaxesById,
  getDocsTaxes,
  uploadDocTaxes,
  deleteDocTaxes,
  getAdditionalDocsTaxes,
  uploadAddtionalDocTaxes,
  deleteAdditionalDocTaxes,
  editTaxesDetails,
} from "api/API";
import { toast } from "react-toastify";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function RegisteringTaxReturn() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [tax, setTax] = useState({});
  const [client, setClient] = useState({});

  const [docs, setDocs] = useState([]);
  const [documentModal, setDocumentModal] = useState(false);

  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [additionalDocsModal, setAdditionalDocsModal] = useState(false);

  const [detailsEditData, setDetailsEditData] = useState({});
  const [editModal, setEditModal] = useState(false);

  const renderClient = [
    {
      key: "firstName",
      label: t("Name"),
    },
    {
      key: "lastName",
      label: t("Surname"),
    },
    {
      key: "birthday",
      label: t("Birthday"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">
            {" "}
            {dayjs(displayData).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },

    {
      key: "phone",
      label: t("Phone"),
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "CNP",
      label: "CNP",
    },
    {
      key: "Adress",
      label: t("Address"),
    },
  ];

  const renderDetails = [
    {
      key: "type",
      label: t("Type"),
    },
    {
      key: "status",
      label: "Status",
      cellModifier: (displayData) => {
        let colorFromStatus = getTaxDetailsColor();

        return (
          <Typography
            variant="detailsText"
            sx={{
              backgroundColor: colorFromStatus,
              color: "white",
              padding: "6px 12px",
              borderRadius: "1rem",
            }}
          >
            {displayData}
          </Typography>
        );
      },
    },
    {
      key: "payed",
      label: t("Payed"),
      cellModifier: (displayData) => {
        return <Typography variant="detailsText">{displayData}€</Typography>;
      },
    },
    {
      key: "payedGermany",
      label: t("Payed Germany"),
      cellModifier: (displayData) => {
        if (displayData === true) {
          return <Typography variant="detailsText">DA</Typography>;
        } else return <Typography variant="detailsText">NU</Typography>;
      },
    },
    {
      key: "payedTax",
      label: t("Payed UnionTax"),
      cellModifier: (displayData) => {
        if (displayData === true) {
          return <Typography sx={{ color: "#253858" }}>DA</Typography>;
        } else return <Typography sx={{ color: "#253858" }}>NU</Typography>;
      },
    },
    {
      key: "comision",
      label: t("Comission"),
      cellModifier: (displayData) => {
        return <Typography variant="detailsText">{displayData}%</Typography>;
      },
    },
    {
      key: "brut",
      label: t("Gross"),
      cellModifier: (displayData) => {
        return <Typography variant="detailsText">{displayData}€</Typography>;
      },
    },
  ];

  useEffect(() => {
    getTaxesById(id).then((res) => {
      setTax(res);
      setClient(res.client);
    });
  }, [id]);

  useEffect(() => {
    getDocsTaxes(client?.id).then((res) => setDocs(res));
    getAdditionalDocsTaxes(client?.id).then((res) => setAdditionalDocs(res));
  }, [client?.id]);

  useEffect(() => {
    if (!editModal) return;

    const { type, payed, status, comision, brut, payedGermany, payedTax } =
      editModal;

    setDetailsEditData({
      type,
      payed,
      status,
      comision,
      brut,
      payedGermany,
      payedTax,
    });
  }, [editModal]);

  const handleChangeEdit = (e) => {
    setDetailsEditData({
      ...detailsEditData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmitEditDetails = () => {
    const newEditData = { ...detailsEditData };
    newEditData.payed = parseInt(newEditData.payed);
    newEditData.payedGermany = JSON.parse(newEditData.payedGermany);
    newEditData.payedTax = JSON.parse(newEditData.payedTax);
    newEditData.clientId = client.id;

    editTaxesDetails(id, newEditData, () => {
      getTaxesById(id).then((res) => {
        setTax(res);
        setEditModal(false);
        toast.success("Editarea a fost facuta cu succes");
      });
    });
  };

  const statuses = [
    {
      id: 1,
      name: "Inscris",
    },
    {
      id: 2,
      name: "Fara alocatie",
    },
    {
      id: 3,
      name: "Verificare/semnare",
    },
    {
      id: 4,
      name: "Completare dosar",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Tax return registration")}
        </Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Clients")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Tax Return")}</div>
          </Link>
        </Breadcrumbs>
      </div>
      <div className={styles.parent}>
        <div className={styles.wholeContainer}>
          <div className={styles.leftSide}>
            <div className={styles.container1}>
              <DetailsComponet
                title={t("Client details")}
                tableData={client}
                render={renderClient}
                showClient
                onShowClient={() =>
                  navigate(`/clients/visualize-clients/${client.id}`)
                }
              />
            </div>
            <div className={styles.container2}>
              <DocumentDetails
                docTypes={[]}
                getDocs={() => getDocsTaxes(client.id)}
                deleteDoc={deleteDocTaxes}
                docs={docs}
                setDocs={setDocs}
                fileUrl="/tax/file/"
                setDocumentModal={setDocumentModal}
              />
              <DocumentModal
                open={documentModal}
                setOpen={setDocumentModal}
                handleSubmit={(formData) =>
                  uploadDocTaxes(client.id, id, formData, () => {
                    getDocsTaxes(client.id).then((docs) => {
                      setDocs(docs);
                      setDocumentModal(false);
                      toast.success("File has been uploaded!");
                    });
                  })
                }
              />
            </div>
            <div className={styles.container3}>
              <DocumentDetails
                title={t("Additional documents")}
                docTypes={[]}
                getDocs={() => getAdditionalDocsTaxes(client.id)}
                deleteDoc={deleteAdditionalDocTaxes}
                docs={additionalDocs}
                setDocs={setAdditionalDocs}
                fileUrl="/tax/additionalFile/"
                setDocumentModal={setAdditionalDocsModal}
              />
              <DocumentModal
                open={additionalDocsModal}
                setOpen={setAdditionalDocsModal}
                handleSubmit={(formData) =>
                  uploadAddtionalDocTaxes(client.id, id, formData, () => {
                    getAdditionalDocsTaxes(client.id).then((docs) => {
                      setAdditionalDocs(docs);
                      setAdditionalDocsModal(false);
                      toast.success("File has been uploaded!");
                    });
                  })
                }
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <DetailsComponet
              title="Detalii"
              tableData={tax}
              render={renderDetails}
              showEdit
              onEdit={() => setEditModal(tax)}
            />
            <CustomDialog
              open={editModal}
              handleClose={() => setEditModal(false)}
              button1={t("Edit")}
              button2={t("Quit")}
              title={t("Edit")}
              onClickButton1={() => handleSubmitEditDetails()}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "white",
                  borderRadius: "1.5rem",
                  height: "100%",
                  width: "22rem",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      fullWidth
                      select
                      label="Tip"
                      name="type"
                      value={detailsEditData?.type}
                      onChange={handleChangeEdit}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                    >
                      <MenuItem value="alocatie">Alocație</MenuItem>
                      <MenuItem value="impozit">Impozit</MenuItem>
                      <MenuItem value="alocatie+impozit">
                        Alocație + Impozit
                      </MenuItem>
                    </TextField>

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="payed"
                      value={detailsEditData.payed}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label={t("Paid")}
                    />

                    <TextField
                      fullWidth
                      select
                      label={t("Paid in Germany")}
                      name="payedGermany"
                      value={detailsEditData?.payedGermany}
                      onChange={handleChangeEdit}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                    >
                      <MenuItem value="true">DA</MenuItem>
                      <MenuItem value="false">NU</MenuItem>
                    </TextField>

                    <TextField
                      fullWidth
                      select
                      label={t("Paid taxes")}
                      name="payedTax"
                      value={detailsEditData?.payedTax}
                      onChange={handleChangeEdit}
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                    >
                      <MenuItem value="true">DA</MenuItem>
                      <MenuItem value="false">NU</MenuItem>
                    </TextField>

                    <TextField
                      select
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      required
                      id="outlined-required"
                      label="Status"
                      value={detailsEditData?.status}
                      name="status"
                      onChange={handleChangeEdit}
                    >
                      {statuses.map((status) => (
                        <MenuItem key={status.id} value={status.name}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="comision"
                      value={detailsEditData.comision}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label={t("Comision")}
                    />

                    <TextField
                      fullWidth
                      sx={{
                        fieldset: {
                          borderRadius: "1.5rem",
                        },
                      }}
                      autoComplete={"off"}
                      required
                      name="brut"
                      value={detailsEditData.brut}
                      onChange={handleChangeEdit}
                      id="outlined-required"
                      label={t("Gross cash")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CustomDialog>
          </div>
        </div>
        <div className={styles.comments}>
          <Comments type="TAX" id={id} />
        </div>
      </div>
    </div>
  );
}

export default RegisteringTaxReturn;
