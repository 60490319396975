import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteIcon from "@mui/icons-material/Delete";

import { getDOB } from "utils/functions";

import {
  Typography,
  Box,
  Grid,
  TextField,
  Chip,
  MenuItem,
} from "@mui/material";

import {
  DetailsComponet,
  CustomDialog,
  DetailsWrapper,
  DocumentModal,
  CustomTable,
} from "lib";

import dayjs from "dayjs";

import {
  getClient,
  getTags,
  getTagsOnClient,
  addTagOnClient,
  deleteTagOnClient,
  getClientDocs,
  uploadClientDoc,
  getAllFields,
  blockClient,
  unblockClient,
  editClient,
  getAdditionalInfo,
  getClientDefaultDocumentTypes,
  getFieldsOnClient,
  deleteClientDoc,
  deleteFieldOnClient,
  getCountries,
  getStates,
  getCities,
  getPaymentsForClient,
} from "api/API";
import { toast } from "react-toastify";
import AdditionalInfoModal from "lib/modals/AdditionalInfoModal";
import EditAdditionalInfoModal from "lib/modals/EditAdditionalInfoModal";
import DocumentDetails from "lib/components/DocumentDetails/DocumentDetails";
import FieldsInfoModal from "lib/modals/FieldsInfoModal";

function Details() {
  let { id } = useParams();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [fields, setFields] = useState([]);
  const [client, setClient] = useState(null);
  const [docs, setDocs] = useState([]);
  const [addInfo, setAddInfo] = useState([]);
  const [clientTags, setClientTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [specs, setSpecs] = useState([]);
  const [docTypes, setDocTypes] = useState([]);

  const [tagModal, setTagModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [clientEditData, setClientEditData] = useState({});
  const [additionalInfoModal, setAdditionalInfoModal] = useState(false);
  const [editAdditionalInfoModal, setEditAdditionalInfoModal] = useState(false);

  const [addFieldsModal, setAddFieldsModal] = useState(false);
  const [addFieldInfo, setAddFieldInfo] = useState([]);

  const [editFieldsModal, setEditFieldsModal] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [clientPayments, setClientPayments] = useState([]);

  /**
   * Initial data fetch
   */

  useEffect(() => {
    getAllFields().then((fields) => setFields(fields));
    getClient(id).then((client) => {
      setClient(client.client);
      setSpecs(client.specialization);
    });
    getTagsOnClient(id).then((tags) => setClientTags(tags));
    getTags().then((tags) => setTags(tags));
    getClientDocs(id).then((docs) => setDocs(docs.length > 0 ? docs : []));
    getAdditionalInfo(id).then((addInfo) => setAddInfo(addInfo));
    getClientDefaultDocumentTypes().then((docTypes) => setDocTypes(docTypes));
    getFieldsOnClient(id).then((fieldsInfo) => setAddFieldInfo(fieldsInfo));
    getCountries().then((countries) => setCountries(countries));
  }, [id]);

  useEffect(() => {
    if (client !== null) {
      getStates(client.countryId).then((states) => setStates(states));
      getCities(client.countryId, client.stateId).then((cities) =>
        setCities(cities)
      );
    }
  }, [client, id]);

  const unusedTags = useMemo(
    () =>
      tags.filter(
        (tag) => clientTags.findIndex((ctag) => ctag.id === tag.id) === -1
      ),
    [tags, clientTags]
  );

  /**
   * Client render function
   */
  const renderClient = [
    {
      key: "firstName",
      label: t("Surname"),
    },
    {
      key: "lastName",
      label: t("Name"),
    },
    {
      key: "birthday",
      label: t("Birthday"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">
            {dayjs(displayData).format("DD-MM-YYYY")}
          </Typography>
        );
      },
    },

    {
      key: "phone",
      label: t("Phone Number"),
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "CNP",
      label: "CNP",
    },
    {
      key: "series",
      label: t("Series"),
    },
    {
      key: "ICNumber",
      label: t("Number"),
    },
    {
      key: "Adress",
      label: t("Address"),
    },
    {
      key: "birthPlace",
      label: t("Birth Place"),
    },
    {
      key: "country",
      label: t("Country"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">{t(displayData?.name)}</Typography>
        );
      },
    },
    {
      key: "states",
      label: t("State"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">{t(displayData?.name)}</Typography>
        );
      },
    },
    {
      key: "city",
      label: t("City"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">{t(displayData?.name)}</Typography>
        );
      },
    },
    {
      key: "nationality",
      label: t("Nationality"),
    },
    {
      key: "contactPerson",
      label: t("Contact Person"),
    },
    {
      key: "Gender",
      label: t("Gender"),
      cellModifier: (displayData) => {
        return <Typography variant="detailsText">{t(displayData)}</Typography>;
      },
    },
    {
      key: "CIP",
      label: "CIP",
    },
    {
      key: "ciExpireDate",
      label: t("Expiration date CI"),
      cellModifier: (date) => (
        <Typography variant="detailsText">
          {dayjs(date).format("DD-MM-YYYY")}
        </Typography>
      ),
    },
    {
      key: "fieldId",
      label: t("Field"),
      cellModifier: (fieldId) => (
        <Typography variant="detailsText">
          {fields.filter((f) => f.id === fieldId)?.[0]?.name}
        </Typography>
      ),
    },
  ];

  /**
   * Additional data & render
   */
  const additionalInfoData = addInfo.reduce(
    (tableData, info) => ({
      ...tableData,
      [info.title]: info.content,
    }),
    {}
  );

  const additionalInfoRender = addInfo.reduce(
    (renderInfo, info) => [
      ...renderInfo,
      {
        key: info.title,
        label: info.title,
      },
    ],
    []
  );

  /**
   * 1. Populate data in modal when opening it
   * 2. Data comes from client state
   */
  useEffect(() => {
    if (!clientModal) return;

    const {
      firstName,
      lastName,
      phone,
      email,
      CNP,
      birthday,
      Adress,
      fieldId,
      Gender,
      CIP,
      series,
      ICNumber,
      nationality,
      contactPerson,
      ciExpireDate,
      countryId,
      stateId,
      cityId,
      birthPlace,
    } = clientModal;

    setClientEditData({
      firstName,
      lastName,
      phone,
      email,
      CNP,
      birthday,
      Adress,
      fieldId,
      Gender,
      CIP,
      series,
      ICNumber,
      nationality,
      contactPerson,
      ciExpireDate,
      countryId,
      stateId,
      cityId,
      birthPlace,
    });
  }, [clientModal]);

  // functions for city and town

  const getAndSetStates = async (countryId) => {
    getStates(countryId).then((states) => setStates(states));
  };

  const getAndSetCities = async (countryId, stateId) => {
    getCities(countryId, stateId).then((cities) => setCities(cities));
  };

  /**
   * Function to change client data on edit
   */
  const handleChange = async (e) => {
    if (e.target.name === "CNP") {
      setClientEditData({
        ...clientEditData,
        [e.target.name]: e.target.value,
        ["birthday"]: getDOB(e.target.value),
      });
    } else {
      setClientEditData({
        ...clientEditData,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "countryId") await getAndSetStates(e.target.value);
    if (e.target.name === "stateId")
      await getAndSetCities(clientEditData.countryId, e.target.value);
  };

  const genders = [
    {
      id: 1,
      name: t("Male"),
      value: "MALE",
    },
    {
      id: 2,
      name: t("Female"),
      value: "FEMALE",
    },
    {
      id: 3,
      name: t("Neutral"),
      value: "Neutral",
    },
  ];

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    Boolean(client) && (
      <div>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
            width: "100%",
            maxWidth: "1080px",
            alignItems: "start",
            "> *": {
              width: "45%",
              maxWidth: "30rem",
            },
          }}
        >
          {/* Client Details */}
          <DetailsComponet
            title={t("Client details")}
            showEdit
            onEdit={() => setClientModal(client)}
            showBlock={!client.isBlocked}
            onBlock={() =>
              confirm(
                `${t("Are you sure you want to block client")} ${
                  client.firstName
                }?`,
                () =>
                  blockClient(client.id, () => {
                    setClient({
                      ...client,
                      isBlocked: true,
                    });
                    toast.info(t("Client has been blocked!"));
                  })
              )
            }
            showUnblock={client.isBlocked}
            onUnblock={() =>
              confirm(
                `${t("Are you sure you want to unblock client")} ${
                  client.firstName
                }?`,
                () =>
                  unblockClient(client.id, () => {
                    setClient({ ...client, isBlocked: false });
                    toast.info(t("Client has been unblocked!"));
                  })
              )
            }
            tableData={client}
            render={renderClient}
          />

          {/* Client Docs */}
          <DocumentDetails
            entityId={id}
            docTypes={docTypes}
            docs={docs}
            getDocs={() => getClientDocs(id)}
            deleteDoc={deleteClientDoc}
            setDocs={setDocs}
            clientData={client}
            fileUrl="/clients/file/"
            setDocumentModal={setDocumentModal}
          />

          {/* Client Tags */}
          <DetailsWrapper
            title={t("Tags")}
            showAdd
            onAdd={() => {
              setTagModal(true);
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                overflow: "hidden",
                gap: "5px",
              }}
            >
              {clientTags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  onDelete={() => {
                    deleteTagOnClient(client.id, tag.id, () => {
                      getTagsOnClient(id).then((tags) => setClientTags(tags));
                    });
                  }}
                  sx={{
                    background: tag.type,
                  }}
                />
              ))}

              {!clientTags.length && (
                <Typography variant="detailsText">
                  {t("This client has no tags.")}
                </Typography>
              )}
            </Box>
          </DetailsWrapper>

          {/* Client Specialization */}

          {/* Client Additional Info */}
          <DetailsComponet
            title={t("Additional information")}
            showAdd
            onAdd={() => setAdditionalInfoModal(true)}
            showEdit={addInfo.length > 0}
            onEdit={() => setEditAdditionalInfoModal(true)}
            tableData={additionalInfoData}
            render={additionalInfoRender}
          />

          {/* Specialization */}
          <DetailsComponet
            title={t("Specialization")}
            tableData={specs.reduce(
              (data, spec) =>
                Boolean(spec.field)
                  ? {
                      ...data,
                      [spec.field.name]: spec.count,
                    }
                  : data,
              {}
            )}
            render={specs.reduce(
              (render, spec) =>
                Boolean(spec.field)
                  ? [
                      ...render,
                      {
                        key: spec.field.name,
                        label: spec.field.name,
                      },
                    ]
                  : render,
              []
            )}
          ></DetailsComponet>

          <DetailsComponet
            title={t("Fields")}
            showAdd
            onAdd={() => setAddFieldsModal(true)}
            tableData={addFieldInfo.reduce(
              (data, field) => ({
                ...data,
                [field.field.name]: field.field.id,
              }),
              {}
            )}
            render={addFieldInfo.reduce(
              (render, field) => [
                ...render,
                {
                  key: field.field.name,
                  label: field.field.name,
                  cellModifier: (fieldId) => (
                    <DeleteIcon
                      color="errorCustom"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        confirm("Are you sure you want to delete this?", () => {
                          deleteFieldOnClient(id, fieldId, () => {
                            getFieldsOnClient(id).then((fieldsInfo) => {
                              setAddFieldInfo(fieldsInfo);
                              setEditFieldsModal(false);
                              toast.success(
                                "Cultura a fost stearsa cu succes!"
                              );
                            });
                          });
                        })
                      }
                    />
                  ),
                },
              ],
              []
            )}
          ></DetailsComponet>
        </Box>

        {/* Tag Modal */}
        <CustomDialog
          title={t("Manage tags")}
          open={Boolean(tagModal)}
          handleClose={() => setTagModal(false)}
          button2="Quit"
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "1.5rem",
              height: "100%",
              width: "22rem",
            }}
          >
            <Grid container justifyContent={"space-between"} spacing={2}>
              <Grid item xs={12}>
                {/* Tags on client */}
                <Typography variant="detailsText">
                  {t("Client Tags")}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 5,
                    marginTop: "1rem",
                  }}
                >
                  {clientTags.map((tag) => (
                    <Chip
                      key={tag.id}
                      style={{ background: tag.type }}
                      label={String(tag.name)}
                      onClick={() => addTagOnClient(client.id, tag.id)}
                      onDelete={() => {
                        deleteTagOnClient(client.id, tag.id, () => {
                          getTagsOnClient(id).then((tags) =>
                            setClientTags(tags)
                          );
                        });
                      }}
                    />
                  ))}
                </div>

                {unusedTags.length > 0 && (
                  <>
                    <br />
                    <br />

                    {/* All tags */}
                    <Typography variant="detailsText">
                      {t("Add new tag")}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 5,
                        marginTop: "1rem",
                      }}
                    >
                      {unusedTags.map((tag) => (
                        <Chip
                          key={tag.id}
                          style={{ background: tag.type }}
                          label={String(tag.name)}
                          onClick={() =>
                            addTagOnClient(client.id, tag.id, () => {
                              getTagsOnClient(id).then((tags) =>
                                setClientTags(tags)
                              );
                            })
                          }
                        />
                      ))}
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>{" "}
        </CustomDialog>

        {/* Document Modal */}
        <DocumentModal
          open={documentModal}
          setOpen={setDocumentModal}
          handleSubmit={(formData) =>
            uploadClientDoc(client.id, formData, () => {
              getClientDocs(client.id).then((docs) => {
                setDocs(docs);
                setDocumentModal(false);
                toast.success(t("File has been uploaded!"));
              });
            })
          }
        />

        {/* Edit Client Modal */}
        <CustomDialog
          open={clientModal !== false}
          handleClose={() => setClientModal(false)}
          button1="Editează"
          onClickButton1={() =>
            editClient(client.id, clientEditData, (client) => {
              setClient(client);
              setClientModal(false);
              toast.success(t("Client succesfully edited"));
            })
          }
          button2="Renunță"
          title="Editează Client"
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "1.5rem",
              height: "100%",
              width: "22rem",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="lastName"
                  value={clientEditData.lastName}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("First name")}
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="firstName"
                  value={clientEditData.firstName}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Last name")}
                />

                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="phone"
                  value={clientEditData.phone}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Phone Number")}
                />

                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="email"
                  value={clientEditData.email}
                  onChange={handleChange}
                  id="outlined-required"
                  label="Email"
                />

                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="CNP"
                  value={clientEditData.CNP}
                  onChange={handleChange}
                  id="outlined-required"
                  label="CNP"
                  inputProps={{ maxLength: 13 }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Zi de naștere"
                    value={getDOB(clientEditData.CNP)}
                    // onChange={(value) =>
                    //   handleChange({ target: { name: "birthday", value } })
                    // }
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onKeyDown={onKeyDown}
                        disabled={true}
                        InputProps={{
                          readOnly: true,
                        }}
                        id="outlined-required"
                        autoComplete={"off"}
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="Adress"
                  value={clientEditData.Adress}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Address")}
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="birthPlace"
                  value={clientEditData.birthPlace}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Birth Place")}
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="series"
                  value={clientEditData.series}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Series")}
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="ICNumber"
                  value={clientEditData.ICNumber}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Number")}
                />

                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  value={clientEditData.CIP}
                  id="outlined-required"
                  label="CIP"
                  name="CIP"
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="contactPerson"
                  value={clientEditData.contactPerson}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Contact Person")}
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="nationality"
                  value={clientEditData.nationality}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Nationality")}
                />
                <TextField
                  select
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="Gender"
                  value={clientEditData.Gender}
                  onChange={handleChange}
                  id="outlined-required"
                  label={t("Gender")}
                >
                  {genders.map((gender) => (
                    <MenuItem key={gender.id} value={gender.value}>
                      {gender.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  required
                  select
                  label={t("Country")}
                  value={clientEditData.countryId}
                  onChange={handleChange}
                  name="countryId"
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                >
                  {countries.map((country) => (
                    <MenuItem key={country.id} value={country.id}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  required
                  select
                  label={t("State")}
                  value={clientEditData.stateId}
                  onChange={handleChange}
                  name="stateId"
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                >
                  {states.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  required
                  select
                  label={t("Cities")}
                  value={clientEditData.cityId}
                  onChange={handleChange}
                  name="cityId"
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                >
                  {cities.map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))}
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={t("Expiration date CI")}
                    name="ciExpireDate"
                    value={clientEditData.ciExpireDate}
                    onChange={(value) => {
                      handleChange({ target: { name: "ciExpireDate", value } });
                    }}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                          width: "16rem",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>

        <AdditionalInfoModal
          clientId={client.id}
          open={additionalInfoModal}
          setOpen={setAdditionalInfoModal}
          addCallback={(additionalInfo) => {
            setAdditionalInfoModal(false);
            setAddInfo([...addInfo, additionalInfo]);
            toast.success(
              `Informația ${additionalInfo.title} a fost adaugată!`
            );
          }}
        />

        <EditAdditionalInfoModal
          open={editAdditionalInfoModal}
          setOpen={setEditAdditionalInfoModal}
          info={addInfo}
          clientId={client.id}
          editCallback={async () => {
            setEditAdditionalInfoModal(false);
            try {
              const newAddInfo = await getAdditionalInfo(client.id);
              setAddInfo(newAddInfo);
              toast.success(`Informațiile adiționale au fost modificate!`);
            } catch (error) {
              console.error(error);
            }
          }}
          deleteCallback={(additionalInfoId) => {
            setEditAdditionalInfoModal(false);
            setAddInfo(addInfo.filter((i) => i.id !== additionalInfoId));
            toast.success("Informația a fost ștearsă!");
          }}
        />

        <FieldsInfoModal
          open={addFieldsModal}
          setOpen={setAddFieldsModal}
          clientId={id}
          addCallback={() =>
            getFieldsOnClient(id).then((fieldsInfo) => {
              setAddFieldInfo(fieldsInfo);
              setAddFieldsModal(false);
              toast.success(`Cultura a fost adaugata!`);
            })
          }
        />
      </div>
    )
  );
}

export default Details;
