import React from 'react'
import CustomDialog from 'lib/components/Modals/CustomModal'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { useUser } from 'hooks/useUser'
import { useNavigate } from 'react-router-dom'

const ConfirmLogoutModal = ({ open, setOpen }) => {
    const { t } = useTranslation()
    const { setAccessToken } = useUser()
    const navigate = useNavigate()

    return (
        <CustomDialog
            handleClose={() => setOpen(false)}
            open={Boolean(open)}
            title={t('Logout')}
            button1={t('YES')}
            button2={t('NO')}
            onClickButton1={() => {
                setAccessToken(undefined)
                navigate('/login')
                setOpen(false)
            }}
        >
            <div
                style={{
                    width: '350px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant='tableContent'>
                    {t('Are you sure you want to log out?')}
                </Typography>
            </div>
        </CustomDialog>
    )
}

export default ConfirmLogoutModal
