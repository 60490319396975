import React, { useCallback } from "react";
import { useEffect, useState, useRef } from "react";
import {
  Box,
  Chip,
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import CustomDialog from "lib/components/Modals/CustomModal";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { useTranslation } from "react-i18next";
import styles from "./AddClientToTrip.module.scss";
import { addClientOnTrip, updateReturn } from "api/tripApi";

import { getAllFields } from "api/fieldApi";

import FilterFieldsModal from "lib/modals/FilterFieldsModal";
import FilterPeriodsModal from "lib/modals/FilterPeriodsModal";
import SearchIcon from "@mui/icons-material/Search";

import dayjs from "dayjs";

const AddClientToTrip = ({
  open = false,
  setOpen = () => {},
  clientsWithoutRoute = [],
  routeId,
  addCallback = () => {},
  fields = [],
  trip = [],
  clientsArrived = [],
  clientsForTrip = [],
  setSearchedClient = () => {},
  keyDownHandlerSearch = () => {},
  loading,
  hasMoreClients,
  pageNumber,
  setPageNumber,
  isSearch,
  setIsSearch,
  setClientsWithoutRoute,
  getClientsForAdd = () => {},
}) => {
  const { t } = useTranslation();
  const [clientListTur, setClientListTur] = useState([]); // TUR -> list of {}
  const [clientListRetur, setClientListRetur] = useState([]); // RETUR -> list of ids
  const [fieldFilters, setFieldFilters] = useState([]);
  const [fieldForClient, setFieldForClient] = useState("");

  const [fieldFilterModal, setFieldFilterModal] = useState(false);
  const [periodsFilterModal, setPeriodsFilterModal] = useState(false);

  const [allDepartureFields, setAllDepartureFields] = useState([]);

  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedPeriods, setSelectedPeriods] = useState(null);

  const [taxes, setTaxes] = [{ id: null, liquidated: null, eligibleTax: null }];

  const [finalDate, setFinalDate] = useState("");

  const observer = useRef();

  const lastClientElementRef = useCallback(
    (node) => {
      if (loading || isSearch) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreClients) {
          setPageNumber((prev) => prev + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMoreClients, isSearch]
  );

  // TUR only
  const clientClickHandleTur = ({
    clientId,
    fieldId = null,
    // finalDate = null,
  }) => {
    if (!clientId) return;

    const clientIndex = clientListTur.findIndex((c) => c.clientId === clientId);

    // Client is not yet selected, so we add it with info provided (either fieldId or period must be null)
    if (clientIndex < 0) {
      setClientListTur([
        ...clientListTur,
        {
          clientId,
          fieldId,
        },
      ]);
      return;
    }

    // We have this client selected already
    const client = clientListTur[clientIndex];
    if (fieldId === null) {
      client.finalDate = finalDate;
    } else {
      client.fieldId = fieldId;
    }

    const newClientListTur = [...clientListTur];
    newClientListTur[clientIndex] = client;

    setClientListTur(newClientListTur);
  };

  const handleLiquidatedField = (id, liquidated) => {
    const clientReturIndex = clientListRetur.findIndex((c) => c.id === id);

    if (clientReturIndex < 0) {
      setClientListRetur([...clientListRetur, { id, liquidated }]);
    } else {
      const shallow = [...clientListRetur];
      shallow[clientReturIndex].liquidated = liquidated;
      setClientListRetur(shallow);
    }
  };

  useEffect(() => {
    getAllFields().then((res) => setAllDepartureFields(res));
  }, []);

  useEffect(() => {
    if (!open) return;

    setClientListTur([]);
  }, [open]);

  return (
    <CustomDialog
      // style={{
      //   "& .MuiPaper-root": {
      //     maxWidth: "95vw",
      //   },
      // }}
      open={open}
      handleClose={() => setOpen(false)}
      title={
        clientListTur.length > 0
          ? `${clientListTur.length} selected`
          : t("Add client")
      }
      button1={t("Add")}
      button2={t("Quit")}
      isAddingOnTrip={true}
      onClickButton1={() => {
        // Add func
        trip.type === "RETUR"
          ? updateReturn(trip.id, clientListRetur, addCallback)
          : addClientOnTrip(clientListTur, routeId, addCallback);
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          // width: "100rem",
        }}
      >
        <Grid container sx={{ width: 1000 }}>
          {/* Aici modific latimea modalului, sau o lasi sa se ia automat :* */}
          <div className={styles.clientContainer}>
            {clientsWithoutRoute?.length > 0 && trip.type === "TUR" && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    startIcon={<FilterAltIcon />}
                    style={{
                      borderRadius: "1rem",
                    }}
                    onClick={() => setFieldFilterModal(true)}
                  >
                    {t("Filtering by fields")}
                  </Button>
                  <FilterFieldsModal
                    open={fieldFilterModal}
                    setOpen={setFieldFilterModal}
                    fields={allDepartureFields}
                    selectedFields={selectedFields}
                    setSelectedFields={setSelectedFields}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    startIcon={<FilterAltIcon />}
                    style={{
                      borderRadius: "1rem",
                    }}
                    onClick={() => setPeriodsFilterModal(true)}
                  >
                    {t("Filtering by periods")}
                  </Button>
                  <FilterPeriodsModal
                    open={periodsFilterModal}
                    setOpen={setPeriodsFilterModal}
                    selectedPeriods={selectedPeriods}
                    setSelectedPeriods={setSelectedPeriods}
                  />
                </div>
              </div>
            )}

            {/* search bar */}
            <div>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "0.5rem",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                autoComplete={"off"}
                name="contactPerson"
                // value={searchFields}
                // onChange={(e) => setSearchFields(e.target.value)}
                id="outlined-required"
                label={t("Find client")}
                onKeyDown={keyDownHandlerSearch}
                onChange={(e) => setSearchedClient(e.target.value)}
              />
            </div>

            {!clientsWithoutRoute.length && (
              <Typography sx={{ textAlign: "center" }}>
                Nu sunt clienți înscriși pe deplasări!
              </Typography>
            )}

            {clientsWithoutRoute
              .filter((client) => {
                // 1. Filtrare dupa fields
                if (selectedFields.length !== 0) {
                  for (const fieldId of selectedFields) {
                    if (
                      client.DepartureFields?.findIndex(
                        (f) => f.field.id === fieldId
                      ) === -1
                    ) {
                      return false;
                    }
                  }
                }

                // 2. Filtrare dupa perioada
                if (selectedPeriods !== null) {
                  const filterDate = dayjs(selectedPeriods).format("M YYYY");
                  let cd = dayjs(client.dates[0]).format("M YYYY");

                  if (
                    client.dates?.findIndex(
                      (d) => dayjs(d).format("M YYYY") === filterDate
                    ) === -1
                  )
                    return false;
                }

                return true;
              })
              .map((client, index) => {
                const clientIndex = clientListTur.findIndex(
                  (c) => c.clientId === client.id
                );

                let clientSelected = {};

                if (trip.type === "TUR") {
                  clientSelected =
                    clientIndex > -1 ? clientListTur[clientIndex] : null;
                } else if (trip.type === "RETUR") {
                  clientSelected = clientListRetur.indexOf(client.id) >= 0;
                }

                return (
                  <div
                    ref={
                      clientsWithoutRoute.length === index + 1
                        ? lastClientElementRef
                        : null
                    }
                    key={client.id}
                    className={`${styles.client} ${
                      clientSelected ? styles.selected : ""
                    }`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border:
                        client.client.isExpired === false
                          ? "1px solid #f4f4f4"
                          : "3px solid red",
                      // flexDirection: "column",
                    }}
                    onClick={() =>
                      // trip.type === "RETUR"
                      //   ? clientClickHandleRetur(
                      //       client.id,
                      //       client.liquidated,
                      //       client.eligibleTax
                      //     )
                      //   : {}
                      null
                    }
                  >
                    <Typography
                      component={"div"}
                      style={{
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      {client.client.firstName} {client.client.lastName}
                    </Typography>

                    {client.client.TagOnClient?.length > 0 && (
                      <div className={styles.tagContainer}>
                        {client.client?.TagOnClient?.map((tag) => (
                          <Chip
                            key={tag.tag.id}
                            label={tag.tag.name}
                            sx={{ backgroundColor: tag.tag.type }}
                          />
                        ))}
                      </div>
                    )}

                    <div
                      style={{
                        width: "80%",
                        flexShrink: 0,
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      {trip.type === "TUR" && (
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <Typography component={"div"}>
                            <TextField
                              select
                              fullWidth
                              label="Culturi"
                              value={
                                clientSelected
                                  ? clientSelected?.fieldId
                                  : client?.DepartureFields?.fieldId
                              }
                              onChange={(e) =>
                                clientClickHandleTur({
                                  clientId: client.id,
                                  fieldId: e.target.value,
                                })
                              }
                              sx={{
                                fieldset: {
                                  borderRadius: "1.5rem",
                                },
                                marginTop: "5px",
                              }}
                            >
                              {client.DepartureFields?.map((f) => (
                                <MenuItem key={f.fieldId} value={f.fieldId}>
                                  {f.field.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Typography>
                        </div>
                      )}

                      {trip.type === "TUR" && (
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          {client.dates?.length > 0 &&
                            client.dates?.map((opt, index) => (
                              <Chip
                                key={index}
                                sx={{
                                  color: "#FFFFFF",
                                  backgroundColor: "#7BC67E",
                                  marginTop: "10px",
                                  marginRight: "5px",
                                  border: "1px solid",
                                }}
                                label={dayjs(opt).format("MM/YYYY")}
                              />
                            ))}
                        </div>
                      )}

                      {trip.type === "RETUR" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "1rem",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              width: "100% ",
                              border: "1px solid #F4F5F7",
                              borderRadius: "1rem",
                              padding: "14px",
                              boxShadow:
                                "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                            }}
                          >
                            <Typography>
                              {"Data finala" +
                                ": " +
                                dayjs(client.finalDate).format("DD-MM-YYYY")}
                            </Typography>
                          </div>
                          <TextField
                            fullWidth
                            label="Lichidare"
                            name="liquidated"
                            value={clientSelected.liquidated}
                            sx={{ fieldset: { borderRadius: "1rem" } }}
                            onChange={(e) =>
                              handleLiquidatedField(client.id, e.target.value)
                            }
                          ></TextField>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            {loading && (
              <Typography
                style={{ textAlign: "center" }}
                variant="firstPageTitle"
              >
                Se incarca...
              </Typography>
            )}
          </div>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AddClientToTrip;
