import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "hooks/useWindowDimensions";
import styles from "./Sidebar.module.scss";
import TehnicalSupport from "lib/modals/TehnicalSupport";

// mui
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Container,
  Stack,
  Collapse,
  Avatar,
} from "@mui/material";

import LogoDevIcon from "@mui/icons-material/LogoDev";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "../../theme";
import UserSVG from "resources/avatars/avatar-01.svg";
import ChatIcon from "@mui/icons-material/Chat";
import { TechnicalSupportButton } from "lib/components/Buttons/buttons";

function Sidebar({ bgcolor, tier, navMain }) {
  const { t } = useTranslation();
  const history = useNavigate();
  const { vw } = useWindowDimensions();
  const [supportOpen, setSupportOpen] = useState(false);
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            backgroundColor: bgcolor,
            padding: 0,
            height: "100%",
            overflowY: "auto",
            paddingTop: vw >= 1080 ? "0" : "5rem",
            paddingBottom: vw >= 1080 ? "0" : "5rem",
          }}
          className={styles.sideBarScroll}
        >
          {/* name, tier */}
          <List sx={{ pt: "0", pb: "1rem" }}>
            {navMain.map(
              (navGroup, idx) =>
                navGroup.render && (
                  <nav
                    aria-label="Aria Label Text"
                    key={idx}
                    className={styles.navBar}
                  >
                    <Divider
                      className="sidebar-divider"
                      textAlign="left"
                      sx={{
                        color: "#DCE4E9",
                        textDecoration: "none",
                        marginBottom: "0.5rem",

                        "&.MuiDivider-root": {
                          color: "#DCE4E9",

                          "&::before": {
                            width: "30%",
                            borderTopWidth: "2px",
                          },
                          "&::after": {
                            width: "70%",
                            borderTopWidth: "2px",
                          },
                        },
                      }}
                    >
                      {navGroup.label}
                    </Divider>
                    {navGroup.navItems.map(
                      (item, idx) =>
                        item.render && (
                          <div key={idx}>
                            <SidebarItem
                              content={item.content}
                              icon={item.icon}
                              path={item.path}
                              render={item.render}
                              nested={item.nested}
                              nestedItems={item.nestedItems}
                              withNoIconColor={item.withNoIconColor}
                              onClick={item.onClick}
                            />
                          </div>
                        )
                    )}
                  </nav>
                )
            )}

            <TechnicalSupportButton
              startIcon={<ChatIcon />}
              onClick={() => setSupportOpen(true)}
            >
              {t("Technical support")}
            </TechnicalSupportButton>

            <Typography
              onClick={() => history("/versions")}
              style={{
                fontFamily: ["Montserrat", "sans-serif"].join(","),
                lineHeight: "157%",
                fontStyle: "normal",
                textAlign: "center",
                marginTop: "15px",
                color: "#DCE4E9",
                fontWeight: 600,
                fontSize: "12px",
                letterSpacing: "0.1px",
                cursor: "pointer",
              }}
            >
              RaisisCRM v 1.2.0
            </Typography>
          </List>
        </Box>
      </ThemeProvider>
      <TehnicalSupport open={supportOpen} setOpen={setSupportOpen} />
    </>
  );
}

function SidebarItem({
  nested,
  icon,
  content,
  nestedItems,
  path,
  withNoIconColor,
  onClick,
}) {
  let navigate = useNavigate();
  let location = useLocation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton
        // className={styles.test}
        sx={{
          boxShadow:
            "/" + location.pathname.split("/")[1] === path
              ? "inset 12px 12px 20px #e6e9ed, inset -12px -12px 20px #FAFBFC"
              : null,
          backgroundColor:
            "/" + location.pathname.split("/")[1] === path ? "#EFF1F" : null,
          borderRadius: "25px 0px 0px 25px",

          ml: "25px",
          "&:hover": {
            backgroundColor: "transparent",

            "& .sidebar-menuIcon": {
              "& svg path": {
                fill: withNoIconColor ? null : "#5B80BA",
              },
            },
            "& .sidebar-menuText": {
              color: "#5B80BA",
            },
          },

          "&:active": {
            backgroundColor: "#E2E9F3",
            color: "#5B80BA",
          },
          "& .MuiTouchRipple-child": {
            backgroundColor: "#5B80BA",
          },
        }}
        onClick={() =>
          nested ? setOpen(!open) : onClick ? onClick() : navigate(`${path}`)
        }
      >
        <ListItemIcon
          className="sidebar-menuIcon"
          sx={{
            "& svg path": {
              fill: withNoIconColor
                ? null
                : "/" + location.pathname.split("/")[1] === path
                ? "#5B80BA"
                : "#8E99A7",
            },
            fontSize: "20px",
            minWidth: 0,
            pr: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText>
          <Typography
            className="sidebar-menuText"
            variant="sidebarItem"
            sx={{
              color:
                "/" + location.pathname.split("/")[1] === path
                  ? "#5B80BA"
                  : "#8E99A7",
              fontSize: "13px",
            }}
          >
            {content}
          </Typography>
        </ListItemText>
        {nested && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {nested &&
        nestedItems.map((item, idx) => (
          <div key={idx}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List disablePadding>
                {item.render && (
                  <ListItemButton
                    sx={{
                      ml: 8,
                      borderRadius: "25px 0px 0px 25px",
                      "&:hover": {
                        backgroundColor: "transparent",

                        "& .sidebar-nested-menuText": {
                          color: "#5B80BA",
                        },
                      },
                    }}
                    onClick={path ? () => navigate(item.path) : item.onClick}
                  >
                    <ListItemIcon
                      className="sidebar-nested-MenuIcon"
                      sx={{
                        minWidth: 0,
                        pr: "10px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="sidebarItem"
                        color="textCustom.secondary"
                        className="sidebar-nested-menuText"
                        sx={{
                          color:
                            "/" +
                              location.pathname.split("/")[1] +
                              "/" +
                              location.pathname.split("/")[2] ===
                            item.path
                              ? "#5B80BA"
                              : "#8E99A7",
                          fontSize: "13px",
                        }}
                      >
                        {item.content}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                )}
              </List>
            </Collapse>
          </div>
        ))}
    </>
  );
}

Sidebar.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.string,
  margin: PropTypes.string,
  bgcolor: PropTypes.string,
  user: PropTypes.string,
  tier: PropTypes.string,
  navMain: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.bool,
      label: PropTypes.string,
      navItems: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          icon: PropTypes.node,
          render: PropTypes.bool,
          path: PropTypes.string,
          nested: PropTypes.bool,
          nestedItems: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.string,
              render: PropTypes.bool,
              path: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
};

Sidebar.defaultProps = {
  bgcolor: "#ffffff",
  user: "Raisis Software",
  tier: "Premium",
  navMain: [
    {
      render: true,
      label: "Label 1",
      navItems: [
        {
          content: "Content",
          icon: <LogoDevIcon />,
          render: true,
          path: "/",
          nested: false,
        },
        {
          content: "Content",
          icon: <LogoDevIcon />,
          render: true,
          path: "/test-route-1",
          nested: false,
        },
      ],
    },
    {
      render: true,
      label: "Label",
      navItems: [
        {
          content: "Content",
          icon: <LogoDevIcon />,
          render: true,
          path: "/test-route-2",
          nested: false,
        },
        {
          content: "Content",
          icon: <LogoDevIcon />,
          render: true,
          path: "/test-route-3",
          nested: false,
        },
        {
          content: "Content",
          icon: <LogoDevIcon />,
          render: true,
          nested: true,
          nestedItems: [
            {
              content: "Inner Content",
              render: true,
              path: "/test-route-4",
            },
            {
              content: "Inner Content",
              render: true,
              path: "/test-route-5",
            },
          ],
        },
        {
          content: "Content",
          icon: <LogoDevIcon />,
          render: true,
          nested: true,
          nestedItems: [
            {
              content: "Inner Content",
              render: true,
              path: "test-route-6",
            },
          ],
        },
      ],
    },
    {
      render: true,
      label: "Label",
      navItems: [
        {
          content: "Content",
          icon: <LogoDevIcon />,
          render: true,
          path: "test-route-7",
          nested: false,
        },
      ],
    },
  ],
};

export default Sidebar;
