import React from "react";
import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const Step3Signature = (props) => {
  const {
    imageSrc,
    setStep = () => {},
    setImageSrc = () => {},
    cancelSignatureImage = () => {},
    handlePrev = () => {},
  } = props;

  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6">{t("Step 3: Visualize signature")}</Typography>
      {imageSrc ? (
        <img src={URL.createObjectURL(imageSrc)} alt="signature" />
      ) : (
        <Typography>{t("There is no signature available")}</Typography>
      )}

      <Button
        sx={{
          color: "#ffffff",
          backgroundColor: "#E91E63",
          borderRadius: "1rem",
          "&:hover": {
            backgroundColor: "#E91E63",
          },
        }}
        variant="contained"
        onClick={() => {
          setStep(1);
          setImageSrc(null);
          cancelSignatureImage();
        }}
      >
        {t("Redo Signature")}
      </Button>

      <div style={{ display: "flex", justifyContent: "end", gap: "20px" }}>
        <Button
          style={{
            borderRadius: "99rem",
          }}
          variant="outlined"
          onClick={handlePrev}
        >
          {t("Previous")}
        </Button>{" "}
      </div>
    </>
  );
};

export default Step3Signature;
