import axios from "api/axios";
import { handleError } from "utils/functions";

export const getAllFleets = async (
  perPage = 9999,
  currentPage = 0,
  pagesToLoad = 1
) => {
  try {
    const response = await axios.get("/cars", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addFleet = async (fleetData, callback = () => {}) => {
  try {
    const response = await axios.post("/cars", fleetData);
    callback(response.fleetData);
  } catch (error) {
    handleError(error);
  }
};

export const deleteFleet = async (fleetId, callback = () => {}) => {
  try {
    await axios.delete(`/cars/${fleetId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};
