import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "hooks/useWindowDimensions";

import { getUserById, updateUser } from "api/API";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Grid,
  TextField,
  Button,
  Avatar,
  MenuItem,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import LockIcon from "@mui/icons-material/Lock";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import UserSVG from "../../resources/avatars/avatar-01.svg";
import { toast } from "react-toastify";
import { getRoles } from "api/permissionsAPI";
import { useUser } from "hooks/useUser";

const User = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { vw } = useWindowDimensions();
  const { can } = useUser();
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [editUserData, setEditUserData] = useState({
    name: "",
    email: "",
    roleId: "",
  });
  const canSeeDashboardInfos = can(22);

  useEffect(() => {
    getUserById(id).then((user) => {
      setUser(user);
      setEditUserData({
        name: user.name,
        email: user.email,
        roleId: user.roleId,
      });
    });
    getRoles().then((roles) => setRoles(roles));
  }, [id]);

  const handleChange = (e) => {
    setEditUserData({
      ...editUserData,
      [e.target.name]: e.target.value,
    });
  };

  const submitEditUser = () => {
    if (!user.id) return;

    updateUser(user.id, editUserData).then(() => {
      getUserById(id).then((user) => {
        setUser(user);
        setEditUserData({
          name: user.name,
          email: user.email,
          roleId: user.roleId,
        });
        toast.success("Userul a fost editat!");
      });
    });
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("User")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Nomenclators")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("User")}</div>
          </Link>
        </Breadcrumbs>
      </div>
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            p: 2,
            backgroundColor: "#ffffff",
            marginBottom: "3rem",
            borderRadius: "1rem",
            width: vw >= 1080 ? "30%" : "90%",
          }}
        >
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item>
              <Typography>{user.name}</Typography>
              <Typography style={{ fontWeight: 300 }}>{user.email}</Typography>
            </Grid>
            <Grid item>
              <Avatar
                style={{
                  height: 100,
                  width: 100,
                  transition: "0.3s",
                  cursor: "pointer",
                }}
                src={UserSVG}
              />
            </Grid>
          </Grid>

          <Box sx={{ p: 2 }}>
            <Button
              variant="contained"
              endIcon={<LockIcon />}
              sx={{ backgroundColor: "#E91E63", borderRadius: "1rem" }}
            >
              Blocare
            </Button>

            <Button
              variant="contained"
              sx={{ borderRadius: "1rem", marginLeft: "1rem" }}
              endIcon={<RestartAltIcon />}
            >
              Resetare
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            p: 2,
            borderRadius: "1rem",
            height: "100%",
            backgroundColor: "#ffffff",
            width: vw >= 1080 ? "60%" : "90%",
          }}
        >
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                name="name"
                value={editUserData.name}
                label={t("Name")}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                name="email"
                value={editUserData.email}
                label={t("Email")}
                onChange={handleChange}
              />
            </Grid>
            {canSeeDashboardInfos && (
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  name="roleId"
                  value={editUserData.roleId}
                  label={t("Role")}
                  onChange={handleChange}
                >
                  {roles.map((role) => (
                    <MenuItem value={role.id} key={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={submitEditUser}
                sx={{
                  color: "#FAFAFA",
                  backgroundColor: "#1976d2",
                  borderRadius: "1rem",
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default User;
