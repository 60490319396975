import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TaskModal from "lib/modals/TaskModal";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography, Breadcrumbs, Link, Avatar, Chip } from "@mui/material";
import {
  getAllTasks,
  getAllUsers,
  deleteTask,
  findTaskByText,
  filterByStatus,
} from "api/API";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";
import { useUser } from "hooks/useUser";
import FilterUsersModal from "lib/modals/FilterUsersModal";
import { CustomTablePagination } from "lib";

const Tasks = () => {
  const { user, can } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [modalOpen, setModalOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([user.id]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const [filterData, setFilterData] = useState({});

  const canViewTasks = can(0);
  const canAlterTasks = can(1);

  useEffect(() => {
    (async () => {
      const users = await getAllUsers();

      setUsers(users);

      setLoadingTasks(false);
    })();
  }, []);

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <span
            onClick={() => navigate(`./` + row.id)}
            style={{ cursor: "pointer" }}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          {canAlterTasks && (
            <>
              &nbsp; &nbsp; &nbsp;
              <span
                onClick={() =>
                  confirm(
                    t("Are you sure you want to delete this task?"),
                    () => {
                      deleteTask(row.id, user.id, () => {
                        // getAllTasks().then((res) => setTasks(res));
                        setTriggerRefetch(!triggerRefetch);
                        toast.success(t("Task has been deleted!"));
                      });
                    }
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon color="errorCustom" />
              </span>
            </>
          )}
        </>
      );
    }

    if (column.id === "user") {
      return (
        <div>
          {row.userId !== null ? (
            <div>
              <Avatar alt={row.user.name}>
                {row.user.name[0].toUpperCase()}
              </Avatar>
            </div>
          ) : (
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              {row.userTask.map((task) => (
                <div>
                  <Avatar key={task.user.id} alt={task.user.name}>
                    {task.user.name[0].toUpperCase()}
                  </Avatar>
                </div>
              ))}
            </div>
          )}
          {/* {row.userTask.map((task) => (
            <div>
              <Avatar key={task.user.id} alt={task.user.name}>
                {task.user.name[0].toUpperCase()}
              </Avatar>
            </div>
          ))} */}
        </div>
      );
    }

    if (column.id === "taskStatus") {
      let colorFromStatus = "#e3cf1b";
      if (row.status) colorFromStatus = "#3B873E";
      if (row.taskStatus === "ToDo") colorFromStatus = "#E91E63";
      if (row.taskStatus === "Done") colorFromStatus = "#3B873E";

      let status;

      if (row.taskStatus === "ToDo") status = t("To Do");
      else if (row.taskStatus === "InProgress") status = t("In Progress");
      else status = t("Done");

      return (
        <Chip
          label={String(status).toUpperCase()}
          sx={{ color: "#fff", background: colorFromStatus }}
        />
      );
    }

    if (column.id === "eta") {
      const deadlineDate = new Date(row.deadline);
      const currentDate = new Date();
      const delta = Math.floor(
        (deadlineDate - currentDate) / 1000 / 60 / 60 / 24 + 1
      );
      return (
        <Chip
          label={`${Math.abs(delta)} ${
            delta >= 0 ? t("days left") : t("days ago")
          }`}
          variant="outlined"
          color={delta > 0 ? "success" : "error"}
        />
      );
    }

    if (column.id === "deadline") {
      return (
        <Typography variant={"tableContent"}>
          {dayjs(value).format("D MMM YYYY")}
        </Typography>
      );
    }

    if (column.id === "createdAt") {
      return (
        <Typography variant={"tableContent"}>
          {dayjs(value).format("D MMM YYYY")}
        </Typography>
      );
    }

    return <Typography variant={"tableContent"}>{value}</Typography>;
  };

  const labels = [
    {
      id: "taskStatus",
      label: "Status",
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "title",
      label: t("Title"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "createdAt",
      label: t("Created at"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "deadline",
      label: "Deadline",
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "eta",
      label: "ETA",
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "user",
      label: t("Responsible"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => {},
    },
  ];

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Tasks")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Tasks")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <TaskModal
        open={modalOpen}
        setOpen={setModalOpen}
        users={users}
        addCallback={() => {
          setTriggerRefetch(!triggerRefetch);
          setModalOpen(false);
          toast.success(t("Task has been added!"));
        }}
      />

      {/* <FilterUsersModal
        open={filterOpen}
        setOpen={setFilterOpen}
        users={users}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        isCheckedAll={isCheckedAll}
        setIsCheckedAll={setIsCheckedAll}
      /> */}

      <CustomTablePagination
        title={t("Tasks")}
        showSearchbar
        loading={loadingTasks}
        setLoading={setLoadingTasks}
        showAdd={canAlterTasks}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        searchFunction={findTaskByText}
        showDatePickerFilter
        onAdd={() => setModalOpen(true)}
        onFilters={() => setFilterOpen(true)}
        labels={labels}
        cellModifier={cellModifier}
        getterFunction={getAllTasks}
        setterFunction={setTasks}
        ComponentUsedForFiltering={FilterUsersModal}
        filterData={filterData}
        setFilterData={setFilterData}
        filterFunction={filterByStatus}
        // tableData={tasks.filter((tsk) =>
        //   tsk.userTask.some((myTask) =>
        //     selectedUsers.some((sUser) => sUser === myTask.userId)
        //   )
        // )}
        tableData={tasks}
      />
    </div>
  );
};

export default Tasks;
