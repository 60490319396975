import React, { useEffect, useState } from "react";
import CustomDialog from "lib/components/Modals/CustomModal";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import { addFieldOnClient } from "api/clientApi";
import { getAllFields } from "api/fieldApi";
import { useTranslation } from "react-i18next";

const FieldsInfoModal = ({ open, setOpen, clientId, addCallback }) => {
  const [data, setData] = useState({});
  const [fields, setFields] = useState([]);
  const [fieldId, setFieldId] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    getAllFields().then((fields) => setFields(fields));
  }, []);

  useEffect(() => {
    if (!open) return;

    setFieldId("");
  }, [open]);

  return (
    <CustomDialog
      open={open}
      handleClose={() => setOpen(false)}
      button1={t("Add")}
      button2={t("Quit")}
      title={t("Add field")}
      onClickButton1={() => addFieldOnClient(clientId, fieldId, addCallback)}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "22rem",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <TextField
              fullWidth
              select
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              name="name"
              value={fieldId}
              onChange={(e) => setFieldId(e.target.value)}
              id="outlined-required"
              label={t("Name")}
            >
              {fields.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {field.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default FieldsInfoModal;
