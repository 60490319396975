import { Box, Typography, TextField } from "@mui/material";
import { CustomDialog } from "lib";
import { useState, useContext, createContext } from "react";
import { useTranslation } from "react-i18next";

const initialState = {
  confirm: () => {},
};

const ConfirmContext = createContext(initialState);

export const ConfirmProvider = ({ children }) => {
  const [text, setText] = useState("Are you sure?");
  const [action, setAction] = useState(() => {});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { t } = useTranslation();

  const confirm = (text = "Are you sure?", action = () => {}) => {
    setText(text);

    setAction(() => action);
    setConfirmOpen(true);
  };

  return (
    <ConfirmContext.Provider value={confirm}>
      {children}
      <CustomDialog
        open={confirmOpen}
        title={t("Confirm action")}
        handleClose={() => setConfirmOpen(false)}
        button1={t("Yes")}
        button2={t("No")}
        onClickButton1={() => {
          action();
          setConfirmOpen(false);
        }}
        onClickButton2={() => setConfirmOpen(false)}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Typography>{text}</Typography>
        </Box>
      </CustomDialog>
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => useContext(ConfirmContext);
