import { useEffect, useState } from "react";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import { CustomTable } from "lib";
import TagModal from "lib/modals/TagModal";
import { useTranslation } from "react-i18next";
import { getTags, deleteTag } from "api/API";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "hooks/useConfirm";
import { useUser } from "hooks/useUser";

const Tags = () => {
  const [tagModal, setTagModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);
  const { t } = useTranslation();
  const confirm = useConfirm();
  const { can } = useUser();

  const canViewTags = can(14);
  const canAlterTags = can(15);

  useEffect(() => {
    getTags().then((tags) => {
      setTags(tags);
      setLoadingTags(false);
    });
  }, []);

  const labels = [
    {
      id: "name",
      label: t("Name"),
      minWidth: 10,
    },
    {
      id: "type",
      label: t("Type"),
      minWidth: 10,
    },
    ...(canAlterTags
      ? [
          {
            id: "actions",
            label: t("Actions"),
            minWidth: 10,
          },
        ]
      : []),
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "type")
      return (
        <div
          style={{
            width: 25,
            height: 25,
            backgroundColor: value,
            borderRadius: "50%",
          }}
        ></div>
      );

    if (column.id === "actions") {
      return (
        <>
          <span style={{ cursor: "pointer" }} onClick={() => setTagModal(row)}>
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp; &nbsp; &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(t("Are you sure you want to delete this tag?"), () =>
                deleteTag(row.id, () =>
                  getTags().then((tags) => {
                    setTags(tags);
                    toast.success(t("Tag has been deleted!"));
                  })
                )
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography variant="pageTitle">{t("Tags")}</Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Tags")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTable
        title={t("Tags")}
        showAdd={canAlterTags}
        onAdd={() => setTagModal(true)}
        onFilters={() => console.log("filters clicked")}
        labels={labels}
        tableData={tags}
        cellModifier={cellModifier}
        loading={loadingTags}
      />

      <TagModal
        open={tagModal}
        setOpen={setTagModal}
        addCallback={() =>
          getTags().then((tags) => {
            setTags(tags);
            setTagModal(false);
            toast.success(t("Tag has been added!"));
          })
        }
        editCallback={() =>
          getTags().then((tags) => {
            setTags(tags);
            setTagModal(false);
            toast.success(t("Tag has been edited!"));
          })
        }
      />
    </div>
  );
};

export default Tags;
