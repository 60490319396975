import { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../i18n'
import { useNavigate } from 'react-router-dom'

// mui
import {
    Avatar,
    Box,
    Button,
    Divider,
    FormControl,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'

// import StarIcon from '@mui/icons-material/Star'
import MenuIcon from '@mui/icons-material/Menu'
import { ThemeProvider } from '@mui/material/styles'
import customTheme from '../../theme'

// import raisisLogo from './raisis.svg'

// import logoLucicosmMediere from 'resources/logo_mediere_alb_vers2.png'
import logo_mediere from 'resources/logo_lucicosm_mediere.png'
import raisisLogo from 'resources/svg/app-bar/raisis-logo.svg'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import DefaultUserPicture from 'resources/img/user/DefaultUserPicture.png'
import { useUser } from 'hooks/useUser'

function Appbar({ onMenuClick }) {
    const { user, setAccessToken } = useUser()
    const { vw } = useWindowDimensions()
    const [anchorEl, setAnchorEl] = useState(null)

    const navigate = useNavigate()

    const languages = [
        {
            value: 'en',
            label: 'English',
        },
        {
            value: 'ro',
            label: 'Română',
        },
    ]

    return (
        <ThemeProvider theme={customTheme}>
            <Box
                sx={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    justifyContent: vw >= 640 ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <img
                    onClick={() => navigate('/')}
                    style={{
                        backgroundColor: 'rgba(0,0,0,0)',
                        width: '158px',
                        height: '49px',
                        display: vw >= 640 ? 'block' : 'none',
                        marginLeft: vw >= 1080 ? '4rem' : '1rem',
                        cursor: 'pointer',
                    }}
                    src={raisisLogo}
                    alt='logo'
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <img
                        src={logo_mediere}
                        style={{
                            width: vw < 600 ? '100px' : '175px',
                            height: 'auto',
                        }}
                        alt={'logo lucicosm'}
                    ></img>
                </Box>

                {/* actions */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    {/* user */}

                    <div
                        style={{
                            marginRight: vw >= 1080 ? '4rem' : '0',
                            padding: '10px 10px',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: '#BDBDBD',
                                borderRadius: '50%',
                                mr: '1rem',
                            }}
                            src={DefaultUserPicture}
                        />

                        <Typography
                            sx={{
                                color: '#929BAA',
                                textTransform: 'none',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {user.name}
                        </Typography>
                    </div>

                    {/* menu */}
                    {vw <= 1080 && (
                        <Button onClick={onMenuClick} sx={{ color: '#929BAA' }}>
                            <MenuIcon
                                sx={{
                                    fontSize: '2rem',
                                }}
                            />
                        </Button>
                    )}

                    {/* menu */}
                    {vw <= 1080 && (
                        <Button onClick={onMenuClick} color='lightCustom'>
                            <MenuIcon
                                sx={{
                                    fontSize: '2rem',
                                }}
                            />
                        </Button>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    )
}

Appbar.propTypes = {
    bgcolor: PropTypes.string,
    logo: PropTypes.node,
    onMenuClick: PropTypes.func,
}

Appbar.defaultProps = {
    bgcolor: 'primaryCustom',
    logo: raisisLogo,
    onMenuClick: () => {},
}

export default Appbar
