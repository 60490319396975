import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Container,
  Typography,
  InputBase,
  Stack,
  styled,
  alpha,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../Spinner/LoadingSpinner";

function CustomTable({
  title,
  showSearchbar,
  onSearch,
  showFilters,
  showAdd,
  showExport,
  onAdd,
  onFilters,
  onExport,
  labels,
  tableData,
  cellModifier,
  loading,

}) {
  // page state
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * rowsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - rowsPerPage;
  const currentRecords = tableData.slice(indexOfFirstRecord, indexOfLastRecord)
  const numberOfPages = Math.ceil(tableData.length / rowsPerPage)



  const { t } = useTranslation();
  // page change handler
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // rows per page change handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const nextPage = () => {
    if (currentPage !== numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }




  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "1rem" }}>
      <Container
        sx={{
          minWidth: "100%",
          display: "flex",
          padding: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 0,
        }}
      >
        <Typography variant="tableTitle">{title}</Typography>
        {showSearchbar && (
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              onChange={onSearch}
              placeholder={t("Search")}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        )}

        <Stack direction="row" spacing={2}>
          {showAdd && (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              style={{
                borderRadius: "99rem",
              }}
              onClick={onAdd}
            >
              {t("Add")}
            </Button>
          )}

          {showFilters && (
            <Button
              variant="contained"
              startIcon={<FilterAltIcon />}
              style={{
                borderRadius: "99rem",
              }}
              onClick={onFilters}
            >
              {t("Filters")}
            </Button>
          )}

          {showExport && (
            <Button
              variant="contained"
              startIcon={<FilterAltIcon />}
              style={{
                borderRadius: "99rem",
              }}
              onClick={onExport}
            >
              Export
            </Button>
          )}
        </Stack>
      </Container>
      <Divider />

      <TableContainer sx={{ maxHeight: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {labels.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth || 10 }}
                  onClick={column.onClick}
                  sx={{ color: "textCustom.secondary", fontWeight: 600 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={labels.length}>
                  {/* <Typography
                    sx={{
                      color: "textCustom.secondary",
                      textAlign: "center",
                      padding: "5rem 1rem",
                    }}
                  >
                    {t("Loading...")}
                  </Typography> */}
                  <LoadingSpinner loading={loading} margin="auto" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!tableData.length ? (
                  <TableRow>
                    <TableCell colSpan={labels.length}>
                      <Typography
                        sx={{
                          color: "textCustom.secondary",
                          textAlign: "center",
                          padding: "5rem 1rem",
                        }}
                      >
                        {t("No data available.")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <>
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {labels.map((column) => {
                              const value = row[column.id];

                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  sx={{
                                    color: "textCustom.secondary",
                                  }}
                                  style={
                                    Boolean(row.info)
                                      ? {
                                          borderBottom: "none",
                                        }
                                      : {}
                                  }
                                >
                                  {cellModifier
                                    ? cellModifier(row, column, value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>

                          {Boolean(row.info) && (
                            <TableRow>
                              <TableCell
                                sx={{
                                  color: "textCustom.secondary",
                                  padding: 0,
                                }}
                                colSpan={labels.length}
                              >
                                {row.info}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      );
                    })
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "1rem",
  padding: "0 8rem",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  border: "1px solid #eaeaea",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

// prop types
CustomTable.propTypes = {
  title: PropTypes.string,
  showSearchbar: PropTypes.bool,
  showFilters: PropTypes.bool,
  showExport: PropTypes.bool,
  showAdd: PropTypes.bool,
  cellModifier: PropTypes.func,
  onAdd: PropTypes.func,
  onFilters: PropTypes.func,
  onExport: PropTypes.func,
  onSearch: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      minWidth: PropTypes.number,
      onClick: PropTypes.func,
    })
  ),
  tableData: PropTypes.array,
  loading: PropTypes.bool,
};

// default props
CustomTable.defaultProps = {
  title: "Your title",
  showSearchbar: false,
  showFilters: false,
  showExport: false,
  showAdd: false,
  cellModifier: () => {},
  onAdd: () => {},
  onExport: () => {},
  onFilters: () => {},
  onSearch: () => {},
  loading: false,
  labels: [
    {
      onClick: () => {},
      id: "id",
      label: "ID",
      minWidth: 100,
    },
  ],
  tableData: [],
};

export default CustomTable;
