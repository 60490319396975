import React from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'

function Content({ children }) {
    const { vw } = useWindowDimensions()
    return (
        <div
            style={{
                padding: vw >= 1080 ? '6rem 0 0 16rem' : '6rem 0 0 0',
                background: '#ffffff',

                height: '100vh',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    borderTopLeftRadius: vw >= 1080 ? '1rem' : '0',
                    background: '#fafbfc',
                    height: 'calc(100% - 10rem)',

                    padding: '2rem',
                    boxShadow: 'inset 5px 5px 15px rgba(35, 78, 140, 0.1)',
                    overflowY: 'auto',
                }}
            >
                {/* Inner content */}
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Content
