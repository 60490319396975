import React from "react";
import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Note({ user, title, text, createdAt }) {
  const dayjs = require("dayjs");
  var timeSince = dayjs(createdAt).from();

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#FFFFFF",
        borderRadius: "1rem",
        padding: "1rem",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Avatar sx={{ marginRight: "1rem" }}>
          <AccountCircleIcon />
        </Avatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
            {user ? user.name : "Nume inexistent"}
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
            {title} | {timeSince}
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ marginTop: "1rem" }}>{text}</Typography>
    </Box>
  );
}

export default Note;
