import React, { useState, useEffect } from "react";
import { CustomDialog } from "lib";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  Chip,
  FormGroup,
  Autocomplete,
} from "@mui/material";
import {
  getAllFields,
  getAllTrips,
  addClientDeparture,
  getFieldsOnClient,
  getPeriods,
  getAllDestinations,
} from "api/API";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

import dayjs from "dayjs";

const ClientDepartureModal = ({ open, setOpen, clientId, addCallback }) => {
  const { t } = useTranslation();
  const [departureData, setDepartureData] = useState({});
  const [trips, setTrips] = useState([]);
  const [fields, setFields] = useState([]);
  const [fieldsInfo, setFieldsInfo] = useState([]);

  const [clientFields, setClientFields] = useState([]);
  const [depDate, setDepDate] = useState(Date.now());
  const [departurePeriods, setDeparturePeriods] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [destinations, setDestinations] = useState([]);

  const statuses = ["INSCRIS"];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeClientFields = (event, value) => {
    setDepartureData({
      ...departureData,
      fieldIds: value.map((val) => val.id),
    });
  };

  const handleChange = (e) => {
    setDepartureData({
      ...departureData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChechbox = (key, value) => {
    const shallow = { ...departureData };
    shallow[key] = value;
    setDepartureData(shallow);
  };

  useEffect(() => {
    getPeriods().then((res) => setPeriods(res));
    getAllDestinations().then((destinations) => setDestinations(destinations));
  }, []);

  useEffect(() => {
    if (!open) return;

    if (open === true) {
      setDepartureData({
        clientId,
        // status: statuses[0],
        paymentStatus: "",
        fieldIds: [],
        dates: [],
        watchedVideo: false,
        firstPayDate: null,
        destinationId: "",
      });
    }
  }, [open]);

  useEffect(() => {
    getAllTrips().then((trips) => setTrips(trips));
    getAllFields().then((fields) => setFields(fields));
    getFieldsOnClient(clientId).then((fieldsInfo) => setFieldsInfo(fieldsInfo));
  }, [clientId]);

  return (
    <CustomDialog
      handleClose={() => setOpen(false)}
      open={Boolean(open)}
      title={t("Add departure")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() => addClientDeparture(departureData, addCallback)}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "30rem",
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          spacing={2}
          style={{ marginBottom: "20px" }}
        >
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              select
              label={t("Departure Place")}
              onChange={handleChange}
              name="destinationId"
              value={departureData.destinationId}
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {destinations.map((destination) => (
                <MenuItem key={destination.id} value={destination.id}>
                  {destination.city.name +
                    ", " +
                    destination.states.name +
                    ", " +
                    destination.country.name +
                    " --> " +
                    destination.location}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Payment status")}
              value={departureData.paymentStatus}
              name="paymentStatus"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("First Pay Date")}
                name="firstPayDate"
                value={departureData.firstPayDate}
                onChange={(value) => {
                  handleChange({ target: { name: "firstPayDate", value } });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            <TextField
              multiline
              required
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Payment Type")}
              value={departureData.paymentType}
              name="paymentType"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              multiline
              required
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Description")}
              value={departureData.description}
              name="description"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={fields}
              // value={clientFields}
              getOptionLabel={(option) => option.name}
              onChange={handleChangeClientFields}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  label={t("Fields")}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={6}
            // sx={{
            //   marginBlock: "15px",
            // }}
          >
            <TextField
              fullWidth
              required
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Duration")}
              value={departureData.timeId}
              name="timeId"
              onChange={handleChange}
              select
            >
              {periods.map((period) => (
                <MenuItem value={period.id} key={period.id}>
                  {period.time}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={departureData.watchedVideo}
                    onChange={() =>
                      handleChechbox(
                        "watchedVideo",
                        !departureData.watchedVideo
                      )
                    }
                    name={"watchedVideo"}
                  />
                }
                label={t("Watched video")}
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <div
            // style={{
            //   marginBlock: "15px",
            // }}
            >
              <Typography style={{ marginBottom: "10px" }}>
                {t("Client fields")}
              </Typography>
              <Typography>
                {fieldsInfo.map((flds) => (
                  <Chip
                    key={flds.field.id}
                    color="error"
                    label={flds.field.name}
                    sx={{ margin: "2px" }}
                  />
                ))}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: "15px" }}>
          {t("Available time periods")}
        </Typography>
        <Grid container spacing={2} alignContent="flex-end" alignItems="center">
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Period*")}
                name="period"
                value={depDate}
                onChange={(value) => setDepDate(value)}
                inputFormat="MM/YYYY"
                views={["year", "month"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={t("Add a date")}
              onClick={() =>
                setDepartureData((current) => ({
                  ...current,
                  dates: [...current.dates, depDate],
                }))
              }
              icon={
                <AddCircleOutlineIcon
                  color="primary"
                  style={{
                    cursor: "pointer",
                  }}
                />
              }
            />
          </Grid>
          <Grid item>
            <Typography>
              {departureData?.dates?.length > 0 ? (
                departureData.dates.map((opt) => (
                  <Chip
                    key={opt}
                    label={dayjs(opt).format("MM/YYYY")}
                    onDelete={() =>
                      setDepartureData({
                        ...departureData,
                        dates: departureData.dates.filter(
                          (date) => date !== opt
                        ),
                      })
                    }
                    deleteIcon={<DeleteIcon />}
                    variant="outlined"
                    style={{
                      backgroundColor: "#F4F5F7",
                      padding: "5px",
                      margin: "3px",
                      boxShadow:
                        "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                ))
              ) : (
                <Typography style={{ color: "#E91E63" }}>
                  {t("Please select the dates")}
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default ClientDepartureModal;
