import React from "react";
import { useState } from "react";
import { CustomDialog } from "lib";
import { Box, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const AddReasonModal = ({
  open,
  setOpen,
  clientOnRouteId,
  functionForDelete,
  deleteCallback,
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Delete client from route")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() => {
        if (reason === "") toast.error("Introduceti un motiv!");
        else
          functionForDelete(clientOnRouteId, reason, () => {
            setOpen(false);
            setReason("");
            deleteCallback();
          });
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "20rem",
        }}
      >
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("Reason")}
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{ fieldset: { borderRadius: "1rem" } }}
            ></TextField>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AddReasonModal;
