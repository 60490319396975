import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomTable } from "lib";
import { getClientDepartures, deleteDeparture } from "api/clientApi";
import { deleteClientDepartureWithReason } from "api/API";
import ClientDepartureModal from "lib/modals/ClientDepartureModal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { Skeleton, Box, Typography, Chip } from "@mui/material";
import { useConfirm } from "hooks/useConfirm";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getPaymentStatusColor, getStatusColor } from "utils/functions";
import AddReasonModal from "lib/modals/AddReasonModal";

function Departures() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { t } = useTranslation();
  const [departures, setDepartures] = useState([]);
  const [client, setClient] = useState([]);
  const [route, setRoute] = useState([]);
  const [field, setField] = useState([]);
  const [loadingDepartures, setLoadingDepartures] = useState(true);
  const [departureModal, setDepartureModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [clientOnRoutesInfo, setClientOnRoutesInfo] = useState({});

  useEffect(() => {
    getClientDepartures(id).then((res) => {
      setClient(res.client);
      setRoute(res.route);
      setField(res.field);
      setDepartures(res);
      setLoadingDepartures(false);
    });
  }, [id]);

  const labels = [
    {
      id: "route",
      label: t("Trip"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "departure",
      label: t("Departure date"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "departurePlace",
      label: t("Departure Place"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "destination",
      label: t("Destination"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "CIP",
      label: "CIP",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentStatus",
      label: t("Payment status"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "leftToPay",
      label: t("Left to pay"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "firstPayDate",
      label: t("First Pay Date"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "watchedVideo",
      label: t("Watched video"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "status") {
      let colorFromStatus = getStatusColor(row.status);

      return (
        <Typography
          variant="tableContent"
          style={{
            backgroundColor: colorFromStatus,
            color: "white",
            padding: "4px 14px",
            borderRadius: "999px",
            display: "inline-block",
          }}
        >
          {value}
        </Typography>
      );
    }
    if (column.id === "paymentStatus") {
      const colorFromPayment = getPaymentStatusColor(
        row.totalPayed,
        row.route?.price
      );
      let priceOfTheRoute = row.route?.price;
      if (priceOfTheRoute === undefined)
        priceOfTheRoute = t("Trip not established yet");
      else priceOfTheRoute = row.route?.price;
      return (
        <Typography
          variant="tableContent"
          sx={{
            backgroundColor: colorFromPayment,
            color: "white",
            padding: "4px 14px",
            borderRadius: "999px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {`${row.totalPayed}/${priceOfTheRoute}`}
        </Typography>
      );
    }

    if (column.id === "route") {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {row.isCanceled && (
            <Chip
              label={t("ANULAT")}
              variant="outlined"
              color="error"
              size="small"
            />
          )}
          <Typography variant="tableContent">{row.route?.type}</Typography>
        </div>
      );
    }

    if (column.id === "destination") {
      if (row.type === "TUR") {
        // checking if the type of the route is TUR
        if (row.route === null)
          return (
            <Typography variant="tableContent">
              {t("Trip not established yet")}
            </Typography>
          );
        // checking if the departure has a route assigned to
        else {
          //displaying the location of the route
          return (
            <Typography variant="tableContent">
              {" "}
              {row.route?.destination?.country.name +
                " - " +
                row.route?.destination.states.name +
                " - " +
                row.route?.destination?.city.name}
            </Typography>
          );
        }
      } else if (row.type === "RETUR") {
        return (
          // displaying the address where the client left from in the beggining
          <Typography variant="tableContent">
            {row.destination?.city.name +
              " - " +
              row.destination?.states.name +
              " - " +
              row.destination?.country.name}
          </Typography>
        );
      }
    }

    if (column.id === "firstPayDate") {
      return row.firstPayDate ? (
        <Typography>{dayjs(row.firstPayDate).format("D MMM YYYY")}</Typography>
      ) : (
        "nu exista"
      );
    }

    if (column.id === "departure")
      return (
        <Typography variant="tableContent">
          {Boolean(row.route)
            ? dayjs(row.route.departure).format("D MMM YYYY")
            : ""}
        </Typography>
      );
    if (column.id === "CIP")
      return <Typography variant="tableContent">{row.client.CIP}</Typography>;

    if (column.id === "departurePlace") {
      if (row.type === "TUR") {
        //checking if the type of the route is TUR and returning the destination we choose when we enroll the client
        return (
          <Typography variant="tableContent">
            {row.destination.city.name +
              " - " +
              row.destination.states.name +
              " - " +
              row.destination.country.name}
          </Typography>
        );
      } else if (row.type === "RETUR") {
        //if the type of the route is RETUR then we show where the route is coming from
        if (row.route === null) {
          return (
            <Typography variant="tableContent">
              {t("Not established yet")}
            </Typography>
          );
        } else {
          return (
            <Typography variant="tableContent">
              {row.route?.StartingDestination?.city.name +
                " - " +
                row.route?.StartingDestination?.states.name +
                " - " +
                row.route?.StartingDestination?.country.name}
            </Typography>
          );
        }
      }
    }
    if (column.id === "actions")
      return (
        <>
          <span
            onClick={() => navigate("/clients/departures-list" + "/" + row.id)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp; &nbsp; &nbsp;
          {row.isCanceled === false && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClientOnRoutesInfo(row.id);
                setReasonModal(true);
              }}
            >
              <DeleteIcon color="errorCustom" />
            </span>
          )}
        </>
      );

    if (column.id === "leftToPay") {
      if (row.route?.price > row.paymentStatus) {
        return (
          <Typography variant="tableContent">
            {row.route?.price - row.totalPayed}€
          </Typography>
        );
      } else if (row.route?.price <= row.paymentStatus) {
        return (
          <Typography variant="tableContent">{t("Fully paid")} </Typography>
        );
      } else if (row.route?.price === undefined) {
        return (
          <Typography variant="tableContent">
            {t("Trip not established yet")}{" "}
          </Typography>
        );
      }
    }
    if (column.id === "watchedVideo") {
      if (row.watchedVideo === true)
        return <Typography variant="tableContent">{t("Yes")}</Typography>;
      else return <Typography variant="tableContent">{t("No")}</Typography>;
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  return loadingDepartures ? (
    <Box>
      <Skeleton animation="wave" sx={{ height: 300 }} />
    </Box>
  ) : (
    <div>
      <CustomTable
        title={t("Departures")}
        showAdd
        labels={labels}
        tableData={departures?.map((dep) => ({
          ...dep,
          info:
            dep.type === "TUR" ? (
              <>
                <div
                  style={{ padding: "0 1rem", display: "flex", gap: "12px" }}
                >
                  <span style={{ fontWeight: 800, fontSize: "14px" }}>
                    {" "}
                    {t("Fields :")}{" "}
                  </span>
                  {dep.DepartureFields?.map((depdep) => (
                    <span>{depdep.field?.name}</span>
                  ))}
                </div>

                <div style={{ padding: "1rem", display: "flex", gap: "12px" }}>
                  <span style={{ fontWeight: 800 }}>
                    {t("Available periods:")}
                  </span>
                  {dep.dates?.map((depdep) => (
                    <span>{dayjs(depdep).format("MM/YYYY")}</span>
                  ))}
                </div>
              </>
            ) : (
              <></>
            ),
        }))}
        cellModifier={cellModifier}
        onAdd={() => setDepartureModal(true)}
      />
      <ClientDepartureModal
        open={departureModal}
        setOpen={setDepartureModal}
        clientId={id}
        addCallback={() =>
          getClientDepartures(id).then((departures) => {
            setDepartures(departures);
            setDepartureModal(false);
            toast.success(t("Departure added on client!"));
          })
        }
      />
      <AddReasonModal
        open={reasonModal}
        setOpen={setReasonModal}
        clientOnRouteId={clientOnRoutesInfo}
        functionForDelete={deleteClientDepartureWithReason}
        deleteCallback={() => {
          getClientDepartures(id).then((res) => {
            setDepartures(res);
            toast.success(t("Departure has been deleted!"));
          });
        }}
      />
    </div>
  );
}

export default Departures;
