import React from "react";
import PropTypes from "prop-types";

// mui
import { Typography, Stack, Box, Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "../../theme";

// chartjs
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TT,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TT,
  Legend
);

function LineChart({ title, tooltip, width, chartLabel, chartData }) {
  // chart styling
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  // chart data
  const data = {
    labels: chartLabel,
    datasets: chartData.map((i) => {
      return {
        ...i,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      };
    }),
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          width: width,
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",

          justifyContent: "align-start",
          alignItems: "start",
          overflow: "hidden",
        }}
      >
        <Stack
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="cardHeader" sx={{ m: "1rem 0 0 1rem" }}>
            {title}
            <Tooltip title={tooltip}>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Line
            options={options}
            data={data}
            style={{
              padding: "2rem",
            }}
          />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}

LineChart.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  width: PropTypes.string,
  chartLabel: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
      borderColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    })
  ),
};

LineChart.defaultProps = {
  title: "Test",
  tooltip: "Tooltip content comes here",
  width: "100%",
  chartLabel: [
    "Lorem",
    "Ipsum",
    "Dolor",
    "Sit",
    "Amet",
    "Consectetur",
    "Adipiscing",
    "Elit",
  ],
  chartData: [
    {
      label: "Dataset 1",
      data: [10, 20, 40, 30, 10, 50, 40, 60],
      backgroundColor: "#3C4693",
      borderColor: "#3C4693",
      cubicInterpolationMode: "monotone",
      tension: 0.4,
    },
    {
      label: "Dataset 2",
      data: [30, 10, 50, 40, 60, 10, 20, 40],
      backgroundColor: "#5664D2",
      borderColor: "#5664D2",
    },
  ],
};

export default LineChart;
