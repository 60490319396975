import { useState } from "react";
import { CustomDialog } from "lib";
import { Box, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Roles from "pages/Settings/Roles";
import { addRole } from "api/permissionsAPI";
import { toast } from "react-toastify";

const AddRoleModal = ({ open, setOpen, fetchRoles }) => {
  const { t } = useTranslation();
  const [roleName, setRoleName] = useState("");
  const [rolePermissions, setRolePermissions] = useState([]);

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Add role")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() =>
        addRole(
          {
            name: roleName,
            rolePermissions,
          },
          () => {
            fetchRoles().then(() => {
              setRoleName("");
              setRolePermissions([]);
              setOpen(false);
              toast.success(t("Role has been added successfully!"));
            });
          }
        )
      }
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "20rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item sx={{ width: "100%" }}>
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              id="outlined-required"
              label={t("Role name")}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Roles
              checkedPermission={rolePermissions}
              setCheckedPermissions={setRolePermissions}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AddRoleModal;
