import React, { useEffect, useState } from 'react'
import CustomDialog from 'lib/components/Modals/CustomModal'
import { useTranslation } from 'react-i18next'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import { toast } from 'react-toastify'
import { identificationDocuments } from 'api/tripApi'

const AddIdentification = ({ open, setOpen, data, addCallback }) => {
    const { t } = useTranslation()

    const [state, setState] = useState({
        CNPS: false,
        cityHall: false,
        celibatar: false,
    })

    useEffect(() => {
        if (open)
            setState({
                CNPS: data.CNPS,
                cityHall: data.cityHall,
                celibatar: data.celibatar,
            })
    }, [open])

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        })
    }

    const { CNPS, cityHall, celibatar } = state

    const handleSubmit = () => {
        identificationDocuments(
            data.clientsOnRoutesId,
            CNPS,
            cityHall,
            celibatar,
        ).then((res) => {
            if (res.ok) {
                toast.success(t('Client papers have been updated!'))
                addCallback()
                setOpen()
            } else toast.error(t(`Client papers couldn't been updated!`))
        })
    }

    return (
        <CustomDialog
            handleClose={() => setOpen()}
            open={Boolean(open)}
            title={t('Add client papers')}
            button1={t('Finalize')}
            button2={t('Quit')}
            onClickButton1={handleSubmit}
        >
            <div
                style={{
                    width: '25rem',
                }}
            >
                <FormControl
                    component='fieldset'
                    sx={{ m: 3 }}
                    variant='standard'
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={CNPS}
                                    onChange={handleChange}
                                    name='CNPS'
                                />
                            }
                            label={t('CNPS document')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={cityHall}
                                    onChange={handleChange}
                                    name='cityHall'
                                />
                            }
                            label={t('City hall document')}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={celibatar}
                                    onChange={handleChange}
                                    name='celibatar'
                                />
                            }
                            label={t('Bachelor document')}
                        />
                    </FormGroup>
                </FormControl>
            </div>
        </CustomDialog>
    )
}

export default AddIdentification
