import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CustomTable } from "lib";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import { getPaymentsForClient } from "api/clientApi";
import dayjs from "dayjs";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Payments = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [clientPayments, setClientPayments] = useState([]);

  useEffect(() => {
    getPaymentsForClient(id).then((payment) => setClientPayments(payment));
  }, [id]);

  const paymentsLabels = [
    {
      id: "paymentName",
      label: t("Payment Name"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentDate",
      label: t("Payment Date"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "payment",
      label: t("Amount Paid"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentType",
      label: t("Payment Type"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("actions clicked"),
    },
  ];

  const cellModifierPayments = (row, column, value) => {
    if (column.id === "paymentDate") {
      return (
        <Typography variant="tableContent">
          {dayjs(value).format("DD-MM-YYYY")}
        </Typography>
      );
    }

    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/clients/departures-list/${row.departureId}`);
            }}
          >
            <ArrowForwardIcon color="primary" />
          </span>
        </>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "2rem",
        width: "100%",
        maxWidth: "1080px",
        alignItems: "start",
        "> *": {
          width: "100%",
          //   maxWidth: "30rem",
        },
      }}
    >
      <CustomTable
        title={t("Payments")}
        labels={paymentsLabels}
        cellModifier={cellModifierPayments}
        tableData={clientPayments}
      />
    </Box>
  );
};

export default Payments;
