import React, { useState } from "react";
import { CustomDialog } from "lib";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

import Step1Signature from "./Step1Signature";
import Step2Signature from "./Step2Signature";
import Step3Signature from "./Step3Signature";

const SignatureModal = (props) => {
  const {
    open,
    setOpen = () => {},
    signatureImage,
    setSignatureImage = () => {},
  } = props;
  const { t } = useTranslation();

  const [imageSrc, setImageSrc] = useState(null);
  const [step, setStep] = useState(1);

  const cancelSignatureImage = async () => {
    fetch(`${process.env.REACT_APP_SCANNER_SIGNATURE_URL}/api/sign/cancel`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
      },
    });
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  return (
    <CustomDialog
      handleClose={() => setOpen(false)}
      open={Boolean(open)}
      title={t("Sign the document")}
      button1={t("Save signature")}
      button1Loading={step !== 3}
      button2={t("Quit")}
      onClickButton1={() => {
        if (imageSrc) {
          if (signatureImage.isNewSignature === false) {
            setSignatureImage((prev) => ({
              ...prev,
              clientSignature: imageSrc,
            }));
          } else {
            setSignatureImage((prev) => ({
              ...prev,
              legalSignature: imageSrc,
            }));
          }
          toast.success(t("Signature was saved succesfully"));
          setOpen(false);
        } else {
          toast.error(t("The signature could not be saved. Please try again"));
        }
      }}
      onClickButton2={() => {
        cancelSignatureImage();
        setImageSrc(null);
        setOpen(false);
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          minWidth: "30rem",
          maxWidth: "30rem",
        }}
      >
        {step === 1 && (
          <Step1Signature
            signatureImage={signatureImage}
            setSignatureImage={setSignatureImage}
            handleNext={handleNext}
          />
        )}

        {step === 2 && (
          <Step2Signature
            handlePrev={handlePrev}
            handleNext={handleNext}
            setImageSrc={setImageSrc}
          />
        )}

        {step === 3 && (
          <Step3Signature
            imageSrc={imageSrc}
            setStep={setStep}
            setImageSrc={setImageSrc}
            cancelSignatureImage={cancelSignatureImage}
            handlePrev={handlePrev}
          />
        )}
      </Box>
    </CustomDialog>
  );
};

export default SignatureModal;
