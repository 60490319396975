// react imports
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React from "react";
import { useUser } from "./hooks/useUser";

import {
  Dashboard,
  Content,
  Layout,
  VisualizeClients,
  ArrivalList,
  DepartureList,
  TaxReturns,
  Tasks,
  AllTrips,
  ArrivalTrips,
  DepartureTrips,
  Drivers,
  Fleet,
  Driver,
  Vehicle,
  Trip,
  Client,
  Login,
  RegisteringTaxReturn,
  DepartureRegistering,
  Destinations,
  Task,
  Tags,
  Fields,
  Settings,
  Users,
  User,
  Periods,
  Reports,
  Registrations,
  Versions,
  Documents,
  InOutReports,
} from "./pages";
import ResetPassword from "lib/modals/ResetPassword";
import { CircularProgress } from "@mui/material";

function App() {
  const { user, loadingUser } = useUser();

  return loadingUser ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      {user.id ? (
        <Router>
          <Layout />
          <Content>
            <Routes>
              <Route path="/settings" element={<Settings />} />
              <Route path="/periods" element={<Periods />} />
              <Route path="/users">
                <Route path="" element={<Users />} />
                <Route path=":id" element={<User />} />
              </Route>
              <Route path="/" element={<Dashboard />} />
              {/* tasks */}
              <Route path="tasks">
                <Route path="" element={<Tasks />} />
                <Route exact path=":id" element={<Task />} />
              </Route>
              {/* client */}
              <Route path="clients">
                <Route path="visualize-clients">
                  <Route path="" element={<VisualizeClients />} />
                  <Route exact path=":id" element={<Client />} />
                </Route>
                {/* <Route path="tax-returns" element={<TaxReturns />} />
                <Route path="departures-list" element={<DepartureList />} />
                <Route path="arrivals-list" element={<ArrivalList />} /> */}
                <Route path="tax-returns">
                  <Route path="" element={<TaxReturns />} />
                  <Route exact path=":id" element={<RegisteringTaxReturn />} />
                </Route>
                <Route path="departures-list">
                  <Route path="" element={<DepartureList />} />
                  <Route exact path=":id" element={<DepartureRegistering />} />
                </Route>
                <Route path="arrivals-list">
                  <Route path="" element={<ArrivalList />} />
                  <Route exact path=":id" element={<DepartureRegistering />} />
                </Route>
              </Route>
              {/* trips */}
              <Route path="documents">
                <Route path="" element={<Documents />} />
              </Route>
              <Route path="inoutreport">
                <Route path="" element={<InOutReports />} />
              </Route>
              <Route path="trips">
                <Route path="all-trips" element={<AllTrips />} />
                <Route path="arrival-trips" element={<ArrivalTrips />} />
                <Route path="departure-trips" element={<DepartureTrips />} />
                <Route exact path="trip/:id" element={<Trip />} />
              </Route>

              {/* trips */}
              <Route path="trips">
                <Route path="all-trips" element={<AllTrips />} />
                <Route path="arrival-trips" element={<ArrivalTrips />} />
                <Route path="departure-trips" element={<DepartureTrips />} />
                <Route exact path="trip/:id" element={<Trip />} />
              </Route>

              {/* drivers */}
              <Route path="/drivers">
                <Route path="" element={<Drivers />} />
                <Route exact path="driver/:id" element={<Driver />} />
              </Route>

              {/* fleet */}
              <Route path="/fleet">
                <Route path="" element={<Fleet />} />
                <Route exact path="vehicle/:id" element={<Vehicle />}></Route>
              </Route>

              {/* fields */}
              <Route path="/destinations">
                <Route path="" element={<Destinations />} />
              </Route>
              <Route path="/fields">
                <Route path="" element={<Fields />} />
              </Route>

              {/* tags */}
              <Route path="/tags">
                <Route path="" element={<Tags />}></Route>
              </Route>
              <Route path="/reports">
                <Route path="" element={<Reports />} />
              </Route>

              {/* registrations */}

              <Route path="clients/registrations">
                <Route path="" element={<Registrations />}></Route>
              </Route>

              <Route path="/versions">
                <Route path="" element={<Versions />}></Route>
              </Route>

              {/* route to redirect form login page to dashboard upon logging in */}
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </Content>
        </Router>
      ) : (
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="api/reset_password/:token"
              element={<ResetPassword />}
            />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
