import React, { useState } from 'react'
import { CustomDialog } from 'lib'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { useUser } from 'hooks/useUser'
import { toast } from 'react-toastify'
import { TextField } from '@mui/material'
import TooltipUWrapper from 'lib/Tooltip/TooltipWrapper'
import { support } from 'api/usersAPI'


const TehnicalSupport = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");

  const { user } = useUser();

  const handleSubmit = () => {
    if (text.trim() === "") {
      toast.error("Va rugam introduceti un text valid!");
      return;
    }
    support(user.id, text.trim()).then((res) => {
      if (res.ok) {
        toast.success(t("Bugs / questions sent successfully!"));
        setOpen(false);
      } else {
        toast.error(t(`Bugs / questions coundn't be sent successfully!`));
      }
    });
  };

  return (
    <CustomDialog
      handleClose={() => setOpen(false)}
      open={Boolean(open)}
      setOpen={setOpen}
      title={t("Get help")}
      button1={t("BACK")}
      button2={t("SEND")}
      onClickButton2={handleSubmit}
    >
      <div
        style={{
          width: "560px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          name="text"
          label={t("Description / Question")}
          size="medium"
          multiline
          value={text}
          onChange={(e) => setText(e.target.value)}
          rows={6}
        />

        <TooltipUWrapper
          position="right"
          textTip={t(
            "* When describing a bug, you must present it in as much detail as possible, describe the steps do within the platform until you discover your bug and other relevant details!"
          )}
          style={{
            border: "1px solid",
            borderColor: "#ff4b55",
            color: "#ff4b55",
            fontSize: "13px",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              color: "#ff4b55",
              marginTop: "0.5rem",
            }}
          >
            <InfoIcon
              sx={{
                fontSize: "22px",
              }}
            />
          </div>
        </TooltipUWrapper>
      </div>
    </CustomDialog>
  );
};

export default TehnicalSupport
