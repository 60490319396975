import { CustomDialog, Note } from "lib/";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, TextField, Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addNote, getNotes } from "api/API";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";

function Notes() {
  let { id } = useParams();
  const [notes, setNotes] = useState([]);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const { t } = useTranslation();
  const { user } = useUser();

  const handleSubmitNote = () => {
    addNote(id, user.id, title, note, () => {
      getNotes(id).then((notes) => setNotes(notes));
      setAddNoteModal(false);
      setTitle("");
      setNote("");
      toast.success("Nota a fost adăugată!");
    });
  };

  useEffect(() => {
    getNotes(id).then((notes) => setNotes(notes));
  }, [id]);

  return (
    <div>
      <Button
        startIcon={<AddIcon />}
        sx={{
          height: "2.25rem",
          backgroundColor: "#5664D2",
          borderRadius: "4rem",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#5684D2",
          },
        }}
        onClick={() => setAddNoteModal(true)}
      >
        {t("Add Note")}
      </Button>

      <CustomDialog
        button1={t("Finalize")}
        button2={t("Quit")}
        title={t("New Note")}
        handleClose={() => setAddNoteModal(false)}
        open={addNoteModal}
        onClickButton1={() => handleSubmitNote()}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                autoComplete="off"
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("Title")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder={t("Note")}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          margin: "1rem 0",
        }}
      >
        {notes.map((note) => (
          <Note
            key={note.id}
            user={note.user}
            title={note.title}
            createdAt={note.createdAt}
            text={note.note}
          />
        ))}
      </Box>
    </div>
  );
}

export default Notes;
