import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

import { Comments, CustomTable } from "lib";

import {
    Breadcrumbs,
    Link,
    Typography,
    Box,
    Grid,
    TextField,
} from '@mui/material'

import { toast } from 'react-toastify'

import { DetailsComponet, CustomDialog, DocumentModal } from 'lib'

import EditHoursModal from 'lib/modals/EditHoursModal'

import {
  getClientOnRoute,
  deleteDocDeparture,
  uploadDocDeparture,
  getDocsDepartures,
  deleteAdditionalDocs,
  uploadAdditionalDocs,
  getAdditionalDocs,
  getReturningInfo,
  editReturningInfo,
  getOthersInfo,
  addHours,
  getAllHours,
  addPaymentOnDeparture,
  getDeparturePayments,
  deletePayment,
} from "api/API";

import { useEffect } from "react";
import dayjs from "dayjs";
import DocumentDetails from "lib/components/DocumentDetails/DocumentDetails";
import PrelungireModal from "lib/modals/PrelungireModal";
import AddFinalPay from "lib/modals/AddFinalPay";
import EditDepartureModal from "lib/modals/EditDepartureModal";
import AddPaymentOnDeparture from "lib/modals/AddPaymentOnDeparture";

function DepartureRegistering() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [client, setClient] = useState({});
  const [route, setRoute] = useState({});
  const [additionalDocs, setAdditionalDocs] = useState([]);
  const [details, setDetails] = useState({});
  const [docs, setDocs] = useState([]);
  const [documentModal, setDocumentModal] = useState(false);
  const [additionalDocumentModal, setAdditionalDocumentModal] = useState(false);
  const [returningInfo, setReturningInfo] = useState([]);

  const [addHoursModal, setAddHoursModal] = useState(false);
  const [addHoursData, setAddHoursData] = useState(null);
  const [editHoursModal, setEditHoursModal] = useState(false);

  const [getHoursWorked, setHoursWorked] = useState([]);

  const [returningEditModal, setReturningEditModal] = useState(false);
  const [returningEditData, setReturningEditData] = useState({});

  const [others, setOthers] = useState([]);
  const [othersModal, setOthersModal] = useState(false);
  const [othersData, setOthersData] = useState({});

  const [editBasicInfoModal, setEditBasicInfoModal] = useState(false);

  const [detailsEditData, setDetailsEditData] = useState({});

  const [prelungireModal, setPrelungireModal] = useState(false);

  const [clientsOnRoutesId, setClientOnRoutesId] = useState({});

  const [openEditDepartureModal, setOpenEditDepartureModal] = useState(false);

  const [paymentsDatas, setPaymentsDatas] = useState([]);

  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);

  const [rowDataPayments, setRowDataPayments] = useState({});

  const { t } = useTranslation();

  const handleChangeEdit = (e) => {
    setDetailsEditData({
      ...detailsEditData,
      [e.target.name]: e.target.value,
    });
  };

  const renderClient = [
    {
      key: "firstName",
      label: t("Name"),
    },
    {
      key: "lastName",
      label: t("Surname"),
    },
    {
      key: "birthday",
      label: t("Birthday"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">
            {dayjs(displayData).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },

    {
      key: "phone",
      label: t("Phone"),
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "CNP",
      label: "CNP",
    },
    {
      key: "Adress",
      label: t("Address"),
    },
  ];

  const renderRoute = [
    {
      key: "type",
      label: t("Type"),
    },
    {
      key: "startDeparture",
      label: t("Departure period"),
      cellModifier: (displayData) => {
        return route.departure === null ? (
          <Typography variant="detailsText">
            {dayjs(route.startDeparture).format("DD-MM-YYYY") +
              " <--> " +
              dayjs(route.finalDeparture).format("DD-MM-YYYY")}
          </Typography>
        ) : (
          <Typography variant="detailsText">
            {dayjs(route.departure).format("DD-MM-YYYY")}
          </Typography>
        );
      },
    },
    {
      key: "destinationx",
      label: t("Destination"),
    },
    {
      key: "driverName",
      label: t("Driver"),
      cellModifier: (displayData) => (
        <Typography
          variant="detailsText"
          sx={{
            border: "1px solid #5664D2",
            borderRadius: "1rem",
            padding: "0.3rem",
            color: "#5664D2",
          }}
        >
          {displayData}
        </Typography>
      ),
    },
    {
      key: "registrationPlate",
      label: t("Car"),
      cellModifier: (displayData) => (
        <Typography
          variant="detailsText"
          sx={{
            border: "1px solid #5664D2",
            borderRadius: "1rem",
            padding: "0.3rem",
            color: "#5664D2",
          }}
        >
          {displayData}
        </Typography>
      ),
    },
    {
      key: "sits",
      label: t("Seats"),
      cellModifier: (displayData) => {
        let colorFromSeats = "#7BC67E";
        route?.ClientsOnRoute?.length <= displayData
          ? (colorFromSeats = "#FFB547")
          : route?.ClientsOnRoute?.length === displayData
          ? (colorFromSeats = "#7BC67E")
          : (colorFromSeats = "#F44336");

        return (
          <Typography
            variant="detailsText"
            sx={{
              border: "1px solid #FFB547",
              backgroundColor: "#FFB547",
              borderRadius: "1rem",
              padding: "4px 14px",
              color: "#FFFFFF",
            }}
          >
            {route?.ClientsOnRoute?.length}/{displayData}
          </Typography>
        );
      },
    },
    {
      key: "price",
      label: t("Price"),
      cellModifier: (displayData) => (
        <Typography variant="detailsText">{displayData}€</Typography>
      ),
    },
  ];

  const renderDetails = [
    {
      key: "type",
      label: t("Type"),
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "CIP",
      label: "CIP",
    },
    {
      key: "description",
      label: t("Description"),
    },
    {
      key: "CNPS",
      label: t("CNPS"),
      cellModifier: (displayData) => {
        return displayData === false ? (
          <Typography variant="detailsText">NU</Typography>
        ) : (
          <Typography variant="detailsText">DA</Typography>
        );
      },
    },
    {
      key: "celibatar",
      label: t("Celibatar"),
      cellModifier: (displayData) => {
        return displayData === false ? (
          <Typography variant="detailsText">NU</Typography>
        ) : (
          <Typography variant="detailsText">DA</Typography>
        );
      },
    },
    {
      key: "cityHall",
      label: t("CityHall"),
      cellModifier: (displayData) => {
        return displayData === false ? (
          <Typography variant="detailsText">NU</Typography>
        ) : (
          <Typography variant="detailsText">DA</Typography>
        );
      },
    },
    {
      key: "DepartureFields",
      label: t("Fields"),
      cellModifier: (displayData) => {
        return displayData.map((field) => (
          <Typography
            variant="detailsText"
            key={field.fieldId}
            sx={{
              backgroundColor: field.field.type,
              "& span": {
                mixBlendMode: "difference",
                color: "white",
              },
              borderRadius: "1.5rem",
              padding: "5px 10px",
            }}
          >
            <span>{field.field.name}</span>{" "}
          </Typography>
        ));
      },
    },
    {
      key: "startDate",
      label: t("Contract signed date"),
      cellModifier: (displayData) => {
        if (displayData === null) {
          return <Typography>{t("Contract is not signed yet")}</Typography>;
        } else {
          return (
            <Typography variant="detailsText">
              {dayjs(displayData).format("DD-MM-YYYY")}
            </Typography>
          );
        }
      },
    },
    {
      key: "finalDate",
      label: t("Final date"),
      cellModifier: (displayData) => {
        if (displayData === null) {
          return <Typography>{t("Contract is not signed yet")}</Typography>;
        } else {
          return (
            <Typography variant="detailsText">
              {dayjs(displayData).format("DD-MM-YYYY")}
            </Typography>
          );
        }
      },
    },
    {
      key: "dates",
      label: t("Available dates"),
      cellModifier: (displayData) => {
        return displayData.map((date) => (
          <Typography variant="detailsText">
            {dayjs(date).format("DD-MM-YYYY") + " "}
          </Typography>
        ));
      },
    },
    details.departureReason
      ? {
          key: "departureReason",
          label: "Motiv de anulare",
        }
      : {},
    details.reason
      ? {
          key: "reason",
          label: "Motiv de anulare cursa",
        }
      : {},
  ];

  const renderReturning = [
    {
      key: "name",
      label: t("Name"),
    },
    {
      key: "date",
      label: t("Date"),
      cellModifier: (displayData) => {
        return (
          <Typography variant="detailsText">
            {dayjs(displayData).format("DD MMM YYYY")}
          </Typography>
        );
      },
    },
    {
      key: "reason",
      label: t("Reason"),
    },
  ];

  const renderOthers = [
    {
      key: "name",
      label: t("Name"),
    },
    {
      key: "value",
      label: t("Value"),
    },
  ];

  useEffect(() => {
    getClientOnRoute(id).then((res) => {
      const newRoute = { ...res.route };

      const details = {};

      newRoute.registrationPlate = newRoute.car?.registrationPlate;
      newRoute.driverName =
        newRoute.driver?.firstName + " " + newRoute.driver?.firstName;

      details.type = res.type || "tip nedefinit";
      details.status = res.status;
      details.CIP = res.client.CIP;
      details.startDate = res.contractSign;
      details.finalDate = res.finalDate;
      details.firstPayDate = res.firstPayDate;
      details.secondPayDate = res.secondPayDate;
      details.description = res.description;
      details.DepartureFields = res.DepartureFields;
      details.dates = res.dates;
      details.CNPS = res.CNPS;
      details.celibatar = res.celibatar;
      details.cityHall = res.cityHall;
      details.departureReason = res.departureReason;
      details.reason = res.reason;

      const timeDetails = {};

      timeDetails.clientsOnRoutesId = res.id;
      timeDetails.startDate = res.contractSign;
      timeDetails.finalDate = res.finalDate;
      timeDetails.timeWorked = res.timeFrame.time;

      setClientOnRoutesId(timeDetails);

      setDetails(details);
      setClient(res.client);
      setRoute(newRoute);
    });

    getAllHours(id).then((res) => {
      setHoursWorked(res);
    });

    getDeparturePayments(id).then((res) => setPaymentsDatas(res));
  }, [id]);

  const hoursWorkedData = useMemo(() => {
    if (!Object.keys(getHoursWorked).length) return {};

    const res = getHoursWorked.data.reduce(
      (tableData, info) => ({
        ...tableData,
        [info.week]: info.hours,
      }),
      {}
    );
    res.total = getHoursWorked.total || " ";
    return res;
  }, [getHoursWorked]);

  const hoursWorkedRender = useMemo(() => {
    if (!Object.keys(getHoursWorked).length) return [];

    return getHoursWorked.data.reduce(
      (renderInfo, info) => [
        ...renderInfo,
        {
          key: String(info.week),
          label: t("Week") + " " + info.week,
        },

        {
          key: "total" || " ",
          label: "Total" || " ",
          cellModifier: (displayData) => (
            <Typography style={{ fontSize: "18px", fontWeight: 600 }}>
              {displayData}h
            </Typography>
          ),
        },
      ],
      []
    );
  }, [t, getHoursWorked]);

  useEffect(() => {
    if (!client.id) return;
    getDocsDepartures(client.id).then((docs) => setDocs(docs));
    getReturningInfo(client.id).then((res) => setReturningInfo(res));
    getOthersInfo(client.id).then((others) => setOthers(others));
    getAdditionalDocs(client.id).then((additionalDocs) =>
      setAdditionalDocs(additionalDocs)
    );
  }, [client]);

  useEffect(() => {
    if (!returningEditModal) return;

    const { date, name, reason } = returningEditModal;

    setReturningEditData({
      date,
      name,
      reason,
    });
  }, [returningEditModal]);

  useEffect(() => {
    if (!othersModal) return;

    const { name, value } = othersModal;

    setOthersData({
      name,
      value,
    });
  }, [othersModal]);

  const handleChangeReturning = (e) => {
    setReturningEditData({
      ...returningEditData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeOthers = (e) => {
    setOthersData({
      ...othersData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeHours = (e) => {
    setAddHoursData({
      ...addHoursData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSendHours = () => {
    if (addHoursData) {
      const newData = { ...addHoursData };
      newData.week = parseInt(newData.week);
      newData.hours = parseInt(newData.hours);
      newData.total = parseInt(newData.total);
      newData.actuallyWorked = parseInt(newData.actuallyWorked);
      newData.crateNorm = parseInt(newData.crateNorm);
      newData.crateMade = parseInt(newData.crateMade);
      newData.workNorm = parseInt(newData.workNorm);

      addHours(id, newData, () => {
        getAllHours(id).then((res) => setHoursWorked(res));
      });
      setAddHoursModal(false);
      toast.success("Adaugarea a fost facuta cu succes");
      setAddHoursData("");
    } else {
      console.error("error");
    }
  };

  // labels for the payments table
  const labels = [
    {
      id: "paymentName",
      label: t("Payment Name"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentType",
      label: t("Payment Type"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentDate",
      label: t("Payment Date"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "payment",
      label: t("Amount Paid"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("actions clicked"),
    },
  ];

  const cellModifierPayments = (row, column, value) => {
    if (column.id === "paymentDate") {
      return (
        <Typography variant="tableContent">
          {dayjs(value).format("DD-MM-YYYY")}
        </Typography>
      );
    }
    if (column.id === "payment") {
      return <Typography variant="tableContent">{value} €</Typography>;
    }
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRowDataPayments(row);
              setOpenAddPaymentModal(true);
            }}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(t("Are you sure you want to delete this payment?"), () =>
                deletePayment(row.id, () => {
                  getDeparturePayments(id).then((res) => setPaymentsDatas(res));
                  toast.success(t("Payment deleted succesfully"));
                })
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div style={{ marginBottom: "2.125rem" }}>
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
            fontSize: 24,
          }}
        >
          {t("Departure Registering")}
        </Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Clients")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Departure")}</div>
          </Link>
        </Breadcrumbs>
      </div>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "2rem",
          width: "100%",
          maxWidth: "1080px",
          alignItems: "start",
          marginBottom: "2rem",
          "> *": {
            width: "50%",
            maxWidth: "30rem",
          },
        }}
      >
        {/* Departure Details */}
        <DetailsComponet
          title={t("Details ")}
          tableData={details}
          render={renderDetails}
          showEdit
          onEdit={() => setOpenEditDepartureModal(true)}
          // showAddLastPay
          onAddLastPay={() => setEditBasicInfoModal(details)}
        />
        {/* adding final pay sum */}
        <AddFinalPay
          clientsOnRouteId={id}
          open={editBasicInfoModal}
          setOpen={setEditBasicInfoModal}
          addCallback={() =>
            getClientOnRoute(id).then((res) => {
              const details = {};
              details.type = res.type || "tip nedefinit";
              details.status = res.status;
              details.CIP = res.client.CIP;
              details.startDate = res.contractSign;
              details.finalDate = res.finalDate;
              details.firstPayDate = res.firstPayDate;
              details.secondPayDate = res.secondPayDate;

              setDetails(details);

              setEditBasicInfoModal(false);
              toast.success(t("Final pay succesfully updated"));
            })
          }
        />
        {/* Client Details */}
        <DetailsComponet
          title={t("Client details")}
          showClient
          onShowClient={() =>
            navigate(`/clients/visualize-clients/${client.id}`)
          }
          tableData={client}
          render={renderClient}
        />
        {/* Trip Details */}
        {route.id && (
          <div>
            <DetailsComponet
              title={t("Trip details")}
              tableData={route}
              showTrip
              onShowTrip={() => navigate(`/trips/trip/${route.id}`)}
              render={renderRoute}
              showAdditionalPeriod={route.type === "TUR"}
              onAddAdditionalPeriod={() => setPrelungireModal(true)}
            />
          </div>
        )}
        {/* table for payments */}
        <CustomTable
          title={t("Payments")}
          showAdd
          onAdd={() => {
            setRowDataPayments({});
            setOpenAddPaymentModal(true);
          }}
          tableData={paymentsDatas}
          cellModifier={cellModifierPayments}
          labels={labels}
        />
        {/* modal for adding payments */}
        <AddPaymentOnDeparture
          open={openAddPaymentModal}
          setOpen={setOpenAddPaymentModal}
          id={id}
          rowData={rowDataPayments}
          addCallback={() => {
            getDeparturePayments(id).then((res) => setPaymentsDatas(res));
            toast.success(t("Payment added succesfully"));
          }}
        />
        {/* modal for adding more time on the stay abroad */}
        <PrelungireModal
          open={prelungireModal}
          setOpen={setPrelungireModal}
          clientsOnRoutesId={clientsOnRoutesId}
          addCallback={() =>
            getClientOnRoute(id).then((res) => {
              const details = {};

              details.type = res.type || "tip nedefinit";
              details.status = res.status;
              details.CIP = res.client.CIP;
              details.startDate = res.contractSign;
              details.finalDate = res.finalDate;
              details.firstPayDate = res.firstPayDate;
              details.secondPayDate = res.secondPayDate;
              details.description = res.description;
              details.DeparturesFields = res.DepartureFields;
              details.dates = res.dates;
              details.CNPS = res.CNPS;
              details.celibatar = res.celibatar;
              details.cityHall = res.cityHall;

              setDetails(details);

              setPrelungireModal(false);
              toast.success(t("Period succesfully updated"));
            })
          }
        />
        {/* Hours Worked */}
        {route.id && route.type === "TUR" && (
          <DetailsComponet
            title={t("Hours")}
            tableData={hoursWorkedData}
            render={hoursWorkedRender}
            showAdd
            onAdd={() => setAddHoursModal(true)}
            showEdit
            onEdit={() => setEditHoursModal(true)}
          />
        )}
        {/* Returning ??? */}
        {/* <DetailsComponet
          title="Returning"
          tableData={returningInfo[0]}
          showEdit
          onEdit={() => setReturningEditModal(returningInfo[0])}
          render={renderReturning}
        /> */}
        {/* Altele Details */}
        {/* {route.type === "TUR" && (
          <DetailsComponet
            title="Altele"
            showEdit
            onEdit={() => setOthersModal(others[0])}
            tableData={
              others.length < 0
                ? ((others.name = "Standard"), (others.value = 999))
                : others[0]
            }
            render={renderOthers}
          />
        )} */}
        {/* Docs */} {/* Additional Docs */}
        {route.type === "TUR" && (
          <DocumentDetails
            docs={docs}
            setDocs={setDocs}
            getDocs={() => getDocsDepartures(client.id)}
            deleteDoc={deleteDocDeparture}
            fileUrl="/clientsOnRoute/file/"
            setDocumentModal={setDocumentModal}
          />
        )}
        {route.type === "TUR" && (
          <DocumentDetails
            title={t("Additional documents")}
            docs={additionalDocs}
            setDocs={setAdditionalDocs}
            getDocs={() => getAdditionalDocs(client.id)}
            deleteDoc={deleteAdditionalDocs}
            fileUrl="/clientsOnRoute/additionalFile/"
            setDocumentModal={setAdditionalDocumentModal}
          />
        )}
        {/* Docs Modal */}
        <DocumentModal
          open={documentModal}
          setOpen={setDocumentModal}
          handleSubmit={(formData) =>
            uploadDocDeparture(client.id, formData, () => {
              getDocsDepartures(client.id).then((docs) => {
                setDocs(docs);
                setDocumentModal(false);
                toast.success("File has been uploaded!");
              });
            })
          }
        />
        {/* Additional Docs Modal */}
        <DocumentModal
          open={additionalDocumentModal}
          setOpen={setAdditionalDocumentModal}
          handleSubmit={(formData) =>
            uploadAdditionalDocs(client.id, formData, () => {
              getAdditionalDocs(client.id).then((docs) => {
                setAdditionalDocs(docs);
                setAdditionalDocumentModal(false);
                toast.success("File has been uploaded!");
              });
            })
          }
        />
        {/* Altele Modal */}
        <CustomDialog
          open={othersModal}
          button1="Editeaza"
          button2="Renunta"
          title="Editeaza"
          handleClose={() => setOthersModal(false)}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "1.5rem",
              height: "100%",
              // width: "22rem",
            }}
          >
            <Grid container justifyContent={"space-between"} spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="name"
                  value={othersData.name}
                  onChange={handleChangeOthers}
                  id="outlined-required"
                  label="Nume"
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="value"
                  value={othersData.value}
                  onChange={handleChangeOthers}
                  id="outlined-required"
                  label="Valoare"
                />
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>
        {/* Add Working Hours */}
        <CustomDialog
          open={addHoursModal}
          handleClose={() => setAddHoursModal(false)}
          button1={t("Adauga")}
          button2={t("Anuleaza")}
          title={t("Add hours worked")}
          onClickButton1={() => handleSendHours()}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "1.5rem",
              height: "100%",
              width: "22rem",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  name="week"
                  value={addHoursData?.week}
                  onChange={handleChangeHours}
                  id="outlined-required"
                  label={t("Number of weeks")}
                />

                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  name="hours"
                  value={addHoursData?.hours}
                  onChange={handleChangeHours}
                  id="outlined-required"
                  label={t("Hours worked daily")}
                />
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>
        {/* Edit Working Hours */}
        <EditHoursModal
          open={editHoursModal}
          setOpen={setEditHoursModal}
          info={getHoursWorked.data ?? []}
          clientOnRouteId={id}
          editCallback={async () => {
            setEditHoursModal(false);
            try {
              const newWeek = await getAllHours(id);
              setHoursWorked(newWeek);
              toast.success("Saptamana a fost modificata!");
            } catch (error) {
              console.error(error);
            }
          }}
          deleteCallback={async (hoursId) => {
            setEditHoursModal(false);
            // setHoursWorked(getHoursWorked.filter((i) => i.id !== hoursId));
            try {
              const newWeek = await getAllHours(id);
              setHoursWorked(newWeek);
              toast.success("Saptamana a fost ștearsă!");
            } catch (error) {
              console.error(error);
            }
          }}
        />
        {/* Returniung Modal */}
        <CustomDialog
          open={returningEditModal}
          handleClose={() => setReturningEditModal(false)}
          title="Editare"
          button1={"OK"}
          button2="Renunta"
          onClickButton1={() =>
            editReturningInfo(returningInfo[0].id, returningEditData, () => {
              getReturningInfo(client.id).then((res) => setReturningInfo(res));
              setReturningEditModal(false);
              toast.success("Modificare facuta");
            })
          }
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "1.5rem",
              height: "100%",
              width: "22rem",
            }}
          >
            <Grid container justifyContent={"space-between"} spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Data"
                    value={returningEditData.date}
                    onChange={(value) =>
                      handleChangeReturning({
                        target: { name: "date", value },
                      })
                    }
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        id="outlined-required"
                        autoComplete={"off"}
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  value={returningEditData.name}
                  onChange={handleChangeReturning}
                  id="outlined-required"
                  name="name"
                  label="Nume"
                />
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  required
                  name="reason"
                  value={returningEditData.reason}
                  onChange={handleChangeReturning}
                  id="outlined-required"
                  label="Motiv"
                />
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>
      </Box>
      {/* editing the departure data */}
      <EditDepartureModal
        open={openEditDepartureModal}
        setOpen={setOpenEditDepartureModal}
        details={details}
        id={id}
        addCallback={() =>
          getClientOnRoute(id).then((res) => {
            const details = {};
            details.type = res.type || "tip nedefinit";
            details.status = res.status;
            details.CIP = res.client.CIP;
            details.startDate = res.contractSign;
            details.finalDate = res.finalDate;
            details.firstPayDate = res.firstPayDate;
            details.secondPayDate = res.secondPayDate;
            details.description = res.description;
            details.DepartureFields = res.DepartureFields;
            details.dates = res.dates;
            details.CNPS = res.CNPS;
            details.celibatar = res.celibatar;
            details.cityHall = res.cityHall;
            setDetails(details);
            setOpenEditDepartureModal(false);
            toast.success(t("The departure was edited successfully"));
          })
        }
      />

      <div style={{ width: "100%" }}>
        <Comments type="DEPARTURE" id={id} />
      </div>
    </div>
  );
}

export default DepartureRegistering
