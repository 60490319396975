import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import styles from "./Comments.module.scss";

// mui
import {
  Box,
  Typography,
  Divider,
  TextField,
  Fab,
  Avatar,
  InputAdornment,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "../../theme";
import { useUser } from "hooks/useUser";

import { getAllComments, addComment } from "api/API";

const snapSensibility = 32;

function Comments({ type, id }) {
  const { t } = useTranslation();
  const ref = useRef();
  const { user } = useUser();
  const [file, setFile] = useState(null);
  const [comment, setComment] = useState("");
  const [messages, setMessages] = useState([]);
  const [lastMessageFetch, setLastMessageFetch] = useState(0);

  /**
   * Initial Comment Fetch
   */
  useEffect(() => {
    getAllComments(type, id).then((messages) => setMessages(messages));
  }, [type]);

  /**
   * Every Comments update's date is marked
   */
  useEffect(() => {
    const date = new Date();
    setLastMessageFetch(date.getTime());
  }, [messages]);

  /**
   * Once 5 seconds refetch all comments
   */
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      if (now - lastMessageFetch >= 1000 * 5) {
        getAllComments(type, id).then((messages) => setMessages(messages));
      }
    }, 1000 * 5);

    return () => clearInterval(interval);
  }, [type, lastMessageFetch]);

  const removeFile = () => {
    if (file) {
      setFile(null);
    }
  };

  const handleSubmitComment = () => {
    if (!comment.length) return;

    addComment(id, type, user.id, comment, () => {
      getAllComments(type, id).then((res) => setMessages(res));
    });
    setComment("");
  };

  const [snapBottom, setSnapBottom] = useState(true);
  const containerRef = useRef();

  useEffect(() => {
    if (!snapBottom) return;

    const sHeight = containerRef.current.scrollHeight;
    const sTop = containerRef.current.scrollTop;

    if (Math.abs(sTop - sHeight) > 288.5) {
      containerRef.current.scrollTop = sHeight;
    }
  }, [messages, snapBottom]);

  useEffect(() => {
    const handleScroll = (e) => {
      const sTop = e.target.scrollTop + e.target.clientHeight;
      const sHeight = e.target.scrollHeight;

      if (Math.floor(Math.abs(sTop - sHeight)) < snapSensibility) {
        setSnapBottom(true);
        return;
      }

      setSnapBottom(false);
    };

    const c = containerRef.current;
    c.addEventListener("scroll", handleScroll);

    return () => {
      c.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sortedMessages = messages.sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1
  );

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        ref={ref}
        sx={{
          backgroundColor: "#FFFFFF",
          marginTop: "1rem",
          borderRadius: "1rem",
          position: "relative",
          boxShadow:
            "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Typography
          variant="h6"
          sx={{ paddingTop: "1rem", marginBottom: "1rem", marginLeft: "1rem" }}
        >
          {t("Comments")}
        </Typography>
        <Divider />

        <div
          onClick={() => setSnapBottom(true)}
          className={`${styles.goBottom} ${!snapBottom && styles.visible}`}
        >
          <>go to latest</>
          <ArrowDownwardIcon sx={{ width: "14px" }} />
        </div>

        <Box
          sx={{
            height: "20rem",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
          ref={containerRef}
        >
          {sortedMessages.map((message, messageIndex) => {
            /* 
                Task is to see if 2 consecutive messages are on different dates
                If so, we have to separate them visually
            */
            let daySeparator = null;
            if (messageIndex > 0) {
              let prevMessageDate = new Date(
                sortedMessages[messageIndex - 1].createdAt
              ).setMinutes(0, 0, 0);

              let messageDate = new Date(message.createdAt).setMinutes(0, 0, 0);

              if (messageDate !== prevMessageDate) daySeparator = messageDate;
            }

            return (
              <React.Fragment key={message.id}>
                {daySeparator !== null && <DaySeparator date={daySeparator} />}

                <IndividualComment
                  text={message.comments.comment}
                  sendAt={message.createdAt}
                  isCurrentUserMessage={user.id === message.comments.user.id}
                  user={message.comments.user.name}
                  profilePic={message.profilePic}
                />
              </React.Fragment>
            );
          })}
        </Box>

        {/* Textbox Section */}
        <Box
          sx={{
            padding: "10px",
          }}
        >
          <TextField
            fullWidth
            placeholder={t("Write a comment")}
            variant="outlined"
            value={comment}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmitComment();
              }
            }}
            onChange={(e) => setComment(e.target.value)}
            sx={{
              fieldset: { borderRadius: "1rem" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    display: "flex",
                    marginLeft: "1rem",
                  }}
                >
                  <input
                    type="file"
                    name="zip"
                    id="zip"
                    accept=".zip, .rar, .7zip, .png, .jpeg, .jpg, .pdf"
                    onChange={(e) => setFile(e.target.files)}
                    style={{
                      display: "none",
                    }}
                  />
                  {file ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: "0.8rem",
                          fontStyle: "italic",
                          marginRight: "0.4rem",
                        }}
                      >
                        {file?.[0].name}
                      </Typography>
                      <Fab
                        component="span"
                        size="small"
                        color="primary"
                        onClick={removeFile}
                      >
                        <ClearRoundedIcon fontSize="small" />
                      </Fab>
                    </>
                  ) : (
                    <label htmlFor="zip">
                      <Fab component="span" size="small" color="primary">
                        <AttachFileIcon fontSize="small" />
                      </Fab>
                    </label>
                  )}
                  <Fab
                    size="small"
                    color="primary"
                    style={{ marginLeft: "0.2rem" }}
                    disabled={comment || file ? false : true}
                    onClick={() => handleSubmitComment()}
                  >
                    <SendIcon />
                  </Fab>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function IndividualComment({
  text,
  sendAt,
  user,
  isCurrentUserMessage,
  profilePic,
}) {
  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          backgroundColor: "rgba(86, 100, 210, 0.08)",
          borderRadius: "2rem",
          margin: "1rem",
          padding: "0.5rem",
          maxWidth: "70%",
          overflowWrap: "anywhere",
          alignSelf: isCurrentUserMessage ? "flex-end" : "flex-start",
          alignContent: "flex-start",
        }}
      >
        <Box
          sx={{
            padding: "0.5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: isCurrentUserMessage ? "flex-end" : "flex-start",
          }}
        >
          {/* outer */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isCurrentUserMessage ? "row-reverse" : "row",
            }}
          >
            {/* poza */}
            <Box
              sx={{
                display: "flex",
                height: "100%",
                width: "auto",
              }}
            >
              <Avatar>{profilePic}</Avatar>
            </Box>
            {/* username data */}
            <Box
              sx={{
                // border: "1px solid red",
                marginLeft: isCurrentUserMessage ? "0rem" : "0.5rem",
                marginRight: isCurrentUserMessage ? "0.5rem" : "0rem",
                marginBottom: "0.1rem",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  textAlign: isCurrentUserMessage ? "end" : "start",
                }}
              >
                {user}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "10px",
                  color: "#253858",
                  textAlign: isCurrentUserMessage ? "end" : "start",
                }}
              >
                {dayjs(sendAt).format("DD MMM YYYY, HH:mm")}
              </Typography>
            </Box>
          </Box>

          <Typography
            sx={{
              fontWeight: "400",
              color: "rgba(66, 82, 110, 0.86)",
              paddingTop: "10px",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function DaySeparator({ date }) {
  return (
    <Box
      sx={{
        padding: ".5rem",
        background: "#f4f4f4",
        borderBlock: "1px solid rgba(0, 0, 0, .12)",
        textAlign: "center",
      }}
    >
      <Typography>{dayjs(date).format("DD MMM YYYY")}</Typography>
    </Box>
  );
}

export default Comments;
