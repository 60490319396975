import axios from 'api/axios'

export const getClientOnRoute = async (id) => {
    try {
        const response = await axios.get('/clientsOnRoute/' + id)
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const identificationDocuments = async (
    clientsOnRoutesId,
    CNPS,
    cityHall,
    celibatar,
    callback = () => {},
) => {
    try {
        await axios.patch(
            `/clientsOnRoute/update/departure/tags/${clientsOnRoutesId}?CNPS=${CNPS}&cityHall=${cityHall}&celibatar=${celibatar}`,
        )
        return { ok: true }
    } catch (error) {
        return { ok: false, error }
    }
}

export const deleteDocDeparture = async (docId, callback = () => {}) => {
    try {
        await axios.delete(`/clientsOnRoute/file/${docId}`)
        callback()
    } catch (error) {
        console.error(error)
    }
}

export const uploadDocDeparture = async (
    clientId,
    formData,
    callback = () => {},
) => {
    try {
        const response = await axios.post('/clientsOnRoute/file', formData, {
            params: {
                clientId,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        callback(response.data)
    } catch (error) {
        console.error(error)
    }
}

export const getDocsDepartures = async (clientId) => {
    try {
        const response = await axios.get('/clientsOnRoute/file', {
            params: {
                clientId,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const deleteAdditionalDocs = async (docId, callback = () => {}) => {
    try {
        await axios.delete(`/clientsOnRoute/additionalFile/${docId}`)
        callback()
    } catch (error) {
        console.error(error)
    }
}

export const uploadAdditionalDocs = async (
    clientId,
    formData,
    callback = () => {},
) => {
    try {
        const response = await axios.post(
            '/clientsOnRoute/additionalFile',
            formData,
            {
                params: {
                    clientId,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
        callback(response.data)
    } catch (error) {
        console.error(error)
    }
}

export const uploadTripDocument = async (
    type,
    routeId,
    formData,
    callback = () => {},
) => {
    try {
        const response = await axios.post('/route/file', formData, {
            params: {
                type,
                routeId,
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        callback(response.data)
    } catch (error) {
        console.error(error)
    }
}

export const getTripDocs = async (routeId) => {
    try {
        const response = await axios.get('/route/files/' + routeId)
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const returnTripDoc = async (docId) => {
    try {
        const res = await axios.get(`/route/file/${docId}`, {
            responseType: 'arraybuffer',
        })
        return res
    } catch (error) {
        console.error(error)
    }
}

export const getAdditionalDocs = async (clientId) => {
    try {
        const response = await axios.get('/clientsOnRoute/additionalFile', {
            params: {
                clientId,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const getReturningInfo = async (clientId) => {
    try {
        const response = await axios.get('/return', {
            params: {
                clientId,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const editReturningInfo = async (
    returnId,
    returnData,
    callback = () => {},
) => {
    try {
        const response = await axios.patch(`/return/${returnId}`, returnData)
        callback(response.data)
    } catch (error) {
        console.error(error)
    }
}

export const getOthersInfo = async (clientId) => {
    try {
        const response = await axios.get('/others', {
            params: {
                clientId,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const editHours = async (clientId, data, callback = () => {}) => {
    try {
        try {
            for (const info of data) {
                await axios.put(`/hours/${info.id}`, {
                    week: info.week,
                    hours: info.hours,
                })
            }

            callback()
        } catch (error) {
            console.error(error)
        }
    } catch (error) {
        console.error(error)
    }
}

export const editOthers = async (clientId, data, callback = () => {}) => {
    try {
        try {
            for (const info of data) {
                await axios.patch(`/others/${info.id}`, {
                    name: info.name,
                    value: info.value,
                })
            }

            callback()
        } catch (error) {
            console.error(error)
        }
    } catch (error) {
        console.error(error)
    }
}

export const addHours = async (
    ClientsOnRoutesId,
    data,
    callback = () => {},
) => {
    try {
        const response = await axios.post('/hours', data, {
            params: {
                ClientsOnRoutesId,
            },
        })
        callback(response.data)
    } catch (error) {
        console.error(error)
    }
}

export const getAllHours = async (ClientsOnRoutesId) => {
    try {
        const response = await axios.get(
            `/hours/clientOnRoute/${ClientsOnRoutesId}`,
        )
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const deleteHours = async (hoursId, callback = () => {}) => {
    try {
        await axios.delete(`/hours/${hoursId}`)
        callback()
    } catch (error) {
        console.error(error)
    }
}

export const deleteTrip = async (routeId, callback = () => {}) => {
    try {
        await axios.delete(`/route/${routeId}`)
        callback()
    } catch (error) {
        console.error(error)
    }
}

/**
 * Functia adauga un client pe Trip
 * La tabelul de inscrieri
 */
export const addClientOnTrip = async (
    clientIdList = [],
    routeId,

    callback = () => {},
) => {
    try {
        await axios.put(
            `/clientsOnRoute/departure/addRoute?routeId=${routeId}`,
            {
                clientsIds: clientIdList,
            },
        )
        callback()
    } catch (error) {
        console.error(error)
    }
}

export const updateArrivedTrip = async (routeId, callback = () => {}) => {
    try {
        await axios.put(`route/arrived/${routeId}`)
        callback()
    } catch (error) {
        console.error(error)
    }
}

export const getClientsOnTrip = async (
    tripId,
    perPage = 9999,
    currentPage = 0,
    pagesToLoad = 1,
) => {
    try {
        const response = await axios.get(`/clientsOnRoute?id=${tripId}`, {
            params: {
                perPage,
                currentPage,
                pagesToLoad,
            },
        })
        return response.data
    } catch (error) {
        console.error(error)
        return []
    }
}

export const getClientsWithoutRoute = async (perPage, currentPage, pagesToLoad) => {
  try {
    const response = await axios.get("/clientsOnRoute/allWithoutRoute", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * functie pentru a lua toti clientii de pe tur/retur
 *
 */

export const getClientsOnRouteByType = async (type) => {
  try {
    const response = await axios.get("/clientsOnRoute/allByType", {
      params: {
        type,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDepartureFields = async (departureId) => {
  try {
    const response = await axios.get(
      "/clientsOnRoute/departure/departureFields",
      {
        params: {
          departureId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllDepartureFields = async () => {
  try {
    const response = await axios.get(
      "/clientsOnRoute/allDeparture/departureFields"
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDepartureListArrivals = async (
  perPage,
  currentPage,
  pagesToLoad
) => {
  try {
    const response = await axios.get("/clientsOnRoute/departures/allArrived", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const updateReturn = async (
  routeId,
  clientListRetur = [],
  callback = () => {}
) => {
  try {
    const response = await axios.put(`clientsOnRoute/updateReturn/${routeId}`, {
      ids: clientListRetur,
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const updateContractSign = async (
  clientsOnRoutesId,
  contractSign,
  finalDate,
  callback = () => {}
) => {
  try {
    const response = await axios.patch(
      `clientsOnRoute/update/contractSign/${clientsOnRoutesId}?contractSign=${contractSign}&finalDate=${finalDate}`
    );
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const updatePeriod = async (
  clientsOnRoutesId,
  timeId,
  finalDate,
  callback = () => {}
) => {
  try {
    let response;

    if (timeId === "") {
      response = await axios.patch(
        `/clientsOnRoute/update/periodUpdate/${clientsOnRoutesId}?finalDate=${finalDate}`
      );
      callback(response.data);
    } else {
      response = await axios.patch(
        `/clientsOnRoute/update/periodUpdate/${clientsOnRoutesId}?timeId=${timeId}&finalDate=${finalDate}`
      );
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const liquidateClient = async (
  liquidated,
  eligibleTax,
  clientsOnRoutesId,
  callback = () => {}
) => {
  try {
    const response = await axios.patch(
      `/clientsOnRoute/update/liquidated/${clientsOnRoutesId}?liquidated=${liquidated}&eligibleTax=${eligibleTax}`
    );
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const finishedTrip = async (routeId, callback = () => {}) => {
  try {
    const response = await axios.put(`/route/finished/${routeId}`);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const getClientsOnRoute = async (
  id,
  perPage = 9999,
  pagesToLoad = 1,
  currentPage = 0,
  callback = () => {}
) => {
  try {
    const response = await axios.get(
      `clientsOnRoute?id=${id}&perPage=9999&pagesToLoad=1&currentPage=0`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findClientOnLists = async (text, type) => {
  try {
    const response = await axios.get("/clientsOnRoute/search/departure", {
      params: {
        text,
        type,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
