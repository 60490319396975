import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTable } from "../../../lib";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Typography, Breadcrumbs, Link, Chip } from "@mui/material";
import { getClientsWithoutRoute, findClientOnLists } from "api/tripApi";
import { getPaymentStatusColor, getStatusColor } from "utils/functions";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import { useTranslation } from "react-i18next";

import { CustomTablePagination } from "lib";

import dayjs from "dayjs";

function DepartureList() {
  const navigate = useNavigate();
  const [departures, setDepartures] = useState([]);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [loadingDepartureList, setLoadingDepartureList] = useState(true);

  const labels = [
    {
      id: "client",
      label: t("Client"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "phone",
      label: t("Phone Number"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "clientField",
      label: t("Field"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "period",
      label: t("Time period"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "cip",
      label: t("CIP"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentStatus",
      label: t("Payment status"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "status",
      label: t("Status"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  // useEffect(() => {
  //   getClientsWithoutRoute().then((res) => {
  //     setDepartures(res);
  //     setLoadingDepartureList(false);
  //   });
  // }, []);

  const span = (number) => {
    return (
      <p>
        Probleme
        <span style={{ fontWeight: 800, color: "#E91E63" }}>{number}</span>
      </p>
    );
  };

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`./` + row.id)}
        >
          <ArrowForwardIcon color="primary" />
        </span>
      );
    }
    if (column.id === "field") {
      return <Typography variant="tableContent">{row.field?.name}</Typography>;
    }
    if (column.id === "clientField") {
      return row.DepartureFields.map((fld) => (
        <div
          key={fld.field.id}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              backgroundColor: fld.field.type,
              "& span": {
                mixBlendMode: "difference",
                color: "white",
              },
              borderRadius: "1rem",
              padding: "4px 14px",
              margin: "2px",
            }}
          >
            <span>{fld.field.name}</span>
          </Typography>
        </div>
      ));
    }
    if (column.id === "paymentStatus") {
      const colorFromPayment = getPaymentStatusColor(
        row.totalPayed,
        row.route?.price
      );
      let priceOfTheRoute = row.route?.price;
      if (priceOfTheRoute === undefined)
        priceOfTheRoute = t("Trip not established yet");
      else priceOfTheRoute = row.route?.price;

      return (
        <span>
          <Typography
            variant="tableContent"
            sx={{
              backgroundColor: colorFromPayment,
              color: "white",
              padding: "5px 14px",
              borderRadius: "999px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* {row.paymentStatus + "/" + row.route?.price} */}
            {`${row.totalPayed}/${priceOfTheRoute}`}
          </Typography>
        </span>
      );
    }
    if (column.id === "status") {
      let colorFromStatus = getStatusColor(row.status);

      return (
        <span
          style={{
            backgroundColor: colorFromStatus,
            color: "white",
            padding: "5px 14px",
            borderRadius: "16px",
          }}
        >
          {value}
        </span>
      );
    }
    if (column.id === "client") {
      return (
        <Typography variant="tableContent">
          {row.client.firstName} {row.client.lastName}
        </Typography>
      );
    }
    if (column.id === "phone") {
      return <Typography variant="tableContent">{row.client.phone}</Typography>;
    }
    if (column.id === "cip") {
      return <Typography variant="tableContent">{row.client.CIP}</Typography>;
    }
    if (column.id === "period") {
      return (
        <Typography variant="tableContent">
          {dayjs(row.route?.departure).format("DD/MM/YYYY")}
        </Typography>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Departure list")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")} </div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Clients")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Departure list")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTablePagination
        title={t("Departure list")}
        showSearchbar
        labels={labels}
        loading={loadingDepartureList}
        setLoading={setLoadingDepartureList}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        tableData={departures}
        cellModifier={cellModifier}
        getterFunction={getClientsWithoutRoute}
        setterFunction={setDepartures}
        searchFunction={findClientOnLists}
        additionalId={"TUR"}
      />

      {/* <CustomTable
        title={t("Departure list")}
        showSearchbar
        // showFilters
        onFilters={() => setOpenFilterModal(true)}
        onAdd={() => console.log("add clicked")}
        labels={labels}
        onSearch={(e) => setQuery(e.target.value)}
        loading={loadingDepartureList}
        tableData={departures
          .map((row) => ({
            ...row,
            info: (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "10px",
                  gap: "10px",
                }}
              >
                {row.client.Problems?.length > 0 ? (
                  <Chip
                    key={row.clientID}
                    icon={
                      <PriorityHighIcon
                        color="#FFFFFF"
                        sx={{
                          backgroundColor: "#E91E63",
                          borderRadius: "1rem",
                        }}
                        style={{
                          color: "white",
                        }}
                      />
                    }
                    label={span(row.client.Problems.length)}
                    sx={{
                      border: "1px solid #E91E63",
                      background: "#FFFFFF",
                      color: "#E91E63",
                    }}
                    onClick={() =>
                      navigate(`/clients/visualize-clients/` + row.clientId)
                    }
                  />
                ) : null}
                {row.client.TagOnClient.map((tag) => (
                  <Chip
                    key={tag.id}
                    label={tag.tag.name}
                    sx={{ background: tag.tag.type }}
                  />
                ))}
              </div>
            ),
          }))
          .filter((trip) => {
            if (
              query === "" ||
              trip.client.lastName
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              trip.client.firstName
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              trip.field?.name.toLowerCase().includes(query.toLowerCase())
            )
              return true;
          })}
        cellModifier={cellModifier}
      /> */}
    </div>
  );
}

export default DepartureList;
