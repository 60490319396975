import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "lib";
import { Box, Grid, TextField } from "@mui/material";
import { addFinalPay } from "api/API";
const AddFinalPay = ({ open, setOpen, clientsOnRouteId, addCallback }) => {
  const { t } = useTranslation();
  const [finalPay, setFinalPay] = useState("");

  return (
    <CustomDialog
      handleClose={() => setOpen(false)}
      open={Boolean(open)}
      title={t("Add last pay")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() => {
        addFinalPay(clientsOnRouteId, Number(finalPay), addCallback);
        setFinalPay("");
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "30rem",
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          spacing={2}
          style={{ marginBottom: "20px" }}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Payment status")}
              value={finalPay}
              name="paymentStatus"
              onChange={(e) => setFinalPay(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default AddFinalPay;
