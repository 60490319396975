import React, { useEffect, useState } from "react";
import CustomDialog from "lib/components/Modals/CustomModal";

import { Box, Grid, TextField } from "@mui/material";
import { editRovinieta, deleteRovinieta } from "api/vehicleApi";
import DeleteIcon from "@mui/icons-material/Delete";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const EditRovinietaModal = ({
  vehicleId,
  open,
  setOpen,
  info,
  editCallback,
  deleteCallback,
}) => {
  const [data, setData] = useState([...info]);

  const handleChange = (e, index) => {
    const newData = [...data];
    newData[index][e.target.name] = e.target.value;
    setData(newData);
  };

  useEffect(() => {
    if (!open) return;

    setData(info);
  }, [open, info]);

  return (
    <CustomDialog
      open={open}
      handleClose={() => setOpen(false)}
      button1="Salvează"
      button2="Renunță"
      title="Editează informații"
      onClickButton1={() => editRovinieta(vehicleId, data, editCallback)}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "30rem",
        }}
      >
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {data.map((info, infoIndex) => (
            <Grid key={info.id} item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  autoComplete={"off"}
                  name="title"
                  value={info.title}
                  onChange={(e) => handleChange(e, infoIndex)}
                  id="outlined-required"
                  label="Titlu"
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Asigurare"
                    value={info.expire}
                    onChange={(value) => {
                      handleChange(
                        {
                          target: { name: "expire", value },
                        },
                        infoIndex
                      );
                    }}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                          width: "30rem",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <DeleteIcon
                  color="errorCustom"
                  sx={{
                    cursor: "pointer",
                    transition: "transform .2s ease",
                    ":hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => deleteRovinieta(info.id, deleteCallback)}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default EditRovinietaModal;
