import axios from "api/axios";

export const getAllClientsDocs = async (perPage, currentPage, pagesToLoad) => {
  try {
    const response = await axios.get("/clients/allDocs/ClientDocs", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findInDocumentsByText = async (text) => {
  try {
    const response = await axios.get("/clients/search/allDocs", {
      params: {
        text,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
