import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getAllPermissions } from "api/API";

const Roles = ({ checkedPermission, setCheckedPermissions }) => {
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    getAllPermissions().then((permissions) => setPermissions(permissions));
  }, []);

  const handleCheckChange = (id) => {
    if (checkedPermission.some((p) => p.permissionId === id)) {
      setCheckedPermissions(
        checkedPermission.filter((p) => p.permissionId !== id)
      );
    } else {
      setCheckedPermissions([...checkedPermission, { permissionId: id }]);
    }
  };

  return (
    <Grid container>
      <Grid item sx={{ width: "100%" }}>
        {permissions.map((permission) => (
          <div
            key={permission.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0.5rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography>{t(permission.name)}</Typography>
            </Box>

            <Checkbox onChange={(e) => handleCheckChange(permission.id)} />
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default Roles;
