import axios from "api/axios";
import { handleError } from "utils/functions";

export const addDestination = async (destinationData, callback = () => {}) => {
  try {
    await axios.post("/destinations", destinationData);
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const editDestination = async (
  destinationId,
  destinationData,
  callback = () => {}
) => {
  try {
    await axios.put(`/destinations/${destinationId}`, destinationData);
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const deleteDestination = async (destinationId, callback = () => {}) => {
  try {
    await axios.delete(`/destinations/${destinationId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const getCountries = async () => {
  try {
    const response = await axios.get("/destinations/destination/countries");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getStates = async (countryId) => {
  try {
    const response = await axios.get("/destinations/destination/states", {
      params: {
        countryId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getCities = async (countryId, stateId) => {
  try {
    const response = await axios.get("/destinations/destination/cities", {
      params: {
        countryId,
        stateId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
