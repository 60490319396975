import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomTable, CustomDialog } from "lib";
import {
  Typography,
  Breadcrumbs,
  Link,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";
import { useUser } from "hooks/useUser";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";


import {
  getAllDestinations,
  addDestination,
  editDestination,
  deleteDestination,
  getCountries,
  getStates,
  getCities,
} from "api/API";


import DeleteIcon from "@mui/icons-material/Delete";

function Destinations() {
  const confirm = useConfirm();
  const { t } = useTranslation();
  const { can } = useUser();

  const [destinations, setDestinations] = useState([]);
  const [destinationModal, setDestinationModal] = useState(false);
  const [destinationAddData, setDestinationAddData] = useState({});
  const [loadingDestinations, setLoadingDestinations] = useState(true);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [query, setQuery] = useState("");

  const canViewDestinations = can(18);
  const canAlterDestinations = can(19);

  useEffect(() => {
    getAllDestinations().then((destinations) => {
      setDestinations(destinations);
      setLoadingDestinations(false);
    });
    getCountries().then((countries) => setCountries(countries));
  }, []);

  const handleChange = async (e) => {
    setDestinationAddData({
      ...destinationAddData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "countryId") await getAndSetStates(e.target.value);
    if (e.target.name === "stateId")
      await getAndSetCities(destinationAddData.countryId, e.target.value);
  };

  const labels = [
    {
      id: "country",
      label: t("Country"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "states",
      label: t("City"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "city",
      label: t("Location"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "location",
      label: t("Pick up location"),
      minWidth: 10,
      onClick: () => {},
    },
    ...(canAlterDestinations
      ? [
          {
            id: "actions",
            label: t("Actions"),
            minWidth: 10,
            onClick: () => {},
          },
        ]
      : []),
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setDestinationModal(row)}
          >
            <ArrowForwardIcon color="primary" />
          </span> 
          &nbsp; &nbsp; &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(
                t("Are you sure you want to delete this destination?"),
                () =>
                  deleteDestination(row.id, () =>
                    getAllDestinations().then((destinations) => {
                      setDestinations(destinations);
                      toast.success(t("Destination has been deleted!"));
                    })
                  )
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }

    if (["country", "states", "city"].indexOf(column.id) > -1) {
      return (
        <Typography variant="tableContent"> {row[column.id]?.name}</Typography>
      );
    }

    return <Typography variant={"tableContent"}> {value}</Typography>;
  };

  //   Set modal values on open/edit destination
  useEffect(() => {
    if (!destinationModal) return;

    if (destinationModal === true) {
      setDestinationAddData({
        countryId: "",
        stateId: "",
        cityId: "",
      });
      return;
    }

    const { countryId, cityId, stateId } = destinationModal;
    setDestinationAddData({
      countryId,
      stateId,
      cityId,
    });
  }, [destinationModal]);

  //   Country / States / Cities
  const getAndSetStates = async (countryId) => {
    getStates(countryId).then((states) => setStates(states));
  };

  const getAndSetCities = async (countryId, stateId) => {
    getCities(countryId, stateId).then((cities) => setCities(cities));
  };

  return (
    <div>
      <div style={{ marginBottom: "2rem" }}>
        <Typography variant="pageTitle">{t("Destinations")}</Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Destinations")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTable
        showAdd={canAlterDestinations}
        showSearchbar
        title={t("Destinations")}
        labels={labels}
        loading={loadingDestinations}
        tableData={destinations.filter((destination) => {
          if (
            query === "" ||
            destination.city.name.toLowerCase().includes(query.toLowerCase()) ||
            destination.country.name
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            destination.states.name.toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }

          return false;
        })}
        cellModifier={cellModifier}
        onAdd={() => setDestinationModal(true)}
        onSearch={(e) => setQuery(e.target.value)}
      />

      {/* ---[Add/Edit Destination Modal]--- */}
      <CustomDialog
        title={
          typeof destinationModal === "boolean"
            ? t("Add destination")
            : t("Edit destination")
        }
        open={Boolean(destinationModal)}
        handleClose={() => setDestinationModal(false)}
        button1={typeof destinationModal === "boolean" ? t("Add") : t("Edit")}
        button2={t("Quit")}
        onClickButton1={() =>
          typeof destinationModal === "boolean"
            ? addDestination(destinationAddData, () => {
                getAllDestinations().then((destinations) => {
                  setDestinations(destinations);
                  setDestinationModal(false);
                  toast.success(t("Destination has been added!"));
                });
              })
            : editDestination(destinationModal.id, destinationAddData, () => {
                getAllDestinations().then((destinations) => {
                  setDestinations(destinations);
                  setDestinationModal(false);
                  toast.success(t("Destination has been modified!"));
                });
              })
        }
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              select
              label={t("Country")}
              value={destinationAddData.countryId}
              onChange={handleChange}
              name="countryId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              select
              label={t("State")}
              value={destinationAddData.stateId}
              onChange={handleChange}
              name="stateId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {states.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              select
              label={t("Cities")}
              value={destinationAddData.cityId}
              onChange={handleChange}
              name="cityId"
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
            >
              {cities.map((city) => (
                <MenuItem key={city.id} value={city.id}>
                  {city.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Pick up location")}
              name="location"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </CustomDialog>
    </div>
  );
}

export default Destinations;
