import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomDialog, CustomTable } from "../../../lib";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";



import {
  getAllTaxesReturn,
  getAllEligibleTax,
  deleteElligibleTax,
} from "api/API";
import { useConfirm } from "hooks/useConfirm";
import {
  Typography,
  Breadcrumbs,
  Link,
  Box,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import FilterTaxReturnModal from "lib/modals/FilterTaxReturnModal";

function TaxReturns() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { t } = useTranslation();

  const [addModal, setAddModal] = useState(false);
  const [allTaxes, setAllTaxes] = useState([]);
  const [addTaxData, setAddTaxData] = useState();

  const [filterModal, setFilterModal] = useState(false);

  const [selectedTax, setSelectedTax] = useState([]);

  const [taxElligibleUsers, setTaxElligibleUsers] = useState([]);

  const [query, setQuery] = useState("");
  const [loadingTaxReturns, setLoadingTaxReturns] = useState(true);

  useEffect(() => {
    getAllEligibleTax().then((res) => {
      setTaxElligibleUsers(res);
      setLoadingTaxReturns(false);
    });
  }, []);

  const taxes = [
    { id: 1, name: "alocatie" },
    { id: 2, name: "alocatie+impozit" },
    { id: 3, name: "impozit" },
  ];

  const labels = [
    {
      id: "clientFirstName",
      label: t("First name"),
      minWidth: 10,
      onClick: () => console.log("aici"),
    },
    {
      id: "clientLastName",
      label: t("Last name"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "clientDestination",
      label: t("Destination"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "clientStatus",
      label: t("Status"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },

    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  useEffect(() => {
    getAllTaxesReturn().then((res) => setAllTaxes(res));
  }, []);

  const handleChange = (e) => {
    setAddTaxData({
      ...addTaxData,
      [e.target.name]: e.target.value,
    });
  };

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/clients/visualize-clients/${row.clientId}`)
            }
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp; &nbsp; &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(
                t("Are you sure you want to delete this tax return?"),
                () =>
                  deleteElligibleTax(row.id, () =>
                    getAllEligibleTax().then((res) => {
                      setTaxElligibleUsers(res);
                      toast.success(t("Tax has been deleted!"));
                    })
                  )
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }
    if (column.id === "clientFirstName")
      return (
        <Typography variant="tableContent">{row.client?.lastName}</Typography>
      );
    if (column.id === "clientLastName")
      return (
        <Typography variant="tableContent">{row.client?.firstName}</Typography>
      );
    if (column.id === "clientDestination")
      return (
        <Typography variant="tableContent">
          {row.destination?.country?.name + ", " + row.destination?.city?.name}{" "}
        </Typography>
      );

    if (column.id === "clientStatus") return row.status;

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Tax return registration")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Clients")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Tax returns")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTable
        title={t("Tax returns")}
        showSearchbar
        onAdd={() => setAddModal(true)}
        // showFilters
        onFilters={() => setFilterModal(true)}
        labels={labels}
        loading={loadingTaxReturns}
        tableData={taxElligibleUsers.filter((tax) => {
          let display = false;

          if (
            tax.client.firstName.toLowerCase().includes(query.toLowerCase()) ||
            tax.client.lastName.toLowerCase().includes(query.toLowerCase()) ||
            tax.destination.country.name
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            tax.destination.city.name
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            tax.destination.states.name
              .toLowerCase()
              .includes(query.toLowerCase())
          )
            display = true;
          if (display)
            if (selectedTax.length > 0) {
              if (selectedTax.indexOf(tax.type) === -1) display = false;
            }

          return display;
        })}
        cellModifier={cellModifier}
        onSearch={(e) => setQuery(e.target.value)}
      />
      <FilterTaxReturnModal
        open={filterModal}
        setOpen={setFilterModal}
        taxes={taxes}
        selectedTax={selectedTax}
        setSelectedTax={setSelectedTax}
      />
      <CustomDialog
        handleClose={() => setAddModal(false)}
        open={addModal}
        title="Adaugă recuperare taxe"
        button1="Finalizează"
        onClickButton1={() => console.log(">")}
        button2="Renunță"
      >
        <Box
          fullWidth
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Grid container justifyContent={"space-between"} spacing={1}>
            <Grid item xs={6}>
              <div>
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  required
                  id="outlined-required"
                  name="clientCIP"
                  label="CIP"
                  onChange={handleChange}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Driver"
                name="type"
                value={addTaxData?.taxType}
                onChange={handleChange}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                <MenuItem value="alocatie">Alocație</MenuItem>
                <MenuItem value="impozit">Impozit</MenuItem>
                <MenuItem value="alocatie+impozit">Alocație + Impozit</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Observații"
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                name="observations"
                value={addTaxData?.observations}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </div>
  );
}

export default TaxReturns;
