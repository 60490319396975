import * as React from "react";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";

//mui imports

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "&.MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "&.MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .css-1rwt2y5-MuiButtonBase-root-MuiButton-root": {
    borderRadius: "4rem",
  },
  "& .MuiPaper-root": {
    maxWidth: "95vw",
  },
}));

const CustomDialog = (props) => {
  const {
    handleClose = () => {},
    open = false,
    title = "",
    button1 = null,
    onClickButton1 = () => handleClose(),
    button1Loading = false,
    button2 = null,
    onClickButton2 = () => handleClose(),
    button2Loading = false,
    buttonScanDoc = null,
    onClickButtonScanDoc = () => handleClose(),
    buttonScanDocLoading = false,
    children,
  } = props;

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>

        {Boolean(button1) !== null && Boolean(button2) && (
          <DialogActions sx={{ marginRight: "1rem" }}>
            {Boolean(buttonScanDoc) && (
              <LoadingButton
                autoFocus
                onClick={onClickButtonScanDoc}
                sx={{
                  backgroundColor: "#5664D2",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  width: "8.125rem",
                  height: "2.625rem",
                  borderRadius: "1.5rem",
                  "&:hover": {
                    backgroundColor: "#5684D2",
                  },
                }}
                loading={buttonScanDocLoading}
                disabled={buttonScanDocLoading}
              >
                {buttonScanDoc}
              </LoadingButton>
            )}
            {Boolean(button1) && (
              <LoadingButton
                autoFocus
                onClick={onClickButton1}
                sx={{
                  backgroundColor: "#5664D2",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  width: "8.125rem",
                  height: "2.625rem",
                  borderRadius: "1.5rem",
                  "&:hover": {
                    backgroundColor: "#5684D2",
                  },
                }}
                loading={button1Loading}
                disabled={button1Loading}
              >
                {button1}
              </LoadingButton>
            )}

            {Boolean(button2) && (
              <LoadingButton
                autoFocus
                onClick={onClickButton2}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderColor: "#E91E63",
                  cursor: "pointer",
                  color: "#E91E63",
                  width: "8.125rem",
                  height: "2.625rem",
                  border: "1px solid #E91E63",
                  borderRadius: "1.5rem",
                  marginLeft: "0.625rem",
                }}
                loading={button2Loading}
                disabled={button2Loading}
              >
                {button2}
              </LoadingButton>
            )}
          </DialogActions>
        )}
      </BootstrapDialog>
    </>
  );
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default CustomDialog;
