import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import { UserProvider } from "./hooks/useUser";
import { ConfirmProvider } from "./hooks/useConfirm";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "lib/theme";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  // <React.StrictMode>
  <>
    <ThemeProvider theme={customTheme}>
      <UserProvider>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </UserProvider>
    </ThemeProvider>
    <ToastContainer position="bottom-right" autoClose={2000} />
  </>,

  //</React.StrictMode>,
  document.getElementById("root")
);
