import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Breadcrumbs,
  Link,
  Box,
  TextField,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@mui/material";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TaskIcon from "@mui/icons-material/Task";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";

import SignInModal from "lib/modals/SignInModal";
import { addRole } from "api/API";
import { toast } from "react-toastify";

import Roles from "./Roles";

const Settings = () => {
  const { t } = useTranslation();
  const [signInModal, setSignInModal] = useState(false);
  const [checkedPermission, setCheckedPermissions] = useState([]);
  const [permissionData, setPermissionData] = useState("");

  const handleSendPermissions = () => {
    const newData = {
      name: permissionData,
      rolePermissions: checkedPermission,
    };
    addRole(newData, () => {
      toast.success("Rolul a fost adaugat cu succes");
      setCheckedPermissions([]);
      setPermissionData("");
    });
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Settings")}
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link underline="hover" color="inherit">
              <div>{t("Manage your settings")}</div>
            </Link>
          </Breadcrumbs>
        </Typography>
      </div>
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Accordion
            sx={{ maxWidth: 500, borderRadius: "1rem" }}
            onClick={(e) => e.stopPropagation()}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AddIcon
                  style={{
                    border: "1px solid black",
                    borderRadius: "1rem",
                    marginRight: "10px",
                  }}
                />
                <Typography>Adauga un rol nou</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent="space-between">
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Denumire rol"
                    sx={{ fieldset: { borderRadius: "1rem" } }}
                    name="name"
                    value={permissionData}
                    onChange={(e) => setPermissionData(e.target.value)}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "2.2rem",
                    }}
                  >
                    <AdminPanelSettingsIcon />
                    <Typography sx={{ marginLeft: "10px" }}>
                      Administrator
                    </Typography>
                    <Checkbox />
                  </div>
                </Grid>
              </Grid>

              <Roles
                icon={<TaskIcon />}
                name={t("Permisiuni")}
                checkedPermission={checkedPermission}
                setCheckedPermissions={setCheckedPermissions}
              />

              {checkedPermission.length > 0 && (
                <Button
                  sx={{
                    width: "100%",
                    backgroundColor: "#1976d2",
                    color: "#FAFAFA",
                    borderRadius: "1rem",
                    ":hover": {
                      bgcolor: "#1976d3",
                      color: "FAFAFA",
                    },
                  }}
                  onClick={handleSendPermissions}
                >
                  Adauga
                </Button>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            sx={{
              minWidth: "165px",
              height: "42px",
              backgroundColor: "#1976d2",
              color: "#FAFAFA",
              borderRadius: "1rem",
              ":hover": {
                bgcolor: "#1976d2",
                color: "FAFAFA",
              },
            }}
            onClick={() => setSignInModal(true)}
          >
            {t("Create new account")}
          </Button>
          <SignInModal open={signInModal} setOpen={setSignInModal} />
        </Box>
      </div>
    </div>
  );
};

export default Settings;
