import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "hooks/useConfirm";

import { Typography, Breadcrumbs, Link, Box, Divider } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

import { CustomTable } from "lib";
import { getAllUsers, getRoles, deleteUser, deleteRole } from "api/API";

import { useUser } from "hooks/useUser";

import SignInModal from "lib/modals/SignInModal";
import AddRoleModal from "lib/modals/AddRoleModal";
import { toast } from "react-toastify";

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { can } = useUser();

  const [activeTab, setActiveTab] = useState("users");
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  const [signInModal, setSignInModal] = useState(false);
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const canAddUsers = can(22);

  const labels = [
    {
      id: "email",
      label: "Email",
      minWidth: 10,
    },
    {
      id: "name",
      label: t("Name"),
      minWidth: 10,
    },
    {
      id: "role",
      label: t("Role"),
      minWidth: 10,
    },
    ...(canAddUsers
      ? [
          {
            id: "actions",
            label: t("Actions"),
            minWidth: 10,
          },
        ]
      : []),
  ];

  const roleLabels = [
    {
      id: "name",
      label: t("Name"),
      minWidth: 10,
    },
    {
      id: "permission-count",
      label: t("Permission count"),
      minWidth: 10,
    },
    ...(canAddUsers
      ? [
          {
            id: "actions",
            label: t("Actions"),
            minWidth: 10,
          },
        ]
      : []),
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "role") {
      return <span>{row.role?.name}</span>;
    }

    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/users/${row.id}`)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(t("Are you sure you want to delete this user?"), () =>
                deleteUser(row.id, () =>
                  getAllUsers().then((users) => {
                    setUsers(users);
                    toast.success(t("User has been deleted!"));
                  })
                )
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  const roleCellModifier = (row, column, value) => {
    if (column.id === "permission-count") {
      return <span>{row.RolePermissions.length}</span>;
    }

    if (column.id === "actions") {
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            confirm(t("Are you sure you want to delete this role?"), () =>
              deleteRole(row.id, () => {
                getRoles().then((roles) => {
                  setRoles(roles);
                  toast.success(t("Role has been deleted!"));
                });
              })
            )
          }
        >
          <DeleteIcon color="errorCustom" />
        </span>
      );
    }
    return <Typography variant="tableContent"> {value}</Typography>;
  };

  useEffect(() => {
    getAllUsers().then((users) => {
      setUsers(users);
      setLoadingUsers(false);
    });
    getRoles().then((roles) => setRoles(roles));
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Users")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Manage your users")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <Box
        sx={{
          color: "rgba(66, 82, 110, 0.86)",
          marginTop: "4rem",
          display: "flex",
        }}
      >
        <Typography
          variant="tabs"
          sx={{
            paddingInline: "2rem",
            cursor: "pointer",
            color: activeTab === "users" && "#5664D2",
            borderBottom: activeTab === "users" && "2px solid #5664D2",
          }}
          onClick={() => {
            setActiveTab("users");
          }}
        >
          {t("Users")}
        </Typography>
        <Typography
          variant="tabs"
          sx={{
            paddingInline: "2rem",
            cursor: "pointer",
            color: activeTab === "roles" && "#5664D2",
            borderBottom: activeTab === "roles" && "2px solid #5664D2",
          }}
          onClick={() => {
            setActiveTab("roles");
          }}
        >
          {t("Roles")}
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: "2rem" }} />

      {activeTab === "users" && (
        <CustomTable
          title={t("Users")}
          labels={labels}
          cellModifier={cellModifier}
          tableData={users}
          showAdd={canAddUsers}
          onAdd={() => setSignInModal(true)}
          loading={loadingUsers}
        />
      )}

      {activeTab === "roles" && (
        <CustomTable
          title={t("Roles")}
          labels={roleLabels}
          cellModifier={roleCellModifier}
          tableData={roles}
          showAdd={canAddUsers}
          onAdd={() => setAddRoleModal(true)}
        />
      )}

      <SignInModal
        open={signInModal}
        setOpen={setSignInModal}
        fetchUsers={() => getAllUsers().then((users) => setUsers(users))}
      />

      <AddRoleModal
        open={addRoleModal}
        setOpen={setAddRoleModal}
        fetchRoles={() => getRoles().then((roles) => setRoles(roles))}
      />
    </div>
  );
};

export default Users;
