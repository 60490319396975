import axios from "api/axios";
import { handleError } from "utils/functions";

export const getInOutReport = async (perPage, currentPage, pagesToLoad) => {
  try {
    const response = await axios.get("/ioregister", {
      params: {
        perPage,
        currentPage,
        pagesToLoad,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addInOutRegister = async (registerData) => {
  try {
    const res = await axios.post("/ioregister", registerData);
    return { ok: true, data: res.data };
  } catch (error) {
    handleError(error);
  }
};

export const editInOutRegister = async (id, registerData) => {
  try {
    const response = await axios.patch(`/ioregister/${id}`, registerData);
    return { ok: true, data: response.data };
  } catch (error) {
    handleError(error);
  }
};

export const findInOutRegisterByText = async (text) => {
  try {
    const response = await axios.get("/ioregister/findByString/text", {
      params: {
        text,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteInOutRegister = async (registerId, callback = () => {}) => {
  try {
    const response = await axios.delete(`/ioregister/${registerId}`);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};
