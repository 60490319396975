import axios from "api/axios";

export const editRovinieta = async (vehicleId, data, callback = () => {}) => {
  try {
    try {
      for (const info of data) {
        await axios.patch(`/rovineta/${info.id}`, {
          title: info.title,
          expire: info.expire,
        });
      }
      callback();
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadDocVehicle = async (
  carId,
  formData,
  callback = () => {}
) => {
  try {
    const response = await axios.post(`/cars/file?carId=${carId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteRovinieta = async (rovinetaId, callback = () => {}) => {
  try {
    await axios.delete(`/rovineta/${rovinetaId}`);
    callback(rovinetaId);
  } catch (error) {
    console.error(error);
  }
};

export const getDocsVehicle = async (carId) => {
  try {
    const response = await axios.get(`/cars/file`, {
      params: {
        carId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteDocVehicle = async (fileId, callback = () => {}) => {
  try {
    await axios.delete(`/cars/file/${fileId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const uploadGasCreditCards = async (
  carId,
  formData,
  callback = () => {}
) => {
  try {
    const response = await axios.post("/cars/cards", formData, {
      params: {
        carId,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const getGasCreditCards = async (carId) => {
  try {
    const response = await axios.get("/cars/cards", {
      params: {
        carId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deleteGasCreditCard = async (cardId, callback = () => {}) => {
  try {
    await axios.delete(`/cars/cards/${cardId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};
