import { useEffect } from "react";
import { CustomDialog, Problem } from "lib";

import { useParams } from "react-router-dom";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";

import {
  addProblem,
  getAllTrips,
  getProblems,
  getClientsOnRoute,
} from "api/API";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/useUser";

import { Box, TextField, MenuItem, Grid, Button } from "@mui/material";
import { toast } from "react-toastify";
function Problems() {
  const { user } = useUser();
  let { id } = useParams();
  const { t } = useTranslation();
  const [trips, setTrips] = useState([]);
  const [addProblemModal, setAddProblemModal] = useState(false);
  const [routeId, setRouteId] = useState(undefined);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [problems, setProblems] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);

  useEffect(() => {
    getAllTrips().then((trips) => setTrips(trips.content));
    getProblems(id).then((problems) => setProblems(problems));
    getClientsOnRoute(id).then((detail) => setClientDetails(detail));
  }, [id]);

  return (
    <div>
      <Button
        startIcon={<AddIcon />}
        sx={{
          height: "2.25rem",
          backgroundColor: "#5664D2",
          borderRadius: "4rem",
          color: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#5684D2",
          },
        }}
        onClick={() => setAddProblemModal(true)}
      >
        {t("Add Problem")}
      </Button>

      <CustomDialog
        buttonTitle={t("New Problem")}
        button1={t("Finalize")}
        button2={t("Quit")}
        title={t("New Problem")}
        handleClose={() => setAddProblemModal(false)}
        open={addProblemModal}
        onClickButton1={() =>
          addProblem(id, user.id, routeId, title, description, () => {
            getProblems(id).then((res) => {
              setProblems(res);
              setAddProblemModal(false);
              setTitle("");
              setRouteId("");
              setDescription("");
              toast.success(t("Problema added succesfully"));
            });
          })
        }
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                autoComplete={"off"}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                id="outlined-required"
                label={t("Title")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label={t("Relevant trip")}
                value={routeId}
                onChange={(e) => setRouteId(e.target.value)}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                {clientDetails.map((trip) =>
                  trip?.route?.driver ? (
                    <MenuItem key={trip.route.id} value={trip.route.id}>
                      {`${trip.route.driver?.firstName} ${trip.route.driver?.lastName}  -> ${trip.route.destination.country.name}, ${trip.route.destination.city.name}`}
                    </MenuItem>
                  ) : (
                    <MenuItem>{t("No trips available")}</MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label={t("Describe the problem")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          margin: "1rem 0",
        }}
      >
        {problems.map((problem) => (
          <Problem key={problem.id} problem={problem} />
        ))}
      </Box>
    </div>
  );
}

export default Problems;
