import React from "react";
import { Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const Step1Signature = (props) => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const {
    signatureImage,
    setSignatureImage = () => {},
    handleNext = () => {},
  } = props;

  const getSignatureImage = async () => {
    fetch(`${process.env.REACT_APP_SCANNER_SIGNATURE_URL}/api/sign/start`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        toast.success(t("Start the signing process"));
      })
      .catch((response) => {
        toast.error(t("An error ocurred while starting the scanner"));
      });
  };

  return (
    <>
      <Typography variant="h6">
        {t("Step 1: Initialize Signing Process")}
      </Typography>
      <Button
        style={{
          borderRadius: "99rem",
        }}
        variant="contained"
        onClick={() => getSignatureImage()}
      >
        {t("Start the signature pad")}
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={signatureImage.isNewSignature}
                  onChange={() =>
                    setSignatureImage((prev) => ({
                      ...prev,
                      isNewSignature: !prev.isNewSignature,
                    }))
                  }
                  name="isNewSignature"
                />
              }
              label={t("Semnatura Reprezentant Legal")}
            />
          </FormGroup>
        </FormControl>
        <Button
          style={{
            borderRadius: "99rem",
          }}
          variant="outlined"
          onClick={() =>
            confirm(
              t(
                "Are you sure you turned on the signature pad before proceding?"
              ),
              () => {
                handleNext();
              }
            )
          }
        >
          {t("Next")}
        </Button>
      </div>
    </>
  );
};

export default Step1Signature;
