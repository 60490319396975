import React, { useEffect } from "react";
import { useState } from "react";
import { Grid, TextField } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { updateContractSign } from "api/tripApi";

import { useTranslation } from "react-i18next";

import CustomDialog from "lib/components/Modals/CustomModal";

const SignContractModal = ({
  open = false,
  setOpen = () => {},
  clientOnRoutesInfo,
  addCallback = () => {},
}) => {
  const { t } = useTranslation();

  const [contractSign, setContractSign] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  const setFinalData = (startData) => {
    const start = new Date(startData);

    const numberOfDaysToAdd = clientOnRoutesInfo.numberOfDays;

    const result = new Date(start.setDate(start.getDate() + numberOfDaysToAdd));

    setFinalDate(result);
  };

  useEffect(() => {
    if (clientOnRoutesInfo.numberOfDays) {
      const dateNow = new Date();
      setFinalData(dateNow);
    }
  }, [clientOnRoutesInfo]);

  const resetContractDates = () => {
    setContractSign(new Date());
    setFinalDate(new Date());
  };

  return (
    <CustomDialog
      open={open}
      handleClose={() => {
        resetContractDates();
        setOpen(false);
      }}
      title={t("Semnare Contract")}
      button1={t("Add")}
      button2={t("Quit")}
      onClickButton1={() =>
        updateContractSign(
          clientOnRoutesInfo.clientOnRoutesId,
          contractSign,
          finalDate,
          () => {
            setOpen(false);
            addCallback();
            resetContractDates();
          }
        )
      }
    >
      <Grid container justifyContent={"space-between"} spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={6}>
            <DesktopDatePicker
              label="Date semnare contract"
              value={contractSign}
              onChange={(value) => {
                setContractSign(new Date(value));
                setFinalData(value);
              }}
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                    width: "16rem",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <DesktopDatePicker
              label="Date finalizare contract"
              value={finalDate}
              onChange={(value) => {
                setFinalData(new Date(value));
              }}
              inputFormat="DD/MM/YYYY"
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                    width: "16rem",
                  }}
                />
              )}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
    </CustomDialog>
  );
};

export default SignContractModal;
