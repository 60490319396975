import React from 'react'
import { Button } from '@mui/material'

export const TechnicalSupportButton = ({ onClick, startIcon, children }) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#869DB6",
        borderRadius: "20px",
        boxShadow: "0px 5px 15px rgba(35, 78, 140, 0.1)",
        width: "205px",
        height: "45px",
        display: "flex",
        margin: "15px auto 0 auto",
        textTransform: "none",
      }}
      onClick={onClick}
      startIcon={startIcon && startIcon}
    >
      {children}
    </Button>
  );
};

export const DashboardButtons = ({ onClick, startIcon, children, styles }) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#869DB6",
        borderRadius: "20px",
        boxShadow: "0px 5px 15px rgba(35, 78, 140, 0.1)",
        height: "45px",
        display: "flex",
        textTransform: "none",
        ...styles,
      }}
      onClick={onClick}
      startIcon={startIcon && startIcon}
    >
      {children}
    </Button>
  );
};


