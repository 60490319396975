const versionsInfo = [
  {
    version: "1.0.0 Beta",
    versionType: "",
    description: "Versiunea inițială a aplicației cu toate funcționalitățile",
    features: [],
    bugsFixed: [],
  },
  {
    version: "1.1.0",
    versionType: "Major",
    description: "Update",
    features: [
      "Adăugare culori pe culturi",
      "Adăugare câmp pentru plata primei tranșe",
      "Adăugare câmp pentru plata ultimei tranșe pentru o deplasare",
    ],
    bugsFixed: [
      "Afișare sume plătite de client",
      "Rezolvat unele probleme legate de adăugarea clienților pe curse",
      "Rezolvat problema adăugării unui client fără adresă",
      "Editarea completă a clientului",
      "Editarea unei curse",
      "Adăugare unei probleme pe client care încă nu are cursă stabilită",
      "Rezolvat problemă legată de ștergerea unui client de pe o cursă",
    ],
  },
  {
    version: "1.2.0",
    versionType: "Major",
    description: "Update",
    features: [
      "Cautare de culturi la adaugarea de deplasare",
      "Editare deplasare",
      "Atasament foaie prezenta cursa tur",
      "Functie de cautare la adaugarea clientilor pe cursa",
      "Raport zilnic/lunar/anual",
      "Cautare clienti pe tabelul de vizualizare clienti si inscrieri",
      "Adaugat tabel de plati pe deplasari",
      "Anularea unei deplasari si afisarea acestui lucru",
      "Avertizarea in momentul in care un client are cartea de identitate expirata, in momentul adaugarii pe cursa",
      "Dupa ce clientul este adaugat pe cursa exista posibilitatea de a bifa daca acesta a prezentat CNPS, actul de la primarie si cel de celibatar",
      "Pe tabelul de vizualizare clienti este posibila filtrarea dupa data nasterii, tara, judet, oras, gen, persoana de contact, CIP sau tag",
      "Adaugare camp cu adaugarea datei de expirare a cartii de identitate",
      "In momentul in care se adauga un client pe cursa, exista posibilitatea cautarii culturilor disponibile",
      "Schimbarea design-ului cu unul mai atragator",
    ],
    bugsFixed: [],
  },
];

export default versionsInfo;
