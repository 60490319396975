import React, { useState } from "react";
import CustomDialog from "lib/components/Modals/CustomModal";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  Chip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { getAllFields, updateDepartureData } from "api/API";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import dayjs from "dayjs";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

const EditDepartureModal = ({ open, setOpen, details, id, addCallback }) => {
  const { t } = useTranslation();
  const [editDepartureData, setEditDepartureData] = useState({});
  const [depDate, setDepDate] = useState(Date.now());
  const [fields, setFields] = useState([]);
  const [fieldsName, setFieldsName] = useState([]);

  const handleChange = (e) => {
    setEditDepartureData({
      ...editDepartureData,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeClientFields = (event, value) => {
    // setEditDepartureData({
    //   ...editDepartureData,
    //   fieldIds: value.map((val) => val),
    // });
    setFieldsName(value);
  };

  useEffect(() => {
    getAllFields().then((fields) => setFields(fields));
  }, []);

  useEffect(() => {
    if (!open) return;

    if (open === true) {
      setEditDepartureData(details);
      setFieldsName(details.DepartureFields.map((field) => field.field));
    }
  }, [open]);

  const handleSubmit = () => {
    const newObject = {};
    newObject.finalDate= editDepartureData.finalDate;
    newObject.dates = editDepartureData.dates;
    newObject.description = editDepartureData.description;
    newObject.fieldIds = fieldsName.map((field) => field.id);
    
    updateDepartureData(id, newObject, addCallback);
  };
  return (
    <CustomDialog
      open={open}
      handleClose={() => setOpen(false)}
      button1={t("Edit")}
      button2={t("Cancel")}
      title={t("Edit departure")}
      onClickButton1={handleSubmit}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "22rem",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <TextField
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              autoComplete={"off"}
              required
              name="description"
              value={editDepartureData.description}
              onChange={handleChange}
              id="outlined-required"
              label={t("Description")}
            />

            <Autocomplete
              multiple
              id="tags-outlined"
              options={fields}
              value={fieldsName}
              getOptionLabel={(option) => option.name}
              onChange={handleChangeClientFields}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  label={t("Fields")}
                />
              )}
            />
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Final date")}
                name="finalDate"
                value={editDepartureData.finalDate} 
                onChange={(e)=>handleChange({target:{name:'finalDate',value:new Date(e) }})}
                inputFormat="DD/MM/YYYY"
                views={["year", "month","day"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
         
        </Grid>
        <Grid
          container
          spacing={2}
          alignContent="flex-end"
          alignItems="center"
          sx={{ marginTop: "12px" }}
        >

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Period*")}
                name="period"
                value={depDate}
                onChange={(value) => setDepDate(value)}
                inputFormat="MM/YYYY"
                views={["year", "month"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={t("Add a date")}
              onClick={() =>
                setEditDepartureData((current) => ({
                  ...current,
                  dates: [...current.dates, dayjs(depDate).format()],
                }))
              }
              icon={
                <AddCircleOutlineIcon
                  color="primary"
                  style={{
                    cursor: "pointer",
                  }}
                />
              }
            />
          </Grid>
          <Grid item>
            <Typography>
              {editDepartureData?.dates?.length > 0 ? (
                editDepartureData.dates.map((opt) => (
                  <Chip
                    key={opt}
                    label={dayjs(opt).format("MM/YYYY")}
                    onDelete={() =>
                      setEditDepartureData({
                        ...editDepartureData,
                        dates: editDepartureData.dates.filter(
                          (date) => date !== opt
                        ),
                      })
                    }
                    deleteIcon={<DeleteIcon />}
                    variant="outlined"
                    style={{
                      backgroundColor: "#F4F5F7",
                      padding: "5px",
                      margin: "3px",
                      boxShadow:
                        "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                    }}
                  />
                ))
              ) : (
                <Typography style={{ color: "#E91E63" }}>
                  {t("Please select the dates")}
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default EditDepartureModal;
