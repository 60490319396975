import React from "react";
import PropTypes from "prop-types";

// mui
import { Typography, Stack, Button, Box, Container } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "../../theme";

// chartjs
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TT,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TT,
  Legend
);

function Linecard2({ title, number, chartLabel, chartData, button }) {
  // chart styling
  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          color: "white",
        },
      },
      x: {
        display: false,
        grid: {
          color: "white",
        },
      },
    },

    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  // chart data
  const data = {
    labels: chartLabel,
    datasets: chartData,
  };
  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          width: "30rem",
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          boxShadow:
            "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",

          justifyContent: "align-start",
          alignItems: "start",
          overflow: "hidden",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            padding: "1rem",
          }}
        >
          <Typography variant="smallCardHeader">{title}</Typography>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="smallCardTitle">{number}</Typography>
            <Container
              sx={{
                width: "75%",
              }}
            >
              <Line
                options={options}
                data={data}
                style={{
                  padding: "2rem",
                }}
              />
            </Container>
          </Stack>
        </Stack>
        <Button
          startIcon={
            <ArrowCircleDownIcon
              color="warning"
              style={{
                fontSize: "300%",
              }}
            />
          }
          variant="cardButton"
          sx={{
            backgroundColor: "rgba(0,0,0,0)",
            borderTop: "1px solid #E0E0E0",
            padding: "0.7rem 2rem",
          }}
        >
          <Typography variant="smallCardHeader" sx={{ width: "100%" }}>
            {button}
          </Typography>
        </Button>
      </Box>
    </ThemeProvider>
  );
}

Linecard2.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
  button: PropTypes.string,
  chartLabel: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
      borderColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    })
  ),
};

Linecard2.defaultProps = {
  title: "Your title comes here",
  number: 123,
  button: "Lorem ipsum dolor sit amet",
  chartLabel: ["Lorem", "Ipsum", "Dolor", "Sit", "Amet"],
  chartData: [
    {
      label: "Dataset 1",
      data: [10, 20, 15, 20, 5],
      backgroundColor: "#ff0000",
      borderColor: "#ff0000",
    },
  ],
};

export default Linecard2;
