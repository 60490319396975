import axios from "api/axios";
import { handleError } from "utils/functions";

export const getDocsDriver = async (driverId) => {
  try {
    const response = await axios.get("/drivers/file", {
      params: {
        driverId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const uploadDocDriver = async (
  driverId,
  formData,
  callback = () => {}
) => {
  try {
    await axios.post(`/drivers/file?driverId=${driverId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const deleteDocDriver = async (docId, callback = () => {}) => {
  try {
    await axios.delete(`/drivers/file/${docId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const addDriver = async (driver, callback = () => {}) => {
  try {
    await axios.post("/drivers", driver);
    callback();
  } catch (error) {
    handleError(error);
  }
};
