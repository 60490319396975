import axios from "api/axios";
import { handleError } from "utils/functions";

export const getPeriods = async () => {
  try {
    const response = await axios.get("/period");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addPeriod = async (time, callback = () => {}) => {
  try {
    const response = await axios.post("/period", time);
    callback(response.data);
  } catch (error) {
    handleError(error);
  }
};

export const deletePeriod = async (periodId, callback = () => {}) => {
  try {
    await axios.delete(`/period/${periodId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};
