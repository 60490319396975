import { useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect, useCallback } from "react";

import {
  Typography,
  Breadcrumbs,
  Link,
  Box,
  TextField,
  MenuItem,
  Grid,
  Button,
  Chip,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import UploadIcon from "@mui/icons-material/Upload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Tooltip } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { toast } from "react-toastify";
import {
  CustomTable,
  Comments,
  DetailsComponet,
  CustomDialog,
  DocumentModal,
} from "lib";
import { useTranslation } from "react-i18next";
import { useConfirm } from "hooks/useConfirm";

import {
  getTrip,
  getAllFields,
  getClientsOnTrip,
  getAllDrivers,
  getAllDestinations,
  getAllFleets,
  editTrip,
  // deleteClientOnRoute,
  getClientsWithoutRoute,
  updateArrivedTrip,
  getDepartureListArrivals,
  finishedTrip,
  getTripReport,
  deleteClientOnRoute,
  searchClientWhenAddingToTrip,
  uploadTripDocument,
  getTripDocs,
  returnTripDoc,
} from "api/API";

import { getPaymentStatusColor, getStatusColor } from "utils/functions";

import dayjs from "dayjs";

import styles from "./Trip.module.scss";
import AddClientToTrip from "lib/modals/AddClientToTrip/AddClientToTrip";
import SignContractModal from "lib/modals/SignContractModal";
import PrelungireModal from "lib/modals/PrelungireModal";
import AddReasonModal from "lib/modals/AddReasonModal";
import AddIdentification from "lib/modals/AddIdentification";

function Trip() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [trip, setTrip] = useState(null);
  const [addClientOnTrip, setAddClientOnTrip] = useState(false);
  const [clients, setClients] = useState([]);
  const [fields, setFields] = useState([]);
  const [clientsOnTrip, setClientsOnTrip] = useState([]);
  const [tripModal, setTripModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [clientsWithoutRoute, setClientsWithoutRoute] = useState([]);
  const [clientsArrived, setClientsArrived] = useState([]);
  const [clientsForTrip, setClientsForTrip] = useState([]);
  const [signContractModal, setSignContractModal] = useState(false);
  const [clientOnRoutesInfo, setClientOnRoutesInfo] = useState({});
  const [prelungireModal, setPrelungireModal] = useState(false);
  const [clientsOnRoutesId, setClientsOnRouteId] = useState({});
  const [reasonModal, setReasonModal] = useState(false);
  const [openAddDocument, setOpenAddDocument] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [hasMoreClients, setHasMoreClient] = useState(false);
  const [loadingClientsWithoutRoute, setLoadingClientsWithoutRoute] =
    useState(false);

  const [isSearch, setIsSearch] = useState(false);

  const [searchedClient, setSearchedClient] = useState("");
  const [documents, setDocuments] = useState([]);
  const [identificationStatus, setIdentificationStatus] = useState({
    open: false,
    CNPS: false,
    celibatar: false,
    cityHall: false,
    clientsOnRoutesId: "",
  });

  const { t } = useTranslation();
  const confirm = useConfirm();

  const [tripEditData, setTripEditData] = useState({
    departure: new Date(),
    type: "",
    price: "",
    driverId: "",
    carId: "",
    destinationId: "",
    departurePlace: "",
    sits: "",
  });

  const getDocuments = () => {
    getTripDocs(id).then((docs) => setDocuments(docs));
  };

  const downloadDocument = () => {
    const path = documents[documents.length - 1].docs.fileId;
    returnTripDoc(path).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", path);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  useEffect(() => {
    getTrip(id).then((trip) => {
      trip.carRegPlate = trip.car.registrationPlate ?? "";
      trip.driverName = `${trip.driver.firstName} ${trip.driver.lastName}`;
      trip.destinationCountry = trip.destination.country;
      setTrip(trip);
    });

    getClientsOnTrip(id).then((clients) => setClientsOnTrip(clients));
    getAllDrivers().then((res) => setDrivers(res));
    getAllFleets().then((res) => setFleet(res));
    getAllDestinations().then((res) => setDestinations(res));
    getAllFields().then((res) => setFields(res));
    // getAllClients().then((clients) => setClients(clients));
    getDocuments();
  }, [id]);

  const getClientsForAdd = useCallback(() => {
    if (!Boolean(trip)) return;

    setLoadingClientsWithoutRoute(true);

    trip.type === "RETUR"
      ? getDepartureListArrivals(10, pageNumber, 1).then((res) => {
          setClientsWithoutRoute([...clientsWithoutRoute, ...res.content]);
          setHasMoreClient(res.content.length > 0);
          setLoadingClientsWithoutRoute(false);
        })
      : getClientsWithoutRoute(10, pageNumber, 1).then((res) => {
          setClientsWithoutRoute([...clientsWithoutRoute, ...res.content]);
          setHasMoreClient(res.content.length > 0);
          setLoadingClientsWithoutRoute(false);
        });
  }, [trip, pageNumber]);

  useEffect(() => {
    getClientsForAdd();
  }, [trip, pageNumber, getClientsForAdd]);

  const handleChangeEdit = (e) => {
    setTripEditData({
      ...tripEditData,
      [e.target.name]: e.target.value,
    });
  };

  const getDataFromSearch = () => {
    searchClientWhenAddingToTrip({
      text: searchedClient,
      type: trip.type,
    }).then((res) => setClientsWithoutRoute(res));
  };

  useEffect(() => {
    if (isSearch && searchedClient.length > 0) {
      getDataFromSearch();
    } else if (isSearch && searchedClient.length === 0) {
      setIsSearch(false);
      setClientsWithoutRoute([]);
      if (pageNumber === 0) getClientsForAdd();
      else setPageNumber(0);
    }
  }, [isSearch, searchedClient]);

  const keyDownHandlerSearch = (event) => {
    // setSearchedClient(event.target.value);
    // const isEmpty =
    //   event.target.value.length === 1 && event.key === "Backspace";
    if (event.key === "Enter" && searchedClient.length > 0) {
      setIsSearch(true);
      // searchClientWhenAddingToTrip({
      //   text: isEmpty ? "" : event.target.value,
      //   type: trip.type,
      // }).then((res) => setClientsWithoutRoute(res));
    }
  };

  useEffect(() => {
    if (!tripModal) return;

    const { departure, type, price, driverId, carId, destinationId, sits } =
      tripModal;

    setTripEditData({
      departure,
      type,
      price,
      driverId,
      carId,
      destinationId,
      sits,
    });
  }, [tripModal]);

  const handleEditTrip = () => {
    const newTripEditData = { ...tripEditData };
    newTripEditData.price = parseInt(newTripEditData.price);
    newTripEditData.sits = parseInt(newTripEditData.sits);

    editTrip(id, newTripEditData, () => {
      getTrip(id).then((trip) => {
        trip.carRegPlate = trip.car.registrationPlate ?? "";
        trip.driverName = `${trip.driver.firstName} ${trip.driver.lastName}`;
        trip.destinationCountry = trip.destination.country;
        setTrip(trip);

        setTripModal(false);
        toast.success(t("Trip has been updated!"));
      });
    });
  };

  const render = [
    {
      key: "type",
      label: t("Type"),
    },
    {
      key: "startDeparture",
      label: t("Departure period"),
      cellModifier: (displayData) => {
        return trip.departure === null ? (
          <Typography>
            {dayjs(trip.startDeparture).format("DD-MM-YYYY") +
              " <--> " +
              dayjs(trip.finalDeparture).format("DD-MM-YYYY")}
          </Typography>
        ) : (
          <Typography>{dayjs(trip.departure).format("DD-MM-YYYY")}</Typography>
        );
      },
    },
    {
      key: "destination",
      label: t("Destination"),
      cellModifier: (displayData) => {
        return (
          <Typography sx={{ marginLeft: "10px" }} variant="detailsText">
            {displayData.city?.name +
              ", " +
              displayData.country?.name +
              " -> " +
              displayData?.location}
          </Typography>
        );
      },
    },
    {
      key: "driverName",
      label: t("Driver"),
      cellModifier: (displayData) => (
        <Typography
          sx={{
            border: "1px solid #5664D2",
            borderRadius: "1rem",
            padding: "0.3rem",
            color: "#5664D2",
          }}
        >
          {displayData}
        </Typography>
      ),
    },
    {
      key: "carRegPlate",
      label: t("Car"),
      cellModifier: (displayData) => (
        <Typography
          sx={{
            border: "1px solid #5664D2",
            borderRadius: "1rem",
            padding: "0.3rem",
            color: "#5664D2",
          }}
        >
          {displayData}
        </Typography>
      ),
    },
    {
      key: "sits",
      label: t("Seats"),
      cellModifier: (displayData) => {
        let colorFromSeats;
        clientsOnTrip?.length < displayData
          ? (colorFromSeats = "#FFB547")
          : clientsOnTrip?.length === displayData
          ? (colorFromSeats = "#7BC67E")
          : (colorFromSeats = "#F44336");
        return (
          <Typography
            sx={{
              border: "1px solid #FFB547",
              backgroundColor: colorFromSeats,
              borderRadius: "1rem",
              padding: "4px 14px",
              color: "#FFFFFF",
            }}
          >
            {clientsOnTrip?.length ? clientsOnTrip?.length : "0"}/{displayData}
          </Typography>
        );
      },
    },
    {
      key: "price",
      label: t("Price"),
      cellModifier: (displayData) => <Typography>{displayData}€</Typography>,
    },
    {
      key: "raport",
      label: t("Raport"),
      cellModifier: (displayData) => {
        return <Typography>raport</Typography>;
      },
    },
    {
      key: "documentFile",
      label: t("Attendance sheet"),
      cellModifier: () => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {documents.length > 0 && (
              <Tooltip title="View file">
                <AttachFileIcon
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={downloadDocument}
                />
              </Tooltip>
            )}
            <Tooltip title={t("Upload document")}>
              <UploadIcon
                style={{ cursor: "pointer" }}
                color="primary"
                onClick={() => setOpenAddDocument(true)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const types = [
    {
      value: "TUR",
      label: "Tur",
    },
    {
      value: "RETUR",
      label: "Retur",
    },
  ];

  const labels = [
    {
      id: "clientName",
      label: t("Name"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "clientPhone",
      label: t("Phone"),
      minWidth: 10,
      onClick: () => {},
    },
    ...(trip?.type === "TUR"
      ? [
          {
            id: "clientField",
            label: t("Field"),
            minWidth: 10,
            onClick: () => {},
          },
        ]
      : []),

    {
      id: "contractSign",
      label: "Perioada",
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "clientCIP",
      label: "CIP",
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "paymentStatus",
      label: t("Payment status"),
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "status",
      label: "Status",
      minWidth: 10,
      onClick: () => {},
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => {},
    },
  ];

  const cellModifierTable = (row, column, value) => {
    if (column.id === "actions")
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {row.type === "TUR" && row.status !== "FINALIZAT" && (
            <Button
              variant="contained"
              style={{
                borderRadius: "1rem",
                marginRight: "1rem",
              }}
              onClick={() =>
                setIdentificationStatus({
                  open: true,
                  clientsOnRoutesId: row.id,
                  CNPS: row.CNPS,
                  celibatar: row.celibatar,
                  cityHall: row.cityHall,
                })
              }
            >
              {t("Papers")}
            </Button>
          )}
          {row.status === "STABILIT" || row.status === "RETUR" ? (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setClientOnRoutesInfo(row.id);
                setReasonModal(true);
              }}
            >
              <WorkOffIcon color="errorCustom" />
            </span>
          ) : (
            <Typography></Typography>
          )}
          &nbsp; &nbsp; &nbsp;
          {row.status === "FINALIZAT" && row.contractSign === null && (
            <span
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  borderRadius: "1rem",
                }}
                onClick={() => {
                  setSignContractModal(true);

                  setClientOnRoutesInfo({
                    numberOfDays:
                      clientsOnTrip[
                        clientsOnTrip.findIndex(
                          (client) => client.id === row.id
                        )
                      ].timeFrame.time,
                    clientOnRoutesId: row.id,
                  });
                }}
              >
                {t("Sign Contract")}
              </Button>
            </span>
          )}
          {/* &nbsp; &nbsp; &nbsp; */}
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/clients/departures-list/" + row.id)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
        </div>
      );

    if (column.id === "paymentStatus") {
      const colorFromPayment = getPaymentStatusColor(
        row.totalPayed,
        row.route.price
      );

      return (
        <Typography
          sx={{
            backgroundColor: colorFromPayment,
            color: "#FFFFFF",
            borderRadius: "16px",
            padding: "5px 14px",
            maxWidth: "76px",
          }}
        >
          {row.totalPayed + "/" + row.route.price}
        </Typography>
      );
    }
    if (column.id === "status") {
      let colorFromStatus = getStatusColor(row.status);

      return (
        <Typography
          sx={{
            backgroundColor: colorFromStatus,
            color: "white",
            borderRadius: "1rem",
            padding: "4px 14px",
            maxWidth: "86px",
          }}
        >
          {value}
        </Typography>
      );
    }
    if (column.id === "departureRoute")
      return dayjs(value).format("DD-MM-YYYY");

    if (column.id === "clientName") {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {row.client.firstName} {row.client.lastName}
          {row.client.isExpired && (
            <Chip
              label={t("CI EXPIRED")}
              variant="outlined"
              color="error"
              size="small"
            />
          )}
        </div>
      );
    }

    if (column.id === "clientPhone")
      return <Typography>{row.client.phone}</Typography>;

    if (column.id === "departureRoute")
      return <Typography>{row.route.departure}</Typography>;

    if (column.id === "clientCIP")
      return <Typography> {row.client?.CIP} </Typography>;

    if (column.id === "clientField") {
      return (
        <Typography
          sx={{
            backgroundColor: row.field.type,
            "& span": {
              mixBlendMode: "difference",
              color: "white",
            },
            borderRadius: "1rem",
            padding: "4px 14px",
          }}
        >
          <span>{row.field?.name}</span>
        </Typography>
      );
    }

    if (column.id === "contractSign") {
      if (row.contractSign !== null) {
        return `${dayjs(row.contractSign).format("DD-MM-YYYY")} --> ${dayjs(
          row.finalDate
        ).format("DD-MM-YYYY")} (${row.timeFrame?.time} zile)`;
      } else {
        return <Typography>Contractul nu a fost semnat</Typography>;
      }
    }

    return value;
  };

  const getReport = async () => {
    getTripReport(id).then((res) => {
      window.open(`${process.env.REACT_APP_API_BASE}/route/reports/${res}`);
    });
  };

  return (
    <div>
      <div className={styles.title}>
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Trip details")}
        </Typography>

        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Trips")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <div className={styles.parent}>
        <div className={styles.upperSide}>
          <DetailsComponet
            title={t("Trip details")}
            tableData={{ ...trip, documentFile: null }}
            showEdit
            render={render}
            onEdit={() => setTripModal(trip)}
            showFinished={!trip?.finished}
            showDownloadRaport
            onDownloadRaport={getReport}
            onFinished={() =>
              confirm(
                t("Are you sure you want to mark the trip as finished?"),
                () =>
                  trip.type === "TUR"
                    ? updateArrivedTrip(id, () => {
                        getClientsOnTrip(trip.id).then((clients) => {
                          setClientsOnTrip(clients);
                          setAddClientOnTrip(false);
                          toast.success(t("Trip was finalized succesfully"));
                        });
                      })
                    : finishedTrip(id, () => {
                        getClientsOnTrip(trip.id).then((clients) => {
                          setClientsOnTrip(clients);
                          setAddClientOnTrip(false);
                          toast.success(t("Trip was finalized succesfully"));
                        });
                      })
              )
            }
          />
        </div>

        <AddReasonModal
          open={reasonModal}
          setOpen={setReasonModal}
          clientOnRouteId={clientOnRoutesInfo}
          functionForDelete={deleteClientOnRoute}
          deleteCallback={() => {
            getClientsOnTrip(id).then((clients) => setClientsOnTrip(clients));
            toast.success("Clientul a fost sters cu succes");
          }}
        />

        <div className={styles.table}>
          <CustomTable
            title={t("Record")}
            showAdd={!trip?.finished}
            onAdd={() => setAddClientOnTrip(true)}
            labels={labels}
            tableData={clientsOnTrip}
            cellModifier={cellModifierTable}
          />
        </div>

        <div className={styles.comments}>
          <Comments type="ROUTE" id={id} />
        </div>

        {/* Add client to trip modal */}
        <AddClientToTrip
          trip={trip}
          clientsForTrip={clientsForTrip}
          clientsArrived={clientsArrived}
          clientsWithoutRoute={clientsWithoutRoute}
          loading={loadingClientsWithoutRoute}
          hasMoreClients={hasMoreClients}
          setPageNumber={setPageNumber}
          setClientsWithoutRoute={setClientsWithoutRoute}
          open={addClientOnTrip}
          setOpen={setAddClientOnTrip}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          routeId={id}
          fields={fields}
          setSearchedClient={setSearchedClient}
          keyDownHandlerSearch={keyDownHandlerSearch}
          getClientsForAdd={getClientsForAdd}
          addCallback={() => {
            // trip.type === "RETUR"
            //   ? getDepartureListArrivals().then((res) => {
            //       setClientsWithoutRoute(res);
            //       setAddClientOnTrip(false);
            //       toast.success(t("Client has been added on trip!"));
            //     })
            //   : getClientsWithoutRoute().then((res) => {
            //       setClientsWithoutRoute(res);
            //       setAddClientOnTrip(false);
            //       toast.success(t("Client has been added on trip!"));
            //     });
            // getClientsOnTrip(trip.id).then((clients) => {
            //   setClientsOnTrip(clients);
            // });

            setAddClientOnTrip(false);

            setIsSearch(false);
            setClientsWithoutRoute([]);
            if (pageNumber === 0) getClientsForAdd();
            else setPageNumber(0);
            toast.success(t("Client has been added on trip!"));

            getClientsOnTrip(trip.id).then((clients) => {
              setClientsOnTrip(clients);
            });
          }}
        />

        <SignContractModal
          open={signContractModal}
          setOpen={setSignContractModal}
          clientOnRoutesInfo={clientOnRoutesInfo}
          addCallback={() => {
            getClientsOnTrip(trip.id).then((clients) => {
              setClientsOnTrip(clients);
              setAddClientOnTrip(false);
              toast.success(
                t("Perioada contractuala a fost actualizata cu succes")
              );
            });
          }}
        />

        <PrelungireModal
          open={prelungireModal}
          setOpen={setPrelungireModal}
          clientsOnRoutesId={clientsOnRoutesId}
        />

        {/* Add document modal */}
        <DocumentModal
          open={openAddDocument}
          setOpen={setOpenAddDocument}
          handleSubmit={(formData) => {
            uploadTripDocument("document", trip.id, formData, () => {
              getDocuments();
              setOpenAddDocument(false);
              toast.success("File has been uploaded!");
            });
          }}
        />

        {/* Add identifiers modal */}
        <AddIdentification
          open={identificationStatus.open}
          setOpen={() =>
            setIdentificationStatus({
              ...identificationStatus,
              open: false,
            })
          }
          data={identificationStatus}
          addCallback={() => {
            getClientsOnTrip(id).then((clients) => setClientsOnTrip(clients));
          }}
        />

        {/* Edit trip modal */}
        <CustomDialog
          open={Boolean(tripModal)}
          handleClose={() => setTripModal(false)}
          title={"Editeaza cursa"}
          button1="Edit"
          button2="Quit"
          onClickButton1={() => handleEditTrip()}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "white",
              borderRadius: "1.5rem",
              height: "100%",
            }}
          >
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={t("Departure")}
                    name="departure"
                    value={tripEditData?.departure}
                    onChange={(value) => {
                      handleChangeEdit({
                        target: {
                          name: "departure",
                          value,
                        },
                      });
                    }}
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          fieldset: {
                            borderRadius: "1.5rem",
                          },
                          width: "16rem",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  select
                  label={t("Type")}
                  name="type"
                  value={tripEditData?.type}
                  onChange={handleChangeEdit}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                >
                  {types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  value={tripEditData?.price}
                  required
                  id="outlined-required"
                  label={t("Price")}
                  name="price"
                  onChange={handleChangeEdit}
                />

                <TextField
                  select
                  label={t("Driver")}
                  name="driverId"
                  onChange={handleChangeEdit}
                  value={tripEditData?.driverId}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                >
                  {drivers.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firstName + " " + option.lastName}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  select
                  label={t("Car")}
                  name="carId"
                  value={tripEditData?.carId}
                  onChange={handleChangeEdit}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                >
                  {fleet.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.registrationPlate}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  select
                  label={t("Destination")}
                  name="destinationId"
                  value={tripEditData?.destinationId}
                  onChange={handleChangeEdit}
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                >
                  {destinations.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.city?.name +
                        ", " +
                        option.country?.name +
                        " -> " +
                        option?.location}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  sx={{
                    fieldset: {
                      borderRadius: "1.5rem",
                    },
                  }}
                  required
                  id="outlined-required"
                  label={t("Nr. of seats")}
                  name="sits"
                  value={tripEditData?.sits}
                  onChange={handleChangeEdit}
                />
              </Grid>
            </Grid>
          </Box>
        </CustomDialog>
      </div>
    </div>
  );
}

export default Trip
