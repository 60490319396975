import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "hooks/useConfirm";
import { CustomTablePagination } from "../../../lib";

import { useTranslation } from "react-i18next";

import { Typography, Breadcrumbs, Link } from "@mui/material";

import {
  getAllDrivers,
  getAllFleets,
  getAllDestinations,
  getAllTrips,
  deleteTrip,
} from "../../../api/API";
import dayjs from "dayjs";

import { toast } from "react-toastify";

function AllTrips() {
  const navigate = useNavigate();
  const [trips, setTrips] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [query, setQuery] = useState("");
  const [openFilter, setFilterOpen] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [loadingTrips, setLoadingTrips] = useState(true);
  const confirm = useConfirm();
  const { t } = useTranslation();

  const [addTripData, setAddTripData] = useState({
    departure: "",
    type: "",
    driverId: "",
    carId: "",
    sits: "",
    destinationId: "",
    price: "",
  });
  const [addTripsModal, setAddTripsModal] = useState(false);

  const labels = [
    {
      id: "type",
      label: t("Type"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "location",
      label: t("Destination"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "departure",
      label: t("Departure date"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "driver",
      label: t("Driver"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "vehicle",
      label: t("Vehicle"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "sits",
      label: t("Seats"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  useEffect(() => {
    getAllDrivers().then((res) => setDrivers(res));
    getAllFleets().then((res) => setFleet(res));
    getAllDestinations().then((res) => setDestinations(res));
  }, []);

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/trips/trip/` + row.id)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(t("Are you sure you want to delete this trip?"), () =>
                deleteTrip(row.id, () => {
                  setTriggerRefetch(!triggerRefetch);
                  toast.success(t("Vehicle has been deleted!"));
                })
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }

    if (column.id === "location")
      return (
        <Typography variant="tableContent">
          {row.destination.city?.name +
            "," +
            row.destination?.states.name +
            ", " +
            row.destination.country?.name +
            ", " +
            " --> " +
            row.destination?.location}
        </Typography>
      );

    if (column.id === "driver") {
      return (
        <Typography variant="tableContent">
          {row.driver.firstName + " " + row.driver.lastName}
        </Typography>
      );
    }

    if (column.id === "vehicle") {
      return (
        <Typography variant="tableContent">
          {row.car.registrationPlate}
        </Typography>
      );
    }
    if (column.id === "sits") {
      let colorFromSeats = "#7BC67E";
      row.ClientsOnRoute?.length < value
        ? (colorFromSeats = "#FFB547")
        : row.ClientsOnRoute?.length === value
        ? (colorFromSeats = "#7BC67E")
        : (colorFromSeats = "#F44336");

      return (
        <span
          style={{
            backgroundColor: colorFromSeats,
            color: "white",
            padding: "4px 14px",
            borderRadius: "16px",
          }}
        >
          {row.ClientsOnRoute?.length}/{value}
        </span>
      );
    }
    if (column.id === "departure") {
      return row.departure === null ? (
        <Typography variant="tableContent">
          {dayjs(row.startDeparture).format("DD-MM-YYYY") +
            " <--> " +
            dayjs(row.finalDeparture).format("DD-MM-YYYY")}
        </Typography>
      ) : (
        <Typography variant="tableContent">
          {dayjs(value).format("DD-MM-YYYY")}
        </Typography>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("All trips")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Trips")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("All trips")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTablePagination
        title={t("All trips")}
        // showSearchbar
        onAdd={() => setAddTripsModal(true)}
        labels={labels}
        getterFunction={getAllTrips}
        setterFunction={setTrips}
        triggerRefetch={triggerRefetch}
        loading={loadingTrips}
        setLoading={setLoadingTrips}
        tableData={trips?.filter((trip) => {
          if (
            query === "" ||
            trip.type.toLowerCase().includes(query.toLowerCase()) ||
            trip.destination.country.name
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            trip.driver.firstName.toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }
        })}
        cellModifier={cellModifier}
        onSearch={(e) => setQuery(e.target.value)}
      />
    </div>
  );
}

export default AllTrips;
