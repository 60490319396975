// react imports
import React, { useState, useCallback } from "react";
import { ThemeProvider } from "@mui/material/styles";
import customTheme from "lib/theme";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useTranslation } from "react-i18next";
import { Typography, Button, TextField, Box, Divider } from "@mui/material";

import logoRaisis from "resources/LogoRaisisCRM.png";
import axios from "api/axios";


import logo_mediere from "resources/logo_lucicosm_mediere.png";
import { useUser } from "hooks/useUser";
import { recoverPasswordUser } from "api/API";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recoverPassword, setRecoverPassword] = useState(false);
  const { setAccessToken } = useUser();
  const theme = useTheme();
  const mobilePhone = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post("/users/login", {
          email,
          password,
        });
        const { token } = response.data;
        toast.success("V-ati logat cu succes!");
        setAccessToken(token);
      } catch (error) {
        toast.error("A intervenit o eroare");
        console.error(error);
      }
    },
    [email, password]
  );

  const handleSubmitRecoverPassword = () => {
    if (email === "") {
      toast.error("Introduceti o adresa valida");
    } else {
      recoverPasswordUser(email).then((res) => {
        if (res.ok === true) toast.success("Email trimis cu succes.");
        else toast.error("A intervenit o eroare!");
        setEmail("");
        setRecoverPassword(false);
      });
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            width: "100vw",
            minHeight: "100vh",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              flex: 1,
              gap: "7.5vw",
              display: "flex",
              flexDirection: mobilePhone ? "column" : "row",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "2.5rem",
            }}
          >
            <img
              style={{
                width: "40%",
                maxWidth: "400px",
              }}
              src={logo_mediere}
              alt="LogoMediere"
            />
            <Box
              sx={{
                width: mobilePhone ? "90vw" : "40vw",
                maxWidth: "500px",
                flexShrink: 0,
                borderRadius: "15px",
                padding: "25px 32px",
                boxShadow: "0px 1px 2px rgba(36, 36, 36, 0.25)",
              }}
            >
              {recoverPassword === false ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    sx={{
                      // boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                      fieldset: { borderRadius: "10px" },
                    }}
                  />
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    label={t("Password")}
                    sx={{
                      fieldset: { borderRadius: "10px" },
                    }}
                  />
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      backgroundColor: "#5664D2",
                      borderRadius: "1rem",
                      "&:hover": { backgroundColor: "#5654D1" },
                    }}
                  >
                    Autentifica-te
                  </Button>
                  <Divider />
                  <Button
                    onClick={() => setRecoverPassword(true)}
                    sx={{
                      color: "#5664D2",
                    }}
                  >
                    Recupereaza parola
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                  <div>
                    <Button
                      onClick={() => setRecoverPassword(false)}
                      startIcon={<NavigateBeforeIcon />}
                    >
                      <Typography>Inapoi</Typography>
                    </Button>
                  </div>
                  <Typography>
                    Introduceți adresa de email. Vă vom trimite un link de
                    resetare a parolei.
                  </Typography>
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    sx={{
                      // boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
                      fieldset: { borderRadius: "10px" },
                    }}
                  />
                  <Button
                    onClick={() => handleSubmitRecoverPassword()}
                    variant="contained"
                  >
                    Trimite link
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <img
            src={logoRaisis}
            alt="logo raisis"
            style={{
              flexShrink: 0,
              width: "35%",
              maxWidth: "256px",
              paddingBlock: "2.5rem",
            }}
          ></img>
        </Box>
      </form>
    </ThemeProvider>
  );
}

export default Login;
