import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTable } from "../../../lib";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Typography, Breadcrumbs, Link, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getClientsOnRouteByType,
  getDepartureListArrivals,
  findClientOnLists,
} from "api/tripApi";
import { getPaymentStatusColor, getStatusColor } from "utils/functions";

import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { CustomTablePagination } from "lib";

import dayjs from "dayjs";

function ArrivalList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [arrivals, setArrivals] = useState([]);
  const [arrivalList, setArrivalList] = useState([]);
  const [query, setQuery] = useState("");
  const [loadingArrivalList, setLoadingArrivalList] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  // useEffect(() => {
  //   getDepartureListArrivals().then((res) => {
  //     setArrivalList(res);
  //     setLoadingArrivalList(false);
  //   });
  // }, []);

  const labels = [
    {
      id: "client",
      label: t("Client"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "phone",
      label: t("Phone Number"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    // {
    //   id: "field",
    //   label: t("Field"),
    //   minWidth: 10,
    //   onClick: () => console.log("clicked"),
    // },
    {
      id: "period",
      label: t("Time period"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "cip",
      label: "CIP",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentStatus",
      label: t("Payment status"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];
  useEffect(() => {
    getClientsOnRouteByType("RETUR").then((res) => setArrivals(res));
  }, []);

  const span = (number) => {
    return (
      <p>
        Probleme{" "}
        <span style={{ fontWeight: 800, color: "#E91E63" }}>{number}</span>
      </p>
    );
  };

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`./` + row.id)}
        >
          <ArrowForwardIcon />
        </span>
      );
    }
    if (column.id === "paymentStatus") {
      const colorFromPayment = getPaymentStatusColor(
        row.totalPayed,
        row.route?.price
      );
      let priceOfTheRoute = row.route?.price;
      if (priceOfTheRoute === undefined)
        priceOfTheRoute = t("Trip not established yet");
      else priceOfTheRoute = row.route?.price;

      return (
        <span>
          <Typography
            variant="tableContent"
            sx={{
              backgroundColor: colorFromPayment,
              color: "white",
              padding: "5px 14px",
              borderRadius: "999px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* {row.paymentStatus + "/" + row.route?.price} */}
            {`${row.totalPayed}/${priceOfTheRoute}`}
          </Typography>
        </span>
      );
    }
    if (column.id === "field")
      return <Typography variant="tableContent"> {row.field?.name}</Typography>;
    if (column.id === "status") {
      let colorFromStatus = getStatusColor(row.status);

      return (
        <span
          style={{
            backgroundColor: colorFromStatus,
            color: "white",
            padding: "5px 14px",
            borderRadius: "16px",
          }}
        >
          {value}
        </span>
      );
    }
    if (column.id === "client") {
      return (
        <Typography variant="tableContent">
          {" "}
          {row.client.firstName + " " + row.client.lastName}
        </Typography>
      );
    }
    if (column.id === "period") {
      return (
        <Typography variant="tableContent">
          {dayjs(row.route?.departure).format("DD/MM/YYYY")}
        </Typography>
      );
    }
    if (column.id === "phone") {
      return <Typography variant="tableContent">{row.client.phone}</Typography>;
    }
    if (column.id === "cip") {
      return <Typography variant="tableContent"> {row.client.CIP}</Typography>;
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Arrival list")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Clients")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Arrival list")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTablePagination
        title={t("Arrival list")}
        showSearchbar
        labels={labels}
        loading={loadingArrivalList}
        setLoading={setLoadingArrivalList}
        tableData={arrivalList}
        cellModifier={cellModifier}
        getterFunction={getDepartureListArrivals}
        setterFunction={setArrivalList}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        searchFunction={findClientOnLists}
        additionalId={"RETUR"}
      />

      {/* <CustomTable
        title={t("Arrival list")}
        showSearchbar
        // showFilters
        onFilters={() => console.log("filters clicked")}
        onAdd={() => console.log("filters clicked")}
        labels={labels}
        onSearch={(e) => setQuery(e.target.value)}
        loading={loadingArrivalList}
        tableData={arrivalList
          .map((row) => ({
            ...row,
            info: (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "10px",
                  gap: "10px",
                }}
              >
                {row.client.Problems?.length > 0 ? (
                  <Chip
                    key={row.clientId}
                    icon={
                      <PriorityHighIcon
                        color="#FFFFFF"
                        sx={{
                          backgroundColor: "#E91E63",
                          borderRadius: "1rem",
                        }}
                        style={{
                          color: "white",
                        }}
                      />
                    }
                    label={span(row.client.Problems.length)}
                    sx={{
                      border: "1px solid #E91E63",
                      background: "#FFFFFF",
                      color: "#E91E63",
                    }}
                    onClick={() =>
                      navigate(`/clients/visualize-clients/` + row.clientId)
                    }
                  />
                ) : null}
                {row.client.TagOnClient.map((tag) => (
                  <Chip
                    key={tag.tag.id}
                    label={tag.tag.name}
                    sx={{ background: tag.tag.type }}
                  />
                ))}
              </div>
            ),
          }))
          .filter((trip) => {
            if (
              query === "" ||
              trip.client.lastName
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              trip.client.firstName
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              trip.field.name.toLowerCase().includes(query.toLowerCase())
            )
              return true;
          })}
        cellModifier={cellModifier}
      /> */}
    </div>
  );
}

export default ArrivalList;
