import { useState, useEffect } from 'react'
import styles from './Dashboard.module.scss'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { Breadcrumbs, Link, Typography } from '@mui/material'

import { Linecard, OccupationOverview } from '../../lib'

import { useUser } from "hooks/useUser";

import {
  getAllActiveFields,
  getAllDashboardFields,
  getAllFinished,
  getDashboardPayments,
  getDashboardRegistration,
} from "api/dashboardAPI";

import useWindowDimensions from "hooks/useWindowDimensions";

import { getAllDepartures } from "api/clientApi";
import LoadingSpinner from "lib/components/Spinner/LoadingSpinner";
import AppMoneyFlow from "lib/components/Charts/apexChart/AppMoneyFlow";
import dayjs from "dayjs";

function Dashboard() {
  const { t } = useTranslation();
  const { can, user } = useUser();
  const navigate = useNavigate();
  const [activeFields, setActiveFields] = useState([]);
  const [dashboardFields, setDashboardsField] = useState([]);
  const [finishedRoute, setFinishedRoutes] = useState([]);
  const [actualRegistration, setActualRegistrations] = useState([]);
  const [loadingFinishedTrips, setLoadingFinishedTrips] = useState(true);
  const [loadingDepartures, setLoadingDepartures] = useState(true);
  const [loadingDashboardFields, setLoadingDashboardFields] = useState(true);
  const [selectedValue, setSelectedValue] = useState("day");
  const [selectedValueRegistrations, setSelectedValueRegistrations] =
    useState("day");
  const [newChartData, setNewChartData] = useState([]);
  const [newLabelsData, setNewLabelsData] = useState([]);

  const [registrationChartData, setRegistrationChartData] = useState([]);
  const [registrationLabelsData, setRegistrationLabelsData] = useState([]);

  const [loadingMoneyFlow, setLoadingMoneyFlow] = useState(true);
  const [loadingRegistrationsFlow, setLoadingRegistrationsFlow] =
    useState(true);

  const [startDateMoney, setStartDateMoney] = useState(new Date());
  const [endDateMoney, setEndDateMoney] = useState(new Date());

  const [startDateRegistrations, setStartDatesRegistrations] = useState(
    new Date()
  );
  const [endDateRegistrations, setEndDateRegistrations] = useState(new Date());

  const [openMoneyDates, setOpenMoneyDates] = useState(false);
  const [openRegistrationDates, setOpenRegistrationDates] = useState(false);
  const [moneyTotal, setMoneyTotal] = useState(0);
  const [registrationsTotal, setRegistrationsTotal] = useState(0);

  const todaysDate = dayjs().format("YYYY-MM-DD");

  const { vw } = useWindowDimensions();

  const canSeeDashboardInfos = can(22);

  useEffect(() => {
    getDashboardPayments({
      startDate: todaysDate,
      endDate: todaysDate,
      tag: selectedValue,
      orice: true,
    }).then((res) => {
      setNewChartData(res.sumArray);
      setNewLabelsData(res.dates);
      setMoneyTotal(res.Total);
      setLoadingMoneyFlow(false);
    });
    getDashboardRegistration({
      startDate: todaysDate,
      endDate: todaysDate,
      tag: selectedValue,
      orice: true,
    }).then((res) => {
      setRegistrationChartData(res.sumArray);
      setRegistrationLabelsData(res.dates);
      setRegistrationsTotal(res.Total);
      setLoadingRegistrationsFlow(false);
    });
  }, []);

  useEffect(() => {
    getAllActiveFields().then((res) => setActiveFields(res));
    getAllDashboardFields().then((res) => {
      setDashboardsField(res);
      setLoadingDashboardFields(false);
    });
    getAllFinished().then((res) => {
      setFinishedRoutes(res);
      setLoadingFinishedTrips(false);
    });
    getAllDepartures().then((res) => {
      setActualRegistrations(res.count);
      setLoadingDepartures(false);
    });
  }, []);

  return canSeeDashboardInfos ? (
    <div className={styles.container}>
      <div className={styles.title}>
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          Dashboard
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>Dashboard</div>
          </Link>
        </Breadcrumbs>
      </div>

      <div className={styles.header}>
        <Typography
          variant="firstPageTitle"
          sx={{ padding: vw <= 640 ? "0 1rem" : 0 }}
        >
          {t("Hello")}, {user.name}!{" "}
          {t("Welcome to the Lucicosm Mediere platform")}!
        </Typography>
      </div>

      <div>
        <AppMoneyFlow
          title="Bani incasati"
          chartLabels={newLabelsData}
          chartData={[
            {
              name: "Info",
              type: "area",
              fill: "gradient",
              data: newChartData,
            },
          ]}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          tableType="money"
          loading={loadingMoneyFlow}
          startDate={startDateMoney}
          endDate={endDateMoney}
          setStartDate={setStartDateMoney}
          setEndDate={setEndDateMoney}
          open={openMoneyDates}
          setOpen={setOpenMoneyDates}
          setData={setNewChartData}
          setLabels={setNewLabelsData}
          total={moneyTotal}
          setTotal={setMoneyTotal}
        />
      </div>
      <br></br>
      <div>
        <AppMoneyFlow
          title="Inscrieri"
          chartLabels={registrationLabelsData}
          chartData={[
            {
              name: "Info",
              type: "area",
              fill: "gradient",
              data: registrationChartData,
            },
          ]}
          selectedValue={selectedValueRegistrations}
          setSelectedValue={setSelectedValueRegistrations}
          tableType="registration"
          loading={loadingRegistrationsFlow}
          startDate={startDateRegistrations}
          endDate={endDateRegistrations}
          setStartDate={setStartDatesRegistrations}
          setEndDate={setEndDateRegistrations}
          open={openRegistrationDates}
          setOpen={setOpenRegistrationDates}
          setData={setRegistrationChartData}
          setLabels={setRegistrationLabelsData}
          total={registrationsTotal}
          setTotal={setRegistrationsTotal}
        />
      </div>
      <div className={styles.parent}>
        <div className={styles.div1}>
          <Linecard
            title={t("Finished trips")}
            number={finishedRoute}
            button={t("Trips")}
            onClick={() => navigate("/trips/all-trips")}
            loading={loadingFinishedTrips}
          />
        </div>
        <div className={styles.div2}>
          <Linecard
            title={t("Actual registrations")}
            number={actualRegistration}
            button={t("Registrations")}
            onClick={() => navigate("/clients/registrations")}
            loading={loadingDepartures}
          />
        </div>

        <div className={styles.div4}>
          <OccupationOverview
            title={t("Fields distribution")}
            data={dashboardFields?.map((af) => ({
              label: af.field?.name,
              number: af.count,
            }))}
            loading={loadingDashboardFields}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.header}>
      <Typography
        variant="firstPageTitle"
        sx={{ padding: vw <= 640 ? "0 1rem" : 0 }}
      >
        {t("Hello")}, {user.name}!{" "}
        {t("Welcome to the Lucicosm Mediere platform")}!
      </Typography>
    </div>
  );
}

export default Dashboard
