import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CustomDialog } from "lib";
import { Box, Chip, Grid, TextField, Typography } from "@mui/material";
import { addTag, updateTag } from "api/API";

const TagModal = ({ open, setOpen, addCallback, editCallback }) => {
  const { t } = useTranslation();
  const [tagData, setTagData] = useState({});
  const { id: clientId } = useParams;

  const handleChange = (e) => {
    setTagData({
      ...tagData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (!open) return;

    if (open === true) {
      setTagData({
        name: "",
        type: "#f4f4f4",
      });
      return;
    }

    setTagData({
      tagId: open.id,
      name: open.name,
      type: open.type,
    });
  }, [open]);

  return (
    <CustomDialog
      handleClose={() => setOpen(false)}
      open={Boolean(open)}
      title={typeof open === "boolean" ? t("Add tag") : t("Edit tag")}
      button1={t("Finalize")}
      button2={t("Quit")}
      onClickButton1={() =>
        typeof open === "boolean"
          ? addTag(clientId, tagData, addCallback)
          : updateTag(tagData, editCallback)
      }
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "20rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoComplete={"off"}
              fullWidth
              sx={{
                fieldset: {
                  borderRadius: "1.5rem",
                },
              }}
              id="outlined-required"
              label={t("Name")}
              name="name"
              value={tagData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
              <input
                type="color"
                value={tagData.type}
                onChange={handleChange}
                name="type"
                style={{
                  width: 50,
                  height: 50,
                }}
              />

              <div>
                <Typography sx={{ fontSize: 12 }}>Preview tag:</Typography>
                <Chip
                  label={tagData.name?.length > 0 ? tagData.name : "Text tag"}
                  sx={{
                    backgroundColor: tagData.type,
                    "& span": {
                      mixBlendMode: "difference",
                      color: "white",
                    },
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default TagModal;
