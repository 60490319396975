import axios from "api/axios";
import { handleError } from "utils/functions";

export const getAllPermissions = async () => {
  try {
    const response = await axios.get("role/permissions/permission");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addRole = async ({ name, rolePermissions }, callback) => {
  try {
    await axios.post("/role", {
      name,
      rolePermissions,
    });

    callback();
  } catch (error) {
    handleError(error);
  }
};

export const deleteRole = async (roleId, callback) => {
  try {
    await axios.delete(`/role/${roleId}`);
    callback();
  } catch (error) {
    console.error(error);
  }
};

export const getRoles = async () => {
  try {
    const response = await axios.get("/role");
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getRole = async (roleId) => {
  try {
    const res = await axios.get(`/role/${roleId}`);
    return res.data.RolePermissions;
  } catch (error) {
    console.error(error);
    return [];
  }
};
