import React, { useState } from "react";

import { Typography, Breadcrumbs, Link } from "@mui/material";
import { CustomTablePagination } from "lib";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import { useTranslation } from "react-i18next";
import {
  findInOutRegisterByText,
  getInOutReport,
  deleteInOutRegister,
} from "api/API";
import AddReportModal from "./AddReportModal";
import dayjs from "dayjs";
import { useConfirm } from "hooks/useConfirm";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

const InOutReport = () => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [editReportData, setEditReportData] = useState(undefined);
  const [reports, setReports] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [loadingReports, setLoadingReports] = useState(true);
  const labels = [
    {
      id: "registrationNumber",
      label: t("Registration Number"),
      minWidth: 10,
    },
    {
      id: "dateOfRegistration",
      label: t("Registration Date"),
      minWidth: 10,
    },
    {
      id: "numberDateOfRegistration",
      label: t("Registration number & Document Date"),
      minWidth: 10,
    },
    {
      id: "documentOrigin",
      label: t("Where the document comes from"),
      minWidth: 10,
    },
    {
      id: "documentBriefing",
      label: t("Brief content of the document"),
      minWidth: 10,
    },
    {
      id: "asignee",
      label: t(
        "The compartment to which the document and the receiving signature were assigned"
      ),
      minWidth: 10,
    },
    {
      id: "expeditionDate",
      label: t("Expedition Date"),
      minWidth: 10,
    },
    {
      id: "recipient",
      label: t("Recipient"),
      minWidth: 10,
    },
    {
      id: "indicNumber",
      label: t("Reg. No. to which they connect. doc. and the No."),
      minWidth: 10,
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "registrationNumber") {
      return (
        <Typography variant="tableContent">
          {row.registerNumber.registerNumber}
        </Typography>
      );
    }

    if (column.id === "dateOfRegistration") {
      return (
        <Typography variant="tableContent">
          {dayjs(row.createdAt).format("DD-MM-YYYY")}
        </Typography>
      );
    }

    if (column.id === "numberDateOfRegistration") {
      return (
        <Typography variant="tableContent">
          {row.registerNumber.registerNumber}/
          {dayjs(row.createdAt).format("DD-MM-YYYY")}
        </Typography>
      );
    }

    if (column.id === "documentOrigin") {
      return <Typography variant="tableContent">{row.source}</Typography>;
    }

    if (column.id === "documentBriefing") {
      return <Typography variant="tableContent">{row.details}</Typography>;
    }

    if (column.id === "asignee") {
      return <Typography variant="tableContent">{row.responsible}</Typography>;
    }

    if (column.id === "indicNumber") {
      return (
        <Typography variant="tableContent">
          {row.registerNumber.registerNumber}
        </Typography>
      );
    }

    if (column.id === "actions") {
      return (
        <div style={{ display: "flex", gap: "5px" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setReportModalOpen(true);
              setEditReportData(row);
            }}
          >
            <ModeEditIcon color="primary" />
          </span>

          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(
                t(
                  "Are you sure you want to delete this? This is irreversible!"
                ),
                () =>
                  deleteInOutRegister(row.id, () => {
                    setTriggerRefetch(!triggerRefetch);
                    toast.success(t("Succesfully deleted!"));
                  })
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </div>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };
  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Register")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("In/Out Register")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTablePagination
        title={t("In/Out Register")}
        showSearchbar
        showAdd
        onAdd={() => setReportModalOpen(true)}
        labels={labels}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        tableData={reports}
        cellModifier={cellModifier}
        loading={loadingReports}
        setLoading={setLoadingReports}
        getterFunction={getInOutReport}
        setterFunction={setReports}
        searchFunction={findInOutRegisterByText}
      />

      {reportModalOpen && (
        <AddReportModal
          reportModalOpen={reportModalOpen}
          setReportModalOpen={setReportModalOpen}
          setTriggerRefetch={setTriggerRefetch}
          editReportData={editReportData}
          setEditReportData={setEditReportData}
        />
      )}
    </div>
  );
};

export default InOutReport;
