import React, { useState } from "react";
import { CustomDialog } from "lib";
import { Box, Grid, TextField, Button } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { useTranslation } from "react-i18next";

const FilterPeriodsModal = ({
  open = false,
  setOpen = () => {},
  periods = [],
  selectedPeriods = [],
  setSelectedPeriods = () => {},
}) => {
  const { t } = useTranslation();
  const [filterDate, setFilterDate] = useState(new Date());

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Filtering by periods")}
      button1={t("Finalize")}
      button2={t("Quit")}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          //   width: "20rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={t("Period")}
                name="period"
                value={selectedPeriods}
                onChange={(value) => setSelectedPeriods(value)}
                inputFormat="MM/YYYY"
                views={["year", "month"]}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    sx={{
                      fieldset: {
                        borderRadius: "1.5rem",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{
                borderRadius: "1rem",
              }}
              onClick={() => setSelectedPeriods(null)}
            >
              Reseteaza
            </Button>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default FilterPeriodsModal;
