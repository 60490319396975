import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  // shadows: ["none"],
  overrides: {
    MuiSelect: {
      select: {
        padding: "100px",
      },
    },
    MuiInputLabel: {
      outline: {
        fieldset: {
          borderRadius: "none",
        },
      },
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "cardButton" },
          style: {
            textTransform: "none",
            border: "none",
            backgroundColor: "#F7F8F9",
            width: "100%",
            display: "flex",
            textAlign: "left",
            fontSize: "13px",
            fontWeight: 900,
            lineHeight: "22.1px",
            padding: "0.5rem 0",
          },
        },
      ],
    },
  },

  palette: {
    lightCustom: {
      main: "#FFFFFF",
    },
    primaryCustom: {
      main: "#5664D2",
      light: "#7783DB",
      dark: "#3C4693",
      border: "alpha(#5664D2, 0.5)",
      background: "rgba(86, 100, 210, 0.08)",
    },
    secondaryCustom: {
      main: "#E91E63",
      border: "rgba(233, 30, 99, 0.5)",
    },
    textCustom: {
      primary: "#253858",
      secondary: "rgba(66, 82, 110, 0.86)",
    },
    actionCustom: {
      disabled: "rgba(0, 0, 0, 0.26)",
    },
    otherCustom: {
      activeRating: "#FFB400",
      divider: "rgba(0, 0, 0, 0.12)",
      border: "rgba(0, 0, 0, 0.23)",
    },
    infoCustom: {
      main: "#2196F3",
      dark: "#0B79D0",
      light: "#64B6F7",
      textDark:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #2196F3",
      lightBg:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3",
    },
    successCustom: {
      main: "#4CAF50",
      dark: "#3B873E",
      light: "#7BC67E",
    },
    errorCustom: {
      main: "#F44336",
      dark: "#E31B0C",
      light: "#F88078",
    },
    warningCustom: {
      dark: "#C77700",
      light: "#FFB547",
    },
    blueGreyCustom: {
      700: "#66788A",
      400: "#C7CED4",
      200: "#EDF0F2",
    },
    shadowCustom: {
      24: "#ffffff",
    },
  },
  typography: {
    // color: "#253858",
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    tableTitle: {
      fontFamily: '"Montserrat", sans-serif',
      lineHeight: 1.6,
      fontWeight: 900,
      fontSize: "18px",
      letterSpacing: "0.15px",
      color: "#253858",
    },
    tabs: {
      fontFamily: '"Montserrat", sans-serif',
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: "0.15px",
    },
    pageTitle: {
      fontFamily: '"Montserrat", sans-serif',
      lineHeight: "30px",
      fontWeight: 900,
      fontSize: "24px",
      letterSpacing: "0.15px",
    },
    h6: {
      color: "#253858",
      fontWeight: 600,
    },
    detailsText: {
      fontFamily: '"Montserrat", sans-serif',
      color: "#253858",
    },
    sidebarLabel: {
      fontFamily: '"Montserrat", sans-serif',
      lineHeight: "30px",
      fontWeight: 900,
      fontSize: "12px",
      letterSpacing: "0.15px",
    },
    sidebarItem: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      lineHeight: "24px",
      fontWeight: 900,
      fontSize: "14px",
      letterSpacing: "0.15px",
    },
    sidebarNestedItem: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "14px",
      letterSpacing: "0.15px",
    },
    cardHeader: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      lineHeight: "28.8px",
      fontWeight: 900,
      fontSize: "18px",
      letterSpacing: "0.15px",
    },
    smallCardHeader: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      lineHeight: "20.02px",
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "0.15px",
    },
    smallCardTitle: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      lineHeight: "32.02px",
      fontWeight: 900,
      fontSize: "24px",
      letterSpacing: "0.15px",
    },
    tableContent: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      lineHeight: "20px",
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "0.1px",
    },
    clientContactedTimesBox: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      letterSpacing: "0.1px",
      color: "#fff",
      backgroundColor: "#EFAC00",
      borderRadius: "100px",
      padding: "6px 15px",
    },
    firstPageTitle: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontStyle: "normal",
      lineHeight: "34px",
      fontWeight: 700,
      fontSize: "28px",
      letterSpacing: "0.15px",
      color: "rgba(0, 0, 0, 0.62)",
    },
  },
});

export default customTheme;
