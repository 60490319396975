import React, { useState } from "react";
import { CustomTablePagination } from "lib";
import { useNavigate } from "react-router-dom";

import {
  getAllDepartures,
  findClientByText,
  findClientOnRouteByString,
} from "api/clientApi";
import { Typography, Breadcrumbs, Link, Chip } from "@mui/material";
import { getPaymentStatusColor, getStatusColor } from "utils/functions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";

const Registrations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allDepartures, setAllDepartures] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");

  const labels = [
    {
      id: "firstName",
      label: t("First name"),
      minWidth: 10,
      onClick: () => console.log("name clicked"),
    },
    {
      id: "lastName",
      label: t("Last name"),
      minWidth: 10,
      onClick: () => console.log("name clicked"),
    },
    {
      id: "clientField",
      label: t("Field"),
      minWidth: 10,
      onClick: () => console.log("name clicked"),
    },
    {
      id: "departurePlace",
      label: t("Departure Place"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "destination",
      label: t("Destination"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "paymentStatus",
      label: t("Payment status"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/clients/departures-list/` + row.id)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp;&nbsp;&nbsp;
        </>
      );
    }

    if (column.id === "clientField") {
      return row.DepartureFields.map((fld) => (
        <div
          key={fld.field.id}
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              backgroundColor: fld.field.type,
              "& span": {
                // mixBlendMode: "difference",
                color: "white",
              },
              borderRadius: "1rem",
              padding: "4px 14px",
              margin: "2px",
            }}
          >
            <span>{fld.field.name}</span>
          </Typography>
        </div>
      ));
    }
    if (column.id === "firstName") {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {row.isCanceled && (
            <Chip
              label={t("ANULAT")}
              variant="outlined"
              color="error"
              size="small"
            />
          )}
          <Typography variant="tableContent">{row.client.firstName}</Typography>
        </div>
      );
    }
    if (column.id === "lastName") {
      return (
        <Typography variant="tableContent">{row.client.lastName}</Typography>
      );
    }

    if (column.id === "field") {
      return (
        <Typography variant="tableContent">
          {row.field ? row.field.name : t("No field available")}
        </Typography>
      );
    }

    if (column.id === "departurePlace") {
      return (
        <Typography variant="tableContent">
          {row.destination
            ? row.destination.city.name +
              " " +
              row.destination.states.name +
              " " +
              row.destination.country.name
            : t("Not available yet")}
        </Typography>
      );
    }

    if (column.id === "destination") {
      return (
        <Typography variant="tableContent">
          {row.route
            ? row.route.destination.city.name +
              " " +
              row.route.destination.states.name +
              " " +
              row.route.destination.country.name
            : t("Not available yet")}
        </Typography>
      );
    }
    if (column.id === "paymentStatus") {
      const colorFromPayment = getPaymentStatusColor(
        row.totalPayed,
        row.route?.price
      );
      let priceOfTheRoute = row.route?.price;
      if (priceOfTheRoute === undefined)
        priceOfTheRoute = t("Trip not established yet");
      else priceOfTheRoute = row.route?.price;
      return (
        <Typography
          variant="tableContent"
          sx={{
            backgroundColor: colorFromPayment,
            color: "white",
            padding: "4px 14px",
            borderRadius: "999px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* {`${value}/${priceOfTheRoute}`} */}
          {row.totalPayed + "/" + priceOfTheRoute}
        </Typography>
      );
    }

    if (column.id === "status") {
      let colorFromStatus = getStatusColor(row.status);

      return (
        <Typography
          variant="tableContent"
          style={{
            backgroundColor: colorFromStatus,
            color: "white",
            padding: "4px 14px",
            borderRadius: "999px",
            display: "inline-block",
          }}
        >
          {value}
        </Typography>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Registrations")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Clients")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Registrations")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTablePagination
        title={t("Registrations")}
        tableData={allDepartures}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        labels={labels}
        cellModifier={cellModifier}
        getterFunction={getAllDepartures}
        setterFunction={setAllDepartures}
        searchFunction={findClientOnRouteByString}
        showSearchbar
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default Registrations;
