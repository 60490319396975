import { toast } from "react-toastify";
import i18n from "i18n";

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const handleError = (error) => {
  console.error(error);
  const errorKey = Object.keys(error.response.data.errors)[0];
  toast.error(i18n.t(capitalize(error.response.data.errors[errorKey])));
};

export const handleErrorCustom = (error) => {
  console.error(error);
  toast.error(error.response.data.message);
};

export const getPaymentStatusColor = (value, total) => {
  if (value === 0) return "#F88078";
  if (total <= value) return "#7BC67E";
  return "#FFB547";
};

export const getStatusColor = (value) => {
  if (value === "INSCRIS") return "#64B6F7";
  if (value === "STABILIT") return "#2196F3";
  return "#3B873E";
};

export const getTaxDetailsColor = (value) => {
  if (value === "Completare dosar") return "#64B6F7";
  if (value === "Verificare/semnare") return "#2196F3";
  if (value === "Fara alocatie") return "#F88078";
  return "#21c5f3";
};

export const getDOB = (value) => {
  let YEAR = value?.substring(1, 3);
  let MONTH = value?.substring(3, 5);
  let DAY = value?.substring(5, 7);

  let cutoff = new Date().getFullYear() - 2000;
  var DOB = (YEAR > cutoff ? "19" : "20") + YEAR + "/" + MONTH + "/" + DAY;

  return DOB;
};
