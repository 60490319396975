import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomTablePagination } from "../../../lib";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

import { Typography, Breadcrumbs, Link } from "@mui/material";
import { getRouteByTypeArrival, deleteTrip } from "../../../api/API";

import { useConfirm } from "hooks/useConfirm";

import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";

import dayjs from "dayjs";

import AddTripModal from "lib/modals/AddTripModal";

function ArrivalTrips() {
  const navigate = useNavigate();
  const [arrivalRoute, setArrivalRoute] = useState([]);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const confirm = useConfirm();
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [addTripsModal, setAddTripsModal] = useState(false);
  const [loadingArrivalTrips, setLoadingArrivalTrips] = useState(true);
  const [addTripData, setAddTripData] = useState({
    departure: "",
    type: "",
    driverId: "",
    carId: "",
    sits: "",
    destinationId: "",
    price: "",
  });

  const labels = [
    {
      id: "type",
      label: t("Type"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "departurePlace",
      label: t("Departure Place"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "location",
      label: t("Destination"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "departure",
      label: t("Departure date"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "driver",
      label: t("Driver"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "vehicle",
      label: t("Vehicle"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "sits",
      label: t("Seats"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "location") {
      return (
        <Typography variant="tableContent">
          {row.destination.city.name +
            " - " +
            row.destination.states.name +
            " - " +
            row.destination.country.name +
            " --> " +
            row.destination?.location}
        </Typography>
      );
    }
    if (column.id === "actions") {
      return (
        <>
          <span onClick={() => navigate(`/trips/trip/` + row.id)}>
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(t("Are you sure you want to delete this trip?"), () =>
                deleteTrip(row.id, () => {
                  setTriggerRefetch(!triggerRefetch);
                  toast.success(t("Vehicle has been deleted!"));
                })
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }
    if (column.id === "driver") {
      return (
        <Typography variant="tableContent">
          {row.driver.firstName + " " + row.driver.lastName}
        </Typography>
      );
    }
    if (column.id === "vehicle") {
      return (
        <Typography variant="tableContent">
          {row.car.registrationPlate}
        </Typography>
      );
    }

    if (column.id === "sits") {
      let colorFromSeats = "#7BC67E";
      row.ClientsOnRoute?.length < value
        ? (colorFromSeats = "#FFB547")
        : row.ClientsOnRoute?.length === value
        ? (colorFromSeats = "#7BC67E")
        : (colorFromSeats = "#F44336");

      return (
        <span
          style={{
            backgroundColor: colorFromSeats,
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "999px",
          }}
        >
          {row.ClientsOnRoute?.length}/{value}
        </span>
      );
    }

    if (column.id === "departure") {
      return row.departure === null ? (
        <Typography variant="tableContent">
          {dayjs(row.startDeparture).format("DD-MM-YYYY") +
            " <--> " +
            dayjs(row.finalDeparture).format("DD-MM-YYYY")}
        </Typography>
      ) : (
        <Typography variant="tableContent">
          {dayjs(value).format("DD-MM-YYYY")}
        </Typography>
      );
    }

    if (column.id === "departurePlace") {
      return (
        <Typography variant="tableContent">
          {row.StartingDestination?.city.name +
            " - " +
            row.StartingDestination?.states.name +
            " - " +
            row.StartingDestination?.country.name +
            " --> " +
            row.StartingDestination?.location}
        </Typography>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Arrival trips")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Trips")}</div>
          </Link>
          <Link aria-current="page" underline="hover" color="text.primary">
            <div>{t("Arrival")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <AddTripModal
        routeType="RETUR"
        open={addTripsModal}
        setOpen={setAddTripsModal}
        addCallback={() => {
          setTriggerRefetch(!triggerRefetch);
          toast.success(t("Trip added succesfully"));
          setAddTripsModal(false);
        }}
      />

      <CustomTablePagination
        title={t("Arrival trips")}
        // showSearchbar
        showAdd
        onAdd={() => setAddTripsModal(true)}
        labels={labels}
        onSearch={(e) => setQuery(e.target.value)}
        getterFunction={getRouteByTypeArrival}
        setterFunction={setArrivalRoute}
        triggerRefetch={triggerRefetch}
        loading={loadingArrivalTrips}
        setLoading={setLoadingArrivalTrips}
        tableData={arrivalRoute.filter((trip) => {
          if (
            query === "" ||
            trip.type.toLowerCase().includes(query.toLowerCase()) ||
            trip.car.registrationPlate
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            trip.destination.country.name
              .toLowerCase()
              .includes(query.toLowerCase()) ||
            trip.driver.firstName.toLowerCase().includes(query.toLowerCase())
          ) {
            return true;
          }
        })}
        cellModifier={cellModifier}
      />
    </div>
  );
}

export default ArrivalTrips;
