import axios from "api/axios";
import { handleError } from "utils/functions";

export const getAllActiveFields = async () => {
  try {
    const response = await axios.get(
      "/clientsOnRoute/dashboardActive/getAllActiveFields"
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllDashboardFields = async () => {
  try {
    const response = await axios.get("/clientsOnRoute/dashboard/getAllFields");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllFinished = async () => {
  try {
    const response = await axios.get("/route/allRoutes/finished");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAllWithoutRoute = async () => {
  try {
    const response = await axios.get("/clientsOnRoute/allWithoutRoute");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};


export const getDashboardPayments = async (object) => {
  

  try {
    const response = await axios.get("clientsOnRoute/getPayments/dashboard", {
      params: {
        ...object,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDashboardRegistration = async (object) => {
  try {
    const response = await axios.get(
      "clientsOnRoute/getRegistration/dashboard",
      {
        params: {
          ...object,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
