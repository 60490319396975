import React, { useState } from "react";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import { CustomTablePagination } from "lib";

import { useTranslation } from "react-i18next";
import { findInDocumentsByText, getAllClientsDocs } from "api/API";

const docMapper = {
  IC: "CI",
  PASSPORT: "Pasaport",
  RECORD: "Cazier",
  CONTRACT: "Contract",
  GDPR: "GDPR",
  CITYHALL: "Primaria",
  BACHELOR: "Celibatar",
  OTHER: "Contract",
};

const Documents = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(true);

  const labels = [
    {
      id: "name",
      label: t("Name"),
      minWidth: 10,
    },
    {
      id: "document",
      label: t("Document"),
      minWidth: 10,
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "name") {
      return (
        <Typography variant="tableContent">
          {row.client.firstName} {row.client.lastName}{" "}
        </Typography>
      );
    }

    if (column.id === "document") {
      return (
        <Typography variant="tableContent">
          <a
            rel="noreferrer"
            target={"_blank"}
            href={`${process.env.REACT_APP_API_BASE}/clients/file/${row.docs.fileId}`}
            style={{ color: "rgba(66, 82, 110, 0.86)" }}
          >
            {" "}
            <Typography variant="tableContent">
              {docMapper[row.type]}{" "}
            </Typography>
          </a>
        </Typography>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };
  return (
    <div>
      <div
        style={{
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="pageTitle"
          sx={{
            fontWeight: 900,
          }}
        >
          {t("Documents")}
        </Typography>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            <div>{t("Administration")}</div>
          </Link>
          <Link underline="hover" color="inherit">
            <div>{t("Documents")}</div>
          </Link>
        </Breadcrumbs>
      </div>

      <CustomTablePagination
        title={t("Documents")}
        showSearchbar
        labels={labels}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        tableData={documents}
        cellModifier={cellModifier}
        loading={loadingDocuments}
        setLoading={setLoadingDocuments}
        getterFunction={getAllClientsDocs}
        setterFunction={setDocuments}
        searchFunction={findInDocumentsByText}
      />
    </div>
  );
};

export default Documents;
