import React from "react";
import { CustomDialog } from "lib";
import { Box, Grid, Typography, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

const ClientsFilterModal = ({
  open = false,
  setOpen = () => {},
  fields = [],
  selectedFields = [],
  setSelectedFields = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Filter")}
      button1={t("Finalize")}
      button2={t("Quit")}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "20rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}></Grid>
      </Box>
    </CustomDialog>
  );
};

export default ClientsFilterModal;
