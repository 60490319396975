import { useEffect } from "react";
import { CustomTable, CustomDialog } from "lib";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useConfirm } from "hooks/useConfirm";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { getTaxDetailsColor } from "utils/functions";
import { useTranslation } from "react-i18next";

import {
  Box,
  TextField,
  Grid,
  MenuItem,
  Typography,
  InputAdornment,
} from "@mui/material";

import { getClient } from "api/clientApi";

import { addTaxReturn, getTaxesByClient, deleteTax } from "api/API";
import { toast } from "react-toastify";

function Taxes() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [client, setClient] = useState(null);

  const [addTaxesModal, setAddTaxesModal] = useState(false);

  const [addTaxesData, setAddTaxesData] = useState(null);

  const [taxes, setTaxes] = useState([]);

  const confirm = useConfirm();
  const { t } = useTranslation();

  const labels = [
    {
      id: "type",
      label: t("Type"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "clientCIP",
      label: "CIP",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "payed",
      label: t("Paid"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "payedGermany",
      label: t("Paid in Germany"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "payedTax",
      label: t("Paid taxes"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "comision",
      label: t("Comission"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "brut",
      label: t("Gross cash"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
    {
      id: "actions",
      label: t("Actions"),
      minWidth: 10,
      onClick: () => console.log("clicked"),
    },
  ];

  useEffect(() => {
    getClient(id).then((res) => setClient(res));
    getTaxesByClient(id).then((res) => setTaxes(res));
  }, [id]);

  const handleChange = (e) => {
    setAddTaxesData({
      ...addTaxesData,
      [e.target.name]: e.target.value,
    });
  };

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/clients/tax-returns/${row.id}`)}
          >
            <ArrowForwardIcon color="primary" />
          </span>
          &nbsp; &nbsp; &nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() =>
              confirm(
                t("Are you sure you want to delete this tax return?"),
                () =>
                  deleteTax(row.id, () =>
                    getTaxesByClient(id).then((res) => {
                      setTaxes(res);
                      toast.success(t("Tax has been deleted!"));
                    })
                  )
              )
            }
          >
            <DeleteIcon color="errorCustom" />
          </span>
        </>
      );
    }
    if (column.id === "brut") {
      return <Typography variant="tableContent">{value + " €"}</Typography>;
    }
    if (column.id === "payed") {
      return <Typography variant="tableContent">{value + " €"}</Typography>;
    }
    if (column.id === "comision") {
      return <Typography variant="tableContent">{value + " %"}</Typography>;
    }
    if (column.id === "payedGermany") {
      if (value === true) {
        return <Typography variant="tableContent">DA</Typography>;
      } else {
        return <Typography variant="tableContent">NU</Typography>;
      }
    }
    if (column.id === "payedTax") {
      if (value === true) {
        return <Typography variant="tableContent">DA</Typography>;
      } else {
        return <Typography variant="tableContent">NU</Typography>;
      }
    }
    if (column.id === "status") {
      let colorFromStatus = getTaxDetailsColor(row.status);

      return (
        <Typography
          variant="tableContent"
          style={{
            backgroundColor: colorFromStatus,
            color: "white",
            display: "inline",
            padding: "6px 12px",
            borderRadius: "999px",
          }}
        >
          {value}
        </Typography>
      );
    } else {
      return <Typography variant="tableContent">{value}</Typography>;
    }
  };

  const handleSubmitTaxReturn = () => {
    if (addTaxesData) {
      const newTaxData = { ...addTaxesData };
      newTaxData.payed = parseInt(newTaxData.payed);
      newTaxData.payedGermany = JSON.parse(newTaxData.payedGermany);
      newTaxData.payedTax = JSON.parse(newTaxData.payedTax);
      newTaxData.clientId = id;
      addTaxReturn(newTaxData, () => {
        getTaxesByClient(id).then((res) => setTaxes(res));
      });
      toast.success("Formularul a fost adaugat cu succes");
      setAddTaxesModal(false);
      setAddTaxesData("");
    } else {
      toast.fail("A intervenit o eroare");
    }
  };

  return (
    <div>
      <CustomTable
        title={t("Tax Return")}
        showAdd
        tableData={taxes}
        labels={labels}
        onAdd={() => setAddTaxesModal(true)}
        cellModifier={cellModifier}
      />
      <CustomDialog
        handleClose={() => setAddTaxesModal(false)}
        open={addTaxesModal}
        title={t("Add taxes")}
        button1={t("Finalize")}
        button2={t("Quit")}
        onClickButton1={() => handleSubmitTaxReturn()}
      >
        <Box
          sx={{
            p: 2,
            backgroundColor: "white",
            borderRadius: "1.5rem",
            height: "100%",
          }}
        >
          <Grid container justifyContent={"space-between"} spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label={t("Type")}
                name="type"
                value={addTaxesData?.type}
                onChange={handleChange}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                <MenuItem value="alocatie">Alocație</MenuItem>
                <MenuItem value="impozit">Impozit</MenuItem>
                <MenuItem value="alocatie+impozit">Alocație + Impozit</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                required
                id="outlined-required"
                label={t("Paid")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                value={addTaxesData?.payed}
                name="payed"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                required
                id="outlined-required"
                label={t("Status")}
                value={addTaxesData?.status}
                name="status"
                onChange={handleChange}
              >
                <MenuItem value="Inscris">Inscris</MenuItem>
                <MenuItem value="Fara alocatie">Fara alocatie</MenuItem>
                <MenuItem value="Verificare/semnare">
                  Verificare/semnare
                </MenuItem>
                <MenuItem value="Completare dosar">Completare dosar</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                required
                id="outlined-required"
                label="Comision"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                value={addTaxesData?.comision}
                name="comision"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                required
                id="outlined-required"
                label="Brut"
                value={addTaxesData?.brut}
                name="brut"
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label={t("Paid in Germany")}
                name="payedGermany"
                value={addTaxesData?.payedGermany}
                onChange={handleChange}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                <MenuItem value="true">DA</MenuItem>
                <MenuItem value="false">NU</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label={t("Paid taxes")}
                name="payedTax"
                value={addTaxesData?.payedTax}
                onChange={handleChange}
                sx={{
                  fieldset: {
                    borderRadius: "1.5rem",
                  },
                }}
              >
                <MenuItem value="true">DA</MenuItem>
                <MenuItem value="false">NU</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </div>
  );
}

export default Taxes;
