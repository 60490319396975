import React, { useEffect, useState } from "react";
import { CustomDialog } from "lib";
import { Box, Grid, Typography, Checkbox, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterFieldsModal = ({
  open = false,
  setOpen = () => {},
  fields = [],
  selectedFields = [],
  setSelectedFields = () => {},
}) => {
  const { t } = useTranslation();
  const [searchFields, setSearchFields] = useState("");
  const [filteredFields, setFilteredFields] = useState([]);

  useEffect(() => {
    setFilteredFields(
      fields.filter((item) => item.name.toLowerCase().includes(searchFields))
    );
  }, [searchFields]);

  return (
    <CustomDialog
      open={Boolean(open)}
      handleClose={() => setOpen(false)}
      title={t("Filtering by fields")}
      button1={t("Finalize")}
      button2={t("Quit")}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
          borderRadius: "1.5rem",
          height: "100%",
          width: "20rem",
        }}
      >
        <Grid container justifyContent={"space-between"} spacing={2}>
          <TextField
            fullWidth
            sx={{
              fieldset: {
                borderRadius: "1.5rem",
              },
            }}
            autoComplete={"off"}
            name="contactPerson"
            value={searchFields}
            onChange={(e) => setSearchFields(e.target.value)}
            id="outlined-required"
            label={t("Find fields")}
          />
          {filteredFields.map((field) => (
            <Grid key={field.id} item xs={12}>
              <Box
                sx={{
                  //   width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                  borderRadius: "1rem",
                  padding: "1rem",
                  "&:hover": {
                    background: "#f4f4f4",
                  },
                }}
                onClick={() => {
                  if (selectedFields.indexOf(field.id) > -1) {
                    setSelectedFields(
                      selectedFields.filter(
                        (selectedFieldId) => selectedFieldId !== field.id
                      )
                    );
                  } else {
                    setSelectedFields([...selectedFields, field.id]);
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",

                    gap: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      background: field.type,
                      borderRadius: "1rem",
                      padding: "4px 14px",
                      "& span": {
                        mixBlendMode: "difference",
                        color: "white",
                      },
                    }}
                  >
                    <span> {field.name}</span>
                  </Typography>
                </Box>
                <Checkbox checked={selectedFields.indexOf(field.id) > -1} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default FilterFieldsModal;
